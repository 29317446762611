import { ACTION_TYPES } from '../reducers/collectionCartReducer';
import { applyUsageDesc } from './assetDocs';
import { BaseExtendedFirebaseInstance } from 'react-redux-firebase';
import TagManager from 'react-gtm-module';

export const collectionCartAdd = (assetId: string) => {
	return async (
		_dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		const uid = getState().firebase.auth.uid;
		const assetRef = db.doc(`assets/${assetId}`);
		const userRef = db.doc(`users/${uid}`);
		const cartRef = db.doc(`users/${uid}/collectionCartShards/0`);
		try {
			const { assetDoc } = await db.runTransaction(async (t) => {
				const assetDoc = await t.get(assetRef);
				if (!assetDoc.exists)
					throw new Error(`Asset ${assetId} does not exist`);
				const userDoc = await t.get(userRef);
				if (!userDoc.exists) throw new Error(`User ${uid} does not exist`);
				const cartDoc = await t.get(cartRef);
				if (!cartDoc.exists) {
					t.set(cartRef, {
						shardId: 0,
						assets: [assetId],
					});
				} else {
					const assets = cartDoc.data()?.assets;
					if (assets.includes(assetId))
						throw new Error(`Asset ${assetId} is already in collection cart`);
					t.update(cartRef, {
						assets: getFirebase().firestore.FieldValue.arrayUnion(assetId),
					});
				}
				return { assetDoc };
			});
			// GTM event
			try {
				const { filters, query, sort } = getState().assetDocs.filterSort;

				TagManager.dataLayer({
					dataLayer: {
						event: 'add_to_collection',
						asset_name: assetDoc.data()?.name,
						total_in_collection: getState().collectionCart.list.length + 1,
						filters_applied: ((obj) => {
							if (!obj || !Object.keys(obj).length) return [];
							return Object.keys(obj)
								.map((key) => {
									return obj[key].map((val) => {
										let res = {};
										res[key] = val;
										return JSON.stringify(res);
									});
								})
								.reduce((a, b) => a.concat(b));
						})(filters?.noCatPrefix),
						search_term: query?.slice(0, query?.length - 1),
						partial_search_term:
							query && query[query.length - 1]
								? query[query.length - 1]
								: undefined,
						sort_applied: sort?.value,
					},
					dataLayerName: 'dataLayer',
				});
			} catch (e) {
				console.error('Collection analytics failed', e);
			}
		} catch (e) {
			console.error(`Add to collection cart operation failed`, e);
			return null;
		}
	};
};

export const collectionCartRemove = (
	assetId: string,
	fromDownload: boolean = false
) => {
	return async (
		_dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		const uid = getState().firebase.auth.uid;
		const userRef = db.doc(`users/${uid}`);
		const cartRef = db.doc(`users/${uid}/collectionCartShards/0`);
		try {
			await db.runTransaction(async (t) => {
				const userDoc = await t.get(userRef);
				if (!userDoc.exists) throw new Error(`User ${uid} does not exist`);
				const cartDoc = await t.get(cartRef);
				if (!cartDoc.exists) {
					throw new Error(`Collection cart for user ${uid} does not exist`);
				} else {
					const assets = cartDoc.data()?.assets;
					if (!assets.includes(assetId))
						throw new Error(
							`Asset ${assetId} is not present in collection cart`
						);
					t.update(cartRef, {
						assets: getFirebase().firestore.FieldValue.arrayRemove(assetId),
					});
				}
			});
			// GTM event fires only if asset isn't also being downloaded.
			if (fromDownload) return;
			try {
				TagManager.dataLayer({
					dataLayer: {
						event: 'remove_from_collection',
						asset_name: (await db.doc(`assets/${assetId}`).get()).data()?.[
							'name'
						],
						total_in_collection: getState().collectionCart.list.length - 1,
					},
					dataLayerName: 'dataLayer',
				});
			} catch (e) {
				console.error('Collection analytics failed', e);
			}
		} catch (e) {
			console.error(`Remove from collection cart operation failed`, e);
		}
	};
};

export const updateCartCache = (assets: string[]) => {
	return async (dispatch: any, getState: any) => {
		if (!assets) assets = [];
		const byId = {};
		assets.forEach((id) => (byId[id] = true));
		const currentJoins = getState().collectionCart.assetJoins;
		const updatedJoins = { ...currentJoins };
		Object.keys(updatedJoins).forEach((key) => {
			if (!assets.includes(key)) delete updatedJoins[key];
		});
		dispatch({
			type: ACTION_TYPES.ADD_JOINS,
			payload: {
				newJoins: updatedJoins,
			},
		});
		dispatch({
			type: ACTION_TYPES.SET_CACHE,
			payload: {
				list: assets,
				byId,
			},
		});
	};
};

// This is unfortunately the only way in the current version of TS to do a numeric range
// (Firestore "in" queries limited to 10 items)
type countRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export const joinAssets = (count: countRange = 9) => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const assetIds = getState().collectionCart.list;
		const currentJoins: Object = getState().collectionCart.assetJoins;
		let notJoined: string[] = assetIds.filter((id: string) => {
			return !Object.prototype.hasOwnProperty.call(currentJoins, id);
		});

		const firebase = getFirebase();
		const db = firebase.firestore();
		// This is done in a loop to ensure that the count is filled (if applicable)
		// Even if an asset is missing (deleted or moved to draft)
		const newJoins = {};
		while (
			notJoined.length &&
			Object.values(newJoins).filter((v) => v !== 'NOT_FOUND').length < count
		) {
			const subset = notJoined.splice(
				0,
				count - Object.values(newJoins).filter((v) => v !== 'NOT_FOUND').length
			);
			const collectionRef = db.collection(`assets`);
			const query = collectionRef
				.where('id', 'in', subset)
				.where('status', '==', 'live');
			const res = await query.get();
			res.docs.forEach((doc) => {
				const data = doc.data();
				newJoins[data.id] = applyUsageDesc(data);
			});
			subset.forEach((id) => {
				if (!Object.prototype.hasOwnProperty.call(newJoins, id)) {
					newJoins[id] = 'NOT_FOUND';
				}
			});
		}
		if (Object.keys(newJoins).length) {
			dispatch({
				type: ACTION_TYPES.ADD_JOINS,
				payload: {
					newJoins,
				},
			});
		}
	};
};
