/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrap = styled.div`
	display: inline-block;
	position: relative;
	height: 50px;

	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: ${(props) =>
			props.disabled ? '10px solid #aaaaaa' : '10px solid #000000'};
		pointer-events: none;
	}
`;
const Menu = styled.select`
	border: 1px solid #000000;
	height: 50px;
	min-width: 306px;

	font-size: 18px;
	color: #000000;
	font-family: 'Saira', sans-serif;
	line-height: 28px;
	padding-left: 21px;
	outline: none;

	&:disabled {
		color: #aaaaaa;
		border: 1px solid grey;
	}

	-moz-appearance: none;
	-webkit-appearance: none;
`;

function SelectMenu(props) {
	return (
		<Wrap disabled={props.disabled} id={props.id}>
			<Menu
				disabled={props.disabled}
				onChange={props.onChange}
				value={props.value}
			>
				{/* the first option, the default, not selectable */}
				<option value='default' disabled>
					{props.defaultLabel}
				</option>
				{/* the rest of the options */}
				{props.options.map((item, idx) => (
					<option key={idx} value={idx}>
						{item.label}
					</option>
				))}
			</Menu>
		</Wrap>
	);
}

SelectMenu.propTypes = {
	// whether the menu is disabled or not
	disabled: PropTypes.bool,
	// used as the placeholder for the first item (that is not selectable)
	defaultLabel: PropTypes.string.isRequired,
	// array of drop down items
	options: PropTypes.array.isRequired,
	// onChange handler
	onChange: PropTypes.func.isRequired,
	// controls the value of the menu
	value: PropTypes.string.isRequired,
};

SelectMenu.defaultProps = {
	disabled: false,
	value: 'default',
};

export default SelectMenu;
