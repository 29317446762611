const FAQData = {
	'2': {
		label: 'Site Usage',
		faqs: [
			{
				id: '1',
				question: 'How do I search for assets using the filters?',
				answer:
					'<p>When you reach the home page, you’ll find every asset available in the portal. Use the filters on the left side of the page to narrow your search; the page will automatically update to display the assets that correspond to your selected filters. </p><p>Begin by selecting a vehicle brand or commodity. Then narrow your options further by selecting an asset type (Digital, Printables, Catalogs or Mopar Custom Shop). If you want to start your search over, simply click the “clear filters” button at the top of the filter column.</p>',
			},
			{
				id: '2',
				question: 'How do I search for assets using the search function?',
				answer:
					'<p>If your looking for a certain asset or don’t want to use the filters, you can enter keywords into the search bar instead. Use specific words that describe the asset your looking for. </p><p>For example, searching for “Jeep Gladiator Lift Kit Print” would display print assets for the Jeep® Gladiator Lift Kit. Searching for “Ram 1500 digital social media posts” would display social media assets for Ram 1500. </p><p>To begin a new search, just clear the search results by clicking the “X” next to each keyword.</p>',
			},
			{
				id: '3',
				question: 'What should I expect when I preview an asset?',
				answer:
					'<p>If you’d like to get more details on a particular available asset, just click the eye icon on the top left of the asset image to open the preview window. There, you’ll see multiple larger images of the asset as well as a description of how to use it.</p><p>For PDF-type assets, the eye icon will open the document in a PDF previewer in your browser window. From there, you’ll be able to see the actual document and click a download button to download.</p>',
			},
		],
	},
	'3': {
		label: 'Assets and Files',
		faqs: [
			{
				id: '1',
				question: 'What is the recommended browser to open in?',
				answer: '<p>Google Chrome. </p>',
			},
			{
				id: '2',
				question: 'What programs do I need to download assets?',
				answer:
					'<p>You will need a program capable of viewing .jpgs and PDFs, such as Viewer on Windows or Preview on Mac.</p>',
			},
			{
				id: '3',
				question: 'Can I find these assets anywhere else?',
				answer:
					'<p>No. These assets have been created exclusively for Mopar<sub>®</sub> by award-winning designers to ensure you have access to content that is consistent with other Mopar branded materials. </p>',
			},
			{
				id: '4',
				question: 'Who can I share these assets with?',
				answer:
					'<p>These assets can be shared with anyone, including other agencies.</p>',
			},
			{
				id: '5',
				question: 'Are these assets free?',
				answer:
					'<p>These assets are free to you for use in-dealership or in communications on behalf of your dealership.</p>',
			},
			{
				id: '6',
				question: 'Can I have the working file for these assets?',
				answer:
					'<p>Working files are not available for any of the assets on the portal.</p>',
			},
			{
				id: '7',
				question: 'How do I download these files?',
				answer:
					'<p>There are three simple ways to download assets you want. The first is to click the blue “Download” button associated with the asset listing. The second is to click the other blue “Download” button located in the preview window after clicking on the eye icon. The third is to click the “Download Selected” or “Download All” button on the “My Collection” page.</p><p> Certain assets will be downloaded as a .zip file, such as social media content. These assets will contain the individual asset(s) that will be uploaded to your social page as well as a separate document that details some social media requirements and considerations. This document provides general rules and best practices for posting social content as well as prewritten post copy to be used at your discretion.</p><p> To open a .zip file, simply double-click the file and it will unzip, meaning it will open a folder with the contents inside.</p>',
			},
			{
				id: '8',
				question: 'How do I add to My Collection?',
				answer:
					'<p>Adding an asset to your collection is like adding items to a cart. This allows you to visit your added assets on your “My Collection” page to download at a later date or download several files at one time.</p><p>There are two ways to add assets to your collection. When scrolling, you can click on the “Add” button under the asset image or you can click “Add” in the preview window. </p><p>Once you add an item to your collection, you’ll notice a “Remove” button that appears under the asset image in place of the “Add” button. Click “Remove” if you’d like the item to be removed from your collection.</p>',
			},
			{
				id: '9',
				question: 'How do I navigate through My Collection?',
				answer:
					'<p>To visit your “My Collection” page, click on the banner icon in the top navigation menu. This icon will always display the number of added assets in your collection.</p><p>On your “My Collection” page, you will be able to see all your added assets. If you want to download multiple assets within your saved set, use the check boxes in the top left corner of the asset image, then hit “Download Selected.” If you want to download everything in your collection, hit “Download All.” You also have the option to remove assets from your collection by clicking “Remove from Collection” under the asset.</p><p>Keep in mind, after you download assets from your collection, they will be removed from your “My Collection” page.</p>',
			},
			{
				id: '10',
				question: 'How does my Download History work?',
				answer:
					'<p>To visit the “Download History” page, click on the clock icon in the top navigation menu. After you download an asset from the homepage, the preview window or your “My Collection” page, the asset will appear on the “Download History” page along with the date of download and details about the asset you downloaded. You have the option to re-download assets here.</p>',
			},
			{
				id: '11',
				question: 'How do I open or unzip a .zip file?',
				answer:
					'<p>To open a .zip file, simply double-click the file and it will unzip, meaning it will open a folder with the contents inside.</p>',
			},
			{
				id: '12',
				question: 'Should I print the printable materials myself for display?',
				answer:
					'<p>The Consumer Sales Guides, Dealer Sales Guides and Dealer Order Guides can be printed on your office inkjet printer. If you’d like, you can print the Consumer Sales Guides at a local print shop and have them laminated to prolong their shelf life. </p><p>If you’d like to print the Dream Cards as an easy handout, you can do so by printing the version without crop marks on your office inkjet printer. Otherwise, we suggest printing at a local print shop for a more professional look. Here are our print recommendations if you decide to print these with a local print shop.</p><h2>Dream Cards</h2><p>Print double-sided on 11x17 10lb matte cover stock at a print shop and trim to the crop marks.</p><h2>Dealer Order Guides</h2><p>Print double-sided (if applicable) on your office inkjet printer.</p><h2>Dealer Sales Guides</h2><p>Print double-sided (if applicable) on your office inkjet printer. Or print double-sided at a print shop on 80lb text stock and have laminated.</p><h2>Consumer Sales Guides</h2><p>Print double-sided (if applicable) on your office inkjet printer or print at a print shop on 80lb text stock paper.</p><p>For a professional job, you may also utilize Mopar On Demand printing. You can access the On Demand site through the External Resources page or through DealerCONNECT.</p>',
			},
			{
				id: '13',
				question:
					'How do I upload the static social media assets to my social media account?',
				answer:
					'<p>Static social posts are single image posts meant to spark conversation on your social networks. To upload to your social platforms, you can simply post from your desktop on platforms like Facebook or send the assets to your phone and upload through the mobile app for platforms like Instagram. Be sure to tag official Mopar accounts in your post copy.</p>',
			},
			{
				id: '14',
				question:
					'How do I upload the social media carousel assets to my social media account?',
				answer:
					'<p>Carousels are social media posts that allow you to showcase up to 10 images or videos within a single post on Facebook and Instagram. This enables you to highlight different products, give more detail and tell a full story. The carousel assets are downloaded via .zip file and labeled in the order in which they need to be posted. When uploading these to your social account, be sure to select and upload all assets in the order in which they’re labeled to ensure the content is displayed correctly.</p>',
			},
			{
				id: '15',
				question: 'How do I upload the social media videos to my account?',
				answer:
					'<p>Videos for social media are available in two sizes: 1080x1080 (for Instagram) and 1280x720 (for Facebook and Twitter). Make sure to upload the correct size for the recommended platform to showcase the best possible content for your audience.</p>',
			},
			{
				id: '16',
				question:
					'How do I upload the social media split grid to my social media account?',
				answer:
					'<p>Split Grid assets are for use on Instagram only and enable you to showcase content in an artistic way. The Split Grid post consists of six individual images that, when posted separately and in order, create one large image on your Instagram grid. With this type of post, you’ll have six different opportunities to engage with your followers and create an eye-catching graphic on your Instagram page. </p><p>Each asset is labeled in the order in which it must be posted to achieve the Split Grid effect.</p>',
			},
			{
				id: '17',
				question: 'How do I display the TV Monitor images on my monitor?',
				answer:
					'<p>When downloading a TV monitor asset, you’ll be given a set of .jpgs in a .zip file. Double-click the .zip file to unzip and reveal the contents within. That set of .jpgs are labeled in the order in which they should be uploaded to display correctly on your TV monitor, so be sure to check the file names and upload in order. </p><p>The upload process may vary depending on your dealership monitors. Most dealers use a USB drive to connect directly to their TVs or to connect to a computer that is plugged into the TVs.</p>',
			},
			{
				id: '18',
				question: 'How do I go about ordering catalogs or brochures?',
				answer:
					'<ol><li>Log on to DealerCONNECT</li><li>Click on the Marketing link at the top</li><li>Navigate to the Product Information box and click the Literature and Merchandising Material link</li><li>Here, you can search by part number, OR</li><li>Click the Mopar link</li><li>Then click on the Accessories link</li><ol>',
			},
		],
	},
};

function getFAQ(id) {
	let _ids = id.split('.');
	let _section = FAQData[_ids[0]];
	let _faq = _section.faqs.find((v) => v.id === _ids[1]);
	return _faq;
}

export default FAQData;
export { getFAQ };
