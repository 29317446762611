import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { updateFilters } from '../../../config/store/actions/assetDocs';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ReactHtmlParser from 'react-html-parser';
import BrandMutator from '../../_utilities/BrandMutator';

import FilterItem from '../../_atoms/FilterItem';

/**
 * Wrapper for the entire filters menu
 */
const Wrap = styled.section`
	width: 100%;
	box-sizing: border-box;
	color: #ffffff;

	/* wraps the title and the clear filters button */
	header {
		display: flex;
		margin-bottom: 30px;
	}
	padding-top: 30px;
	top: 0;
	padding-bottom: 30px;
`;

/**
 * Filter menu title
 */
const Header = styled.h2`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-weight: 700;
	font-size: 24px;
	letter-spacing: 0.05em;
	margin: 0 auto 0 0;
	text-transform: uppercase;
`;

/**
 * clear filters button
 */
const ClearFilters = styled.button`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.05em;
	border: none;
	background: none;
	padding: 0;
	color: inherit;
	font-weight: 400;
	cursor: ${({ enabled }) => (enabled ? 'pointer' : 'default')};
	text-decoration: underline;
	visibility: ${({ enabled }) => (enabled ? 'visible' : 'hidden')};
`;

/**
 * Wrapper that contains the button
 * and the filters
 */
const FilterGroupWrap = styled.div`
	border-bottom: 1px solid #ffffff;
	padding-bottom: 20px;
	margin-bottom: 20px;
`;

const NestedFilterGroupWrap = styled.div`
	margin-top: 15px;
`;

/**
 * Category title that serves as a button
 * to expand and collapse the filters below it
 */
const CategoryButton = styled.button`
	cursor: pointer;
	font-family: 'Saira Extra Condensed', sans-serif;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.08em;
	width: 100%;
	text-align: left;
	color: #ffffff;
	padding: 0;
	display: flex;
	align-items: center;
	position: relative;
	text-transform: uppercase;
	height: 30px;
	> span.count {
		display: flex;
		font-size: 13px;
		letter-spacing: 0.08em;
		width: 16px;
		height: 16px;
		background-color: #ffffff;
		color: #0067b1;
		border-radius: 8px;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
	}
	&:after {
		content: '';
		display: block;
		margin-left: auto;
		width: 16px;
		height: 16px;
		border-right: 2px solid #ffffff;
		border-top: 2px solid #ffffff;
		transform-origin: 8px 8px;
		transform: rotate(${(props) => (props.expanded ? '135deg' : '-45deg')});
		position: relative;
		right: ${(props) => (props.expanded ? '4px' : '5px')};
		top: ${(props) => (props.expanded ? '-4px' : '6px')};
		visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
	}
	sub {
		margin-right: 0.3em;
		bottom: -0.15em;
	}
`;

const NestedCategoryHeader = styled.div`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.08em;
	width: 100%;
	text-align: left;
	color: #ffffff;
	padding: 0;
	display: flex;
	align-items: center;
	position: relative;
	height: 30px;
`;

/**
 * container that holds the filters for a group
 */
const FiltersContainer = styled.div`
	box-sizing: border-box;
	overflow: hidden;
	transition: max-height 200ms ease-out;
	max-height: ${(props) => (props.expanded ? props.maxHeight : 0)}px;
	overflow: ${(props) => (props.expanded ? 'visible' : 'hidden')};
`;

let _getNestedOptionCount = (nestedSrc) => {
	let count = {
		n: nestedSrc.length,
		o: 0,
	};
	nestedSrc.forEach(({ nested, options }) => {
		if (nested) {
			count.n += nested.length;
			const { n, o } = _getNestedOptionCount(nested);
			count.n += n;
			count.o += o;
		} else count.o += options.length;
	});
	return count;
};

const _getNestedActiveFiltersCount = (id, selectedFilters) => {
	let count = 0;
	const keys = Object.keys(selectedFilters).filter(
		(str) => str === id || str.startsWith(`${id}.`)
	);
	keys.forEach((key) => (count += selectedFilters[key].length));
	return count;
};

function FilterGroup(props) {
	const {
		nested,
		options,
		id,
		handleFilterToggle,
		nestedLevel,
		selectedFilters,
	} = props;
	/**
	 * whether the menu is expanded or not
	 */
	const [expanded, isExpanded] = useState(nestedLevel > 0);

	/**
	 * used for the tweening of opening and closing
	 */
	let maxHeight = 0;
	if (nested) {
		const { n, o } = _getNestedOptionCount(nested);
		maxHeight = n * 45 + o * 32;
	} else if (options) maxHeight = 32 * options.length;

	/**
	 * used to get the number of selected filters per group
	 * and is then displayed to the user if greater than 0
	 */
	let activeFiltersCount = _getNestedActiveFiltersCount(id, selectedFilters);

	const Wrap = nestedLevel === 0 ? FilterGroupWrap : React.Fragment;
	const CategoryHeader =
		nestedLevel === 0 ? CategoryButton : NestedCategoryHeader;

	const toggleExpanded = () => {
		if (nestedLevel > 0) return;
		isExpanded((prev) => !prev);
	};

	return (
		<Wrap>
			{props.display ? (
				<CategoryHeader
					active={nestedLevel === 0}
					expanded={expanded}
					onClick={toggleExpanded}
				>
					{ReactHtmlParser(BrandMutator(props.display))}
					{nestedLevel === 0 && activeFiltersCount > 0 ? (
						<span className='count'>{activeFiltersCount}</span>
					) : (
						<></>
					)}
				</CategoryHeader>
			) : (
				<SkeletonTheme color='#0067b1'>
					<Skeleton width='100%' />
				</SkeletonTheme>
			)}

			<FiltersContainer expanded={expanded} maxHeight={maxHeight}>
				{nested &&
					nested.map((category, idx) => {
						return (
							<NestedFilterGroupWrap key={idx}>
								<FilterGroup
									{...category}
									key={idx}
									id={`${id}.${category.key}`}
									handleFilterToggle={handleFilterToggle}
									nestedLevel={nestedLevel ? nestedLevel + 1 : 1}
									selectedFilters={selectedFilters}
								/>
							</NestedFilterGroupWrap>
						);
					})}
				{!nested &&
					options &&
					options.map((option, idx) => {
						if (option.filtersHidden) return null;
						return (
							<FilterItem
								{...option}
								key={idx}
								filterKey={id}
								checked={
									selectedFilters[id] &&
									selectedFilters[id].includes(option.value)
								}
								handleFilterToggle={handleFilterToggle}
							/>
						);
					})}
			</FiltersContainer>
		</Wrap>
	);
}

function FilterMenu(props) {
	const { updateCurrentFilters, currentFilterSort } = props;
	let { categories } = props;
	console.log(currentFilterSort);

	const [selectedFilters, setSelectedFilters] = useState(
		(currentFilterSort.filters && currentFilterSort.filters.noCatPrefix) || {}
	);

	useEffect(() => {
		setSelectedFilters(
			(currentFilterSort.filters && currentFilterSort.filters.noCatPrefix) || {}
		);
	}, [currentFilterSort]);

	const handleFilterToggle = (filterPath, value) => {
		setSelectedFilters((prev) => {
			const newObj = cloneDeep(prev);
			if (!newObj[filterPath]) newObj[filterPath] = [];
			if (newObj[filterPath].includes(value)) {
				newObj[filterPath].splice(newObj[filterPath].indexOf(value), 1);
				if (newObj[filterPath].length === 0) delete newObj[filterPath];
			} else newObj[filterPath].push(value);
			updateCurrentFilters(newObj);
			return newObj;
		});
	};

	const handleClearFilters = () => {
		if (Object.keys(selectedFilters).length > 0) {
			setSelectedFilters({});
			updateCurrentFilters(null);
		}
	};

	if (!categories) categories = Array.apply(null, Array(6));

	return (
		<Wrap>
			<header>
				<Header>Filters</Header>
				<ClearFilters
					enabled={Object.keys(selectedFilters).length > 0}
					onClick={handleClearFilters}
				>
					Clear Filters
				</ClearFilters>
			</header>
			<div className='items'>
				{categories &&
					categories.map((category, idx) => {
						return (
							<FilterGroup
								{...category}
								key={idx}
								handleFilterToggle={handleFilterToggle}
								selectedFilters={selectedFilters}
								id={(category && category.key) || `cat-${idx}`}
								nestedLevel={0}
							/>
						);
					})}
			</div>
		</Wrap>
	);
}

const mapStateToProps = (state) => ({
	currentFilterSort: state.assetDocs.filterSort,
	categories:
		state.firestore.data.assetDataModel &&
		state.firestore.data.assetDataModel.categories,
});

const mapDispatchToProps = (dispatch) => ({
	updateCurrentFilters: (filtersObj) => dispatch(updateFilters(filtersObj)),
});

export default compose(
	firestoreConnect([
		{
			collection: 'config',
			doc: 'dataModel',
			storeAs: 'assetDataModel',
		},
		{
			collection: 'aggregation/assetCategoryCombinations/shards',
			storeAs: 'assetCategoryCombinationShards',
		},
	]),
	connect(mapStateToProps, mapDispatchToProps)
)(FilterMenu);
