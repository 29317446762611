import React from 'react';
import AccessoryAssetContainer from './_organisms/AccessoryAssetContainer';
import { ListingTypes } from './_organisms/AssetListing';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding-top: 20px;
	padding-bottom: 80px;
`;

const NoResults = styled.p`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-size: 19px;
	line-height: 28px;
	letter-spacing: 0.03em;
	font-weight: 500;
	color: #aaaaaa;
	margin: 0;
	padding: 0 30px;
`;

const ControlledAssetResults = (props) => {
	const {
		resultPages,
		loadMore,
		noMore,
		loading,
		titleOnly,
		columns = 3,
	} = props;

	const handleLoadMore = async () => {
		if (loading) return;
		return await loadMore();
	};

	return (
		<React.Fragment>
			{noMore && (!resultPages || resultPages.length < 1) ? (
				<NoResults>No results found.</NoResults>
			) : (
				<></>
			)}

			<Wrapper>
				<InfiniteScroll
					threshold={600}
					pageStart={0}
					loadMore={handleLoadMore}
					hasMore={!noMore}
					loader={
						<AccessoryAssetContainer
							loading={true}
							listingType={ListingTypes.ACCESSORY}
							columns={columns}
							key={0}
							titleOnly={titleOnly}
						/>
					}
					initialLoad={false}
				>
					{resultPages &&
						resultPages.map((page, idx) => {
							return (
								<AccessoryAssetContainer
									openPreviewHandler={props.openPreviewHandler}
									key={idx}
									data={page}
									listingType={ListingTypes.ACCESSORY}
									columns={columns}
									withEdit={props.withEdit}
									titleOnly={titleOnly}
								/>
							);
						})}
				</InfiniteScroll>
			</Wrapper>
		</React.Fragment>
	);
};

export default ControlledAssetResults;
