// eslint-disable-next-line
import React from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import { connect } from 'react-redux';
import hasPermissions from '../../utils/hasPermissions';

interface Props {
	//** Redirect if the user is not authenticated */
	requireAuth?: boolean;
	//** Redirect if the user is authenticated (overrides requireAuth) */
	requireNoAuth?: boolean;
	//** Require permissions to be present on the authenticated user's profile (written in dot notation) */
	requirePermissions?: string | string[];
	//** The props to path to the Redirect component internally */
	redirectProps: RedirectProps;
	children?: any;
	auth?: any;
	profile?: any;
}

const RouteGuard = (props: Props) => {
	const {
		auth,
		profile,
		requireAuth,
		requireNoAuth,
		requirePermissions,
	} = props;

	let _passesGuards = true;

	if (requireNoAuth) {
		if (auth && auth.uid) _passesGuards = false;
	} else if (requireAuth && (!auth || !auth.uid)) _passesGuards = false;

	if (requirePermissions) {
		if (profile && profile.permissions) {
			if (!hasPermissions(requirePermissions, profile.permissions)) {
				_passesGuards = false;
			}
		} else _passesGuards = false;
	}

	return _passesGuards ? props.children : <Redirect {...props.redirectProps} />;
};

RouteGuard.defaultProps = {
	requireAuth: true,
	redirectProps: { to: '/' },
};

const mapStateToProps = (state) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile,
});

export default connect(mapStateToProps)(RouteGuard);
