export interface userPermissions {
	[key: string]: userPermissions | boolean;
}

export default (
	permissions: string | string[],
	userPermissions: userPermissions
): boolean => {
	if (!Array.isArray(permissions)) permissions = [permissions];

	for (const permission of permissions) {
		const levels = permission.split('.');

		let currentLevel: userPermissions | boolean = userPermissions[levels[0]];
		if (!currentLevel) return false;
		for (let index = 1; index < levels.length; index++) {
			currentLevel = currentLevel[levels[index]];
			if (!currentLevel) return false;
		}
	}

	return true;
};
