import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import BrandMutator from '../../_utilities/BrandMutator';
import Check from './filter-check.svg';
import Tooltip from '../Tooltip';

const Wrap = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	.tip {
		margin-left: 11px;
	}
`;

const Button = styled.button`
	display: flex;
	align-items: center;
	color: #ffffff;
	padding: 5px 0;
	border: none;
	background: none;
	cursor: pointer;
	font-family: 'Saira', sans-serif;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.08em;
	position: relative;

	sub {
		margin-right: 0.3em;
		bottom: -0.15em;
		align-self: flex-end;
	}
	sub,
	sup {
		font-size: 0.5em;
	}

	span {
		text-align: left;
	}
`;

const CheckBox = styled.div`
	border: 1px solid #ffffff;
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-right: 19px;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: ${(props) => (props.checked ? `url(${Check})` : 'none')};
`;

function FilterItem(props) {
	return (
		<Wrap>
			<Button
				onClick={() => props.handleFilterToggle(props.filterKey, props.value)}
			>
				<CheckBox checked={props.checked} />
				<span>{ReactHtmlParser(BrandMutator(props.display))}</span>
				{/* {props.display} */}
			</Button>
			{props.info && props.info !== '' ? <Tooltip tip={props.info} /> : null}
		</Wrap>
	);
}

FilterItem.propTypes = {
	active: PropTypes.bool,
};

FilterItem.defaultProps = {
	active: false,
};

export default FilterItem;
