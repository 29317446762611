import React, { useState } from 'react';
import styled from 'styled-components';

import SelectMenu from '../../_atoms/SelectMenu';
import Button from '../../_atoms/Button';
import FAQ from '../../_atoms/FAQ';
import { getFAQ } from '../../../data/faq';
import { useDispatch } from 'react-redux';
import { submitForm } from '../../../config/store/actions/contactForm';

const TextAreaWrap = styled.div`
	margin-top: 30px;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	textarea {
		width: 100%;
		border: 1px solid #000000;
		resize: none;
		height: 200px;
		font-family: 'Saira', sans-serif;
		color: #000000;
		outline: none;
		box-sizing: border-box;
		vertical-align: bottom;
	}

	> div {
		position: absolute;
		bottom: 8px;
		right: 8px;
		font-family: 'Saira', sans-serif;
		color: #000000;
		font-size: 10px;

		span {
			color: #e54242;
		}
	}
`;

function TextArea(props) {
	const [charCount, updateCharCount] = useState(0);

	const onChangeHandler = (e) => {
		let _val = e.target.value;
		updateCharCount(_val.length);
		props.onChangeHandler(e);
	};

	return (
		<TextAreaWrap>
			<textarea
				maxLength={props.maxchars}
				onChange={onChangeHandler}
			></textarea>
			<div>
				{charCount < props.minchars ? (
					<>
						<span>{charCount}</span>
						{`/${props.maxchars}`}
					</>
				) : (
					`${charCount}/${props.maxchars}`
				)}
			</div>
		</TextAreaWrap>
	);
}

const Wrap = styled.form`
	box-sizing: border-box;
	width: 640px;
	min-height: 100px;
	display: flex;
	flex-wrap: wrap;

	#submit {
		display: inline-flex;
		width: 150px;
		min-width: 0;
		margin-top: 30px;

		&:disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	> div:first-child {
		margin-right: auto;
	}

	#issue {
		width: 100%;
		margin-top: 30px;
		select {
			width: 100%;
		}
	}

	.faqs {
		margin-top: 30px;
	}
`;

const formData = {
	defaultLabel: 'Type of Inquiry',
	options: [
		{
			label: 'Troubleshooting',
			topics: [
				{ label: 'File Download', issues: [] },
				{ label: 'File Preview', issues: [] },
				{ label: 'PDF Preview', issues: [] },
				{ label: 'Opening Zip Files', issues: [] },
				{ label: 'Software Issues', issues: [] },
				{ label: 'Other', issues: [] },
			],
		},
		{
			label: 'Technical Issues',
			topics: [{ label: 'Bug Report', issues: [] }],
		},
		{
			label: 'Assets and Materials',
			topics: [
				{ label: 'New Asset Request', issues: [] },
				{
					label: 'Editable Assets',
					issues: [
						{ label: 'My editable fields aren’t working.', faqs: [] },
						{ label: 'What software do I need to edit these?', faqs: [] },
						{ label: 'Other', faqs: [] },
					],
				},
				{
					label: 'Prefilled Assets',
					issues: [
						{ label: 'Can I print these assets myself?', faqs: ['3.9'] },
						{
							label:
								'I’d like to get these professionally printed. How can I do that?',
							faqs: ['3.9'],
						},
						{ label: 'Other', faqs: [] },
					],
				},
				{
					label: 'Social Media Assets',
					issues: [
						{
							label: 'I’m having trouble uploading the videos to my account.',
							faqs: ['3.12'],
						},
						{
							label: 'I’m having trouble uploading a static grid.',
							faqs: ['3.13'],
						},
						{
							label:
								'I’m having trouble uploading the social carousel to my account.',
							faqs: ['3.11'],
						},
						{ label: 'Other', faqs: [] },
					],
				},
				{
					label: 'TV Monitor Assets',
					issues: [
						{
							label: 'How do I get the monitor assets on my monitors?',
							faqs: ['3.14'],
						},
						{ label: 'Other', faqs: [] },
					],
				},
				{
					label: 'Digital Website Assets',
					issues: [{ label: 'I need a new size to fit my website.', faqs: [] }],
				},
				{
					label: 'Catalogs and Brochures',
					issues: [
						{ label: 'I want to order a catalog.', faqs: ['3.15'] },
						{ label: 'I want to order brochures.', faqs: ['3.15'] },
						{ label: 'Other', faqs: [] },
					],
				},
				{ label: 'Incorrect Information in Asset', issues: [] },
			],
		},
	],
};

function ContactForm(props) {
	const TextAreaMinLength = 5;
	const TextAreaMaxLength = 300;

	// default selections object
	// this will get used to reset selections as well
	const defaultSelections = {
		selectedInquiry: 'default',
		selectedTopic: 'default',
		selectedIssue: 'default',
		topicsList: [],
		issueList: [],
		messageBody: '',
	};
	const [selections, setSelections] = useState(defaultSelections);
	const [submitDisabled, setSubmitDisabled] = useState(true);

	/**
	 * when the type of inquiry select menu changes
	 * @param {event} e
	 */
	const InquireOnChange = (e) => {
		// get the value from the select menu
		let _v = e.target.value;
		// update the selections object based on defaults
		const _updatedSelections = Object.assign({}, defaultSelections, {
			selectedInquiry: _v,
			topicsList: formData.options[_v].topics,
		});
		// update selections
		setSelections(_updatedSelections);
	};

	/**
	 * when the topic of interest select menu changes
	 * @param {event} e
	 */
	const TopicOnChange = (e) => {
		// get the value from the select menu
		let _v = e.target.value;
		// update the selections object based on the current selections
		const _updatedSelections = Object.assign({}, selections, {
			selectedTopic: _v,
			issueList: selections.topicsList[_v].issues,
			selectedIssue: defaultSelections.selectedIssue,
		});
		// update selections
		setSelections(_updatedSelections);
	};

	/**
	 * handler for when the text area changes
	 * @param {event} e the event
	 */
	const onTextAreaChange = (e) => {
		// get the text value from the the text area
		let _v = e.target.value;
		// update the selections
		const _updatedSelections = Object.assign({}, selections, {
			messageBody: _v,
		});
		// update selections
		setSelections(_updatedSelections);
		// update the state of the submit button
		setSubmitDisabled(_v.length < TextAreaMinLength);
	};

	/**
	 * when the message select menu changes
	 * @param {event} e
	 */
	const IssueOnChange = (e) => {
		// get the value from the select menu
		let _v = e.target.value;
		// udate the selections object based on the current selections
		const _updatedSelections = Object.assign({}, selections, {
			selectedIssue: _v,
		});
		// update the selections
		setSelections(_updatedSelections);
	};

	const dispatch = useDispatch();
	/**
	 * click handler to submit the form
	 * @param {event} e the event
	 */
	const onSubmitForm = (e) => {
		e.preventDefault();

		const {
			selectedInquiry,
			selectedTopic,
			selectedIssue,
			messageBody,
		} = selections;

		// get values
		let submissionData = {
			inquiry: formData.options[selectedInquiry].label,
			topic: selections.topicsList[selectedTopic].label,
			issue:
				selectedIssue !== defaultSelections.selectedIssue
					? selections.issueList[selectedIssue].label
					: '',
			message: messageBody !== defaultSelections.messageBody ? messageBody : '',
		};

		props.postSubmissionHandler();

		dispatch(
			submitForm({
				form: submissionData,
			})
		);
	};

	// determines if the faq should be displayed
	const showFAQ = () => {
		let currentMessageObject = selections.issueList[selections.selectedIssue];
		if (!currentMessageObject) return false;
		if (currentMessageObject.faqs.length < 1) return false;
		return true;
	};

	/**
	 * will get all of the FAQs from the current message list
	 */
	const getFAQsList = () => {
		let _faqids = selections.issueList[selections.selectedIssue].faqs;
		let _faqs = _faqids.map((id) => getFAQ(id));
		return _faqs;
	};

	// determines if submit button should be displayed
	const showTextAreaAndSubmit = () => {
		const {
			selectedInquiry,
			selectedTopic,
			selectedIssue,
			issueList,
		} = selections;

		if (selectedInquiry === 'default' || selectedTopic === 'default')
			return false;
		if (issueList.length > 0 && selectedIssue === 'default') return false;

		return true;
	};

	return (
		<Wrap>
			<SelectMenu
				defaultLabel={formData.defaultLabel}
				value={selections.selectedInquiry}
				options={formData.options}
				onChange={InquireOnChange}
			/>
			<SelectMenu
				defaultLabel='Topic of Interest'
				value={selections.selectedTopic}
				disabled={selections.topicsList.length < 1}
				options={selections.topicsList}
				onChange={TopicOnChange}
			/>
			{selections.issueList.length > 0 ? (
				<SelectMenu
					defaultLabel='Issue'
					id='issue'
					onChange={IssueOnChange}
					options={selections.issueList}
					value={selections.selectedIssue}
				/>
			) : null}
			{showFAQ() ? (
				<div className='faqs'>
					{getFAQsList().map((v, idx) => (
						<FAQ key={idx} {...v} />
					))}
				</div>
			) : null}
			{showTextAreaAndSubmit() ? (
				<>
					<TextArea
						onChangeHandler={onTextAreaChange}
						minchars={TextAreaMinLength}
						maxchars={TextAreaMaxLength}
					/>
					<Button id='submit' disabled={submitDisabled} onClick={onSubmitForm}>
						Send Message
					</Button>
				</>
			) : null}
		</Wrap>
	);
}

export default ContactForm;
