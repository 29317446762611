/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.figure`
	position: relative;
	width: ${({ hide }) => (hide ? '0' : '100%')};
	height: ${({ hide }) => (hide ? '0' : '100%')};
	overflow: hidden;
	visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
	margin: 0;
	img.tiny {
		margin: auto;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
		opacity: ${({ blur }) => (blur ? '1' : '0')};
		filter: blur(20px);
		transform: scale(1.05);
		clip-path: inset(0);
		transition: ${({ blur }) => (!blur ? 'none' : 'opacity 3s ease-out')};
	}
	div.full {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
			opacity: ${({ blur }) => (blur ? '0' : '1')};
			transition: ${({ blur, isInstant }) =>
				blur ? 'none' : !isInstant ? 'opacity 0.5s ease-out' : 'none'};
		}
	}
`;

const ProgressiveImg = (props) => {
	const {
		hide,
		onTinyLoaded,
		onFullLoaded,
		src,
		previewSrc,
		cover,
		...restProps
	} = props;
	const [isInstant, setIsInstant] = useState(false);
	const [renderedAt] = useState(Date.now());
	const [tinyLoaded, setTinyLoaded] = useState(false);
	const [fullLoaded, setFullLoaded] = useState(false);
	const [tinyLoadedTriggered, setTinyLoadedTriggered] = useState(false);
	const [fullLoadedTriggered, setFullLoadedTriggered] = useState(false);

	useEffect(() => {
		if (tinyLoaded && !tinyLoadedTriggered) {
			if (onTinyLoaded) onTinyLoaded();
			setTinyLoadedTriggered(true);
		} else if (fullLoaded && !fullLoadedTriggered) {
			if (onFullLoaded) onFullLoaded();
			setFullLoadedTriggered(true);
			if (!tinyLoadedTriggered) {
				if (onTinyLoaded) onTinyLoaded();
				setTinyLoadedTriggered(true);
			}
		}
	}, [
		tinyLoadedTriggered,
		onTinyLoaded,
		setTinyLoadedTriggered,
		tinyLoaded,
		fullLoadedTriggered,
		fullLoaded,
		onFullLoaded,
		setFullLoadedTriggered,
	]);

	useEffect(() => {
		if (fullLoaded) {
			if (renderedAt > Date.now() - 150) {
				setIsInstant(true);
			}
		}
	}, [fullLoaded, setIsInstant, renderedAt]);

	return (
		<Wrapper hide={hide} blur={!fullLoaded} cover={cover} isInstant={isInstant}>
			{!isInstant && (
				<img
					alt=''
					{...restProps}
					className='tiny'
					src={previewSrc}
					onLoad={() => setTinyLoaded(true)}
				/>
			)}

			<div className='full'>
				<img
					alt=''
					{...restProps}
					className='full'
					src={src}
					onLoad={() => setFullLoaded(true)}
				/>
			</div>
		</Wrapper>
	);
};

export default ProgressiveImg;
