import React, { useState } from 'react';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';

import MonthlyPriorityContainer from '../components/_organisms/MonthlyPriorityContainer';
import MonthlyPriorityNav from '../components/_organisms/MonthlyPriorityNav';

const getCurrentMonthAndYear = () => {
	const d = new Date();
	// accounting for the fact that we don't have all assets on the testing/dev env.
	let _month = process.env.NODE_ENV === 'development' ? 11 : d.getMonth();
	let _year = process.env.NODE_ENV === 'development' ? 2020 : d.getFullYear();

	return {
		month: _month,
		year: _year,
	};
};

function MonthlyPriority() {
	// default page will most likely not be zero, but instead the latest page
	const [currentPage, setCurrentPage] = useState(getCurrentMonthAndYear());

	return (
		<React.Fragment>
			<Hero dark bg='monthly-priority-head.jpg'>
				<h1>the monthly priority</h1>
				<p>
					Get assets for internal use within your dealership like up-to-date
					parts lists, custom shop files and more. Assets are uploaded monthly,
					making it easy to stay current with Mopar<sub>®</sub> products and
					streamline internal processes.
				</p>
			</Hero>
			<MonthlyPriorityNav
				changePage={(newMonthYear) => setCurrentPage(newMonthYear)}
				month={currentPage.month}
				year={currentPage.year}
			/>
			<MonthlyPriorityContainer
				month={currentPage.month}
				year={currentPage.year}
			/>
			<Footer />
		</React.Fragment>
	);
}

export default MonthlyPriority;
