import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CURRENT_DC_LINKS } from '../config/environments';
// import React, { useState } from 'react';
// import { connect } from 'react-redux';
// import { setSourceMapRange } from 'typescript';
// import { loginWithEmailPass } from '../config/store/actions/auth';
// import Login from '../components/_molecules/Login';
// import styled from 'styled-components';

// const Container = styled.div`
// 	flex-grow: 1;
// 	display: flex;
// 	height: 100%;
// 	max-height: 660px;
// 	> * {
// 		width: 100%;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 	}
// `;

function LoginPage(props) {
	const { loggedOut, wasFca } = props;
	const history = useHistory();
	React.useEffect(() => {
		if (!loggedOut) {
			window.location.href = CURRENT_DC_LINKS.access;
		} else {
			if (wasFca) {
				window.close();
				setTimeout(() => {
					if (!window.closed) {
						window.location.href = CURRENT_DC_LINKS.base;
					}
				}, 200);
			} else history.push('/dp');
		}
	}, [loggedOut, history, wasFca]);
	return null;
	// const [loading, setLoading] = useState(false);
	// const [error, setError] = useState(false);

	// const handleEmailPassSubmit = async (email, password) => {
	// 	setLoading(true);
	// 	setError(false);
	// 	const { success, err } = await props.emailPassLogin(email, password);
	// 	if (err) console.log(err);
	// 	setLoading(false);
	// 	setError(!success);
	// };

	// return (
	// 	<Container>
	// 		<div>
	// 			<Login
	// 				error={error}
	// 				loading={loading}
	// 				nonSSOHandler={handleEmailPassSubmit}
	// 			/>
	// 		</div>
	// 	</Container>
	// );
}

// const mapDispatchToProps = (dispatch) => ({
// 	emailPassLogin: (email, pass) => dispatch(loginWithEmailPass(email, pass)),
// });

// export default connect(null, mapDispatchToProps)(LoginPage);

const mapStateToProps = (state) => ({
	loggedOut: state.auth.loggedOut,
	wasFca: state.auth.wasFca,
});

export default connect(mapStateToProps)(LoginPage);
