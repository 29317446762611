import React from 'react';
import styled from 'styled-components';
import AssetListing from '../AssetListing';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

const Wrap = styled.div`
	box-sizing: border-box;
	padding: 30px 30px 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	justify-items: center;
	row-gap: 50px;
	column-gap: 30px;
	margin-bottom: 100px;
`;

const MonthlyPriorityContainer = (props) => {
	const { month, year } = props;

	useFirestoreConnect(() => [
		{
			collection: 'monthlyPriority',
			where: [
				['month', '==', month],
				['year', '==', year],
				['status', '==', 'live'],
			],
			orderBy: ['priority', 'asc'],
			storeAs: `monthlyPriority_${month}_${year}`,
		},
	]);

	const data = useSelector(
		(state) => state.firestore.ordered[`monthlyPriority_${month}_${year}`]
	);

	return (
		<Wrap columns={4}>
			{data &&
				data.length > 0 &&
				data.map((v, idx) => (
					<AssetListing key={idx} type='monthly' data={v} />
				))}
			{(!data || data.length === 0) &&
				Array.apply(null, Array(12)).map((_el, idx) => (
					<AssetListing key={idx} type='monthly' loading={true} />
				))}
		</Wrap>
	);
};

export default MonthlyPriorityContainer;
