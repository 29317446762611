export default (
	src: string,
	maxSize: number,
	quality: number = 0.3
): Promise<string> => {
	const canvas = document.createElement('canvas');
	const img = document.createElement('img');

	return new Promise((resolve, reject) => {
		img.onload = () => {
			let w: number = img.width;
			let h: number = img.height;

			if (w > h) {
				if (w > maxSize) {
					h = Math.round((h *= maxSize / w));
					w = maxSize;
				}
			} else {
				if (h > maxSize) {
					w = Math.round((w *= maxSize / h));
					h = maxSize;
				}
			}
			canvas.width = w;
			canvas.height = h;

			const ctx = canvas.getContext('2d');
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!ctx) ctx.drawImage(img, 0, 0, w, h);

			resolve(canvas.toDataURL('image/png', quality));
		};

		img.onerror = (err) => reject(err);

		img.src = src;
	});
};
