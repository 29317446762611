import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TARGET_NAME = 'newportalwin';

window.name = TARGET_NAME;

const WindowNamer = () => {
	const location = useLocation();
	useEffect(() => {
		// Brute force fix for IE bug that strips window.name
		// Used for DealerConnect window management
		if (window.name !== TARGET_NAME) {
			window.name = TARGET_NAME;
		}
		setTimeout(() => {
			if (window.name !== TARGET_NAME) {
				window.name = TARGET_NAME;
			}
		}, 50);
	}, [location]);
	return null;
};

export default WindowNamer;
