/* eslint-disable no-undef */
import TagManager from 'react-gtm-module';
import { ACTION_TYPES } from '../reducers/authReducer';
import { BaseExtendedFirebaseInstance } from 'react-redux-firebase';

export interface LoginResponse {
	success: boolean;
	err?: any;
}

export const loginWithEmailPass = (email: string, password: string) => {
	return async (
		dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const auth = firebase.auth();

		dispatch({
			type: ACTION_TYPES.SET_LOGGED_OUT,
			payload: false,
		});

		dispatch({
			type: ACTION_TYPES.SET_WAS_FCA,
			payload: false,
		});

		try {
			await auth.signInWithEmailAndPassword(email, password);

			// Push login to GTM
			let uid = _getState().firebase.auth.uid;
			TagManager.dataLayer({
				dataLayer: {
					event: 'login',
					method: 'email',
					fca_user_id: uid,
					individual_user_id: uid,
					dealer_code: uid,
				},
				dataLayerName: 'dataLayer',
			});

			return {
				success: true,
			};
		} catch (err) {
			return {
				success: false,
				err,
			};
		}
	};
};

export const loginWithToken = (token: string) => {
	return async (
		dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const auth = firebase.auth();

		dispatch({
			type: ACTION_TYPES.SET_LOGGED_OUT,
			payload: false,
		});

		dispatch({
			type: ACTION_TYPES.SET_WAS_FCA,
			payload: true,
		});

		try {
			await auth.signInWithCustomToken(token);

			const db = getFirebase().firestore();

			const profileDoc = db.doc(`users/${_getState().firebase.auth.uid}`);
			profileDoc.get().then((profile) => {
				if (profile) {
					let dataObject = {
						event: 'login',
						method: 'sso',
						fca_user_id: _getState().firebase.auth.uid,
					};

					const data = profile.data();
					if (data) {
						dataObject['individual_user_id'] = data.nameId;
						dataObject['dealer_code'] = data.dealerCode;
					}

					// Push login to GTM
					TagManager.dataLayer({
						dataLayer: dataObject,
						dataLayerName: 'dataLayer',
					});
				}
			});

			return {
				success: true,
			};
		} catch (err) {
			return {
				success: false,
				err,
			};
		}
	};
};

export const logOut = () => {
	return async (
		dispatch,
		getState,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const state = getState();
		const wasFca =
			state &&
			state.firebase &&
			state.firebase.profile &&
			state.firebase.profile.lastLoginVia === 'fca-saml-sso';
		const firebase = getFirebase();
		const auth = firebase.auth();
		dispatch({
			type: ACTION_TYPES.SET_LOGGED_OUT,
			payload: true,
		});

		dispatch({
			type: ACTION_TYPES.SET_WAS_FCA,
			payload: wasFca ? true : false,
		});

		await auth.signOut();
	};
};
