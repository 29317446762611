import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { updateCartCache } from '../../config/store/actions/collectionCart';

export default () => {
	const uid = useSelector((state) => state.firebase.auth.uid);
	useFirestoreConnect(() => [
		{
			collection: `users/${uid}/collectionCartShards`,
			storeAs: `collectionCartShards`,
		},
	]);
	const shards = useSelector(
		(state) => state.firestore.ordered.collectionCartShards
	);
	const dispatch = useDispatch();
	useEffect(() => {
		const aggregate = [];
		if (shards) {
			shards.forEach((data) => {
				aggregate.push(...data.assets);
			});
		}
		dispatch(updateCartCache(aggregate));
	}, [shards, dispatch]);
	return null;
};
