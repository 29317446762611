/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../_atoms/Button';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import NoImage from './no-image-listing.jpg';
import CloudStorageImg from '../../_utilities/CloudStorageImg';
import CloudStorageDownloadButton from '../../_atoms/CloudStorageDownloadButton';
import { downloadFile } from '../../../config/store/actions/cloudStorage';
import PreviewFlag from './preview-flag.png';
import MetaData from '../../_molecules/MetaData';
import Checkmark from './checkmark.svg';
import Parser from 'react-html-parser';

const ListingTypes = {
	ACCESSORY: 'accessory',
	EXTERNAL: 'external',
	MONTHLY: 'monthly',
	COLLECTION: 'collection',
};

// asset image
const ItemImage = styled.figure`
	width: 100%;
	height: 200px;
	overflow: hidden;
	margin: 0;
	.skeleton {
		height: 100%;
	}
	> img {
		width: 100%;
		height: auto;
		object-fit: cover;
		vertical-align: bottom;
	}
`;

const ItemImageFlagged = styled(ItemImage)`
	position: relative;
	cursor: pointer;
	&:before {
		content: '';
		display: block;
		width: 60px;
		height: 60px;
		position: absolute;
		left: -2px;
		top: -2px;
		z-index: 50;
		background: url(${PreviewFlag});
		background-position: top left;
		background-repeat: no-repeat;
	}
`;

const ItemImageWithCheck = styled(ItemImage)`
	position: relative;
	cursor: pointer;
	*:before {
		content: '';
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		background: #ffffff;
		border: 1px solid #000000;
		top: 10px;
		left: 10px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		z-index: 100;
		${(props) =>
			props.selected &&
			`
			background-color:#000000;
			background-image: url(${Checkmark});
			background-repeat: no-repeat;
			background-position: center center;
		`}
	}
`;

// asset header
const ItemHeader = styled.header`
	box-sizing: border-box;
	padding: 23px 0 7px;
	h1 {
		font-family: 'Saira Extra Condensed', sans-serif;
		font-weight: 700;
		font-size: 20px;
		line-height: 31px;
		letter-spacing: 0.03em;
		margin: 0;
	}
`;

// asset body
const ItemBody = styled.div`
	margin-bottom: 33px;
	p {
		font-family: 'Saira', sans-serif;
		font-weight: 400;
		line-height: 18px;
		margin: 0;
		font-size: 14px;
	}
`;

const ItemBodyInline = styled(ItemBody)`
	margin-bottom: 16px;
`;

const ItemBodyExternal = styled(ItemBody)`
	p:not(:last-of-type) {
		margin-bottom: 1em;
	}
	a {
		text-decoration: underline;
	}
`;

// asset footer
const ItemFooter = styled.footer`
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-self: flex-end;
	span {
		width: 100%;
		height: 50px;
	}
`;

const ItemFooterCollection = styled(ItemFooter)`
	button {
		text-decoration: underline;
		color: #0067b1;
		font-size: 14px;
		letter-spacing: 0.05em;
		cursor: pointer;
		padding: 0;
	}
`;

const ItemWrap = styled.section`
	width: 100%;
	max-width: 304px;
	display: flex;
	flex-direction: column;
`;

const ItemWrapMonthly = styled(ItemWrap)`
	/* monthly assets don't have descriptions, need to add some space */
	> header {
		margin-bottom: 16px;
	}
`;

function ExternalListing(props) {
	const { preview, name, description, uri } = props.data;

	let _thumb =
		preview !== ''
			? `${process.env.PUBLIC_URL}/img/externalresources/${preview}`
			: NoImage;

	return (
		<ItemWrap>
			<ItemImage>
				<img src={_thumb} alt='' />
			</ItemImage>
			<ItemHeader>
				<h1>{name}</h1>
			</ItemHeader>
			<ItemBodyExternal>
				{description.map((v, k) => (
					<p key={k}>{Parser(v)}</p>
				))}
			</ItemBodyExternal>
			<ItemFooter>
				{uri !== '' ? (
					<Button
						rel='noopener noreferrer'
						target='_blank'
						href={uri}
						alt=''
						el='a'
					>
						Go
					</Button>
				) : null}
			</ItemFooter>
		</ItemWrap>
	);
}

function PriorityListing(props) {
	const { loading } = props;
	const { name, previewGalleryPaths, metadata } = props.data || {};

	const dispatch = useDispatch();

	return (
		<ItemWrapMonthly type={props.type}>
			<ItemImageFlagged
				onClick={() =>
					dispatch(
						downloadFile(metadata && `${metadata.path}-forPreview`, {
							open: true,
						})
					)
				}
			>
				{(loading ||
					(previewGalleryPaths && previewGalleryPaths.length > 0)) && (
					<CloudStorageImg
						loading={loading}
						path={previewGalleryPaths ? previewGalleryPaths[0] : null}
					/>
				)}
				{!loading && !(previewGalleryPaths && previewGalleryPaths.length > 0) && (
					// eslint-disable-next-line indent
					<img src={NoImage} alt='' />
					// eslint-disable-next-line indent
				)}
			</ItemImageFlagged>
			<ItemHeader>
				<h1>{name || <Skeleton />}</h1>
			</ItemHeader>
			<ItemFooter>
				{!loading ? (
					<>
						<CloudStorageDownloadButton path={metadata && metadata.path}>
							Download
						</CloudStorageDownloadButton>
					</>
				) : (
					<Skeleton />
				)}
			</ItemFooter>
		</ItemWrapMonthly>
	);
}

const showMetaData = ['size', 'dimensions'];

function CollectionListing(props) {
	const { loading } = props;
	const {
		name,
		previewGalleryPaths,
		metadata,
		dimensions,
		displayManualFormat,
		displayCategories,
		thumbnailPath,
	} = props.data || {};

	console.log(thumbnailPath);
	console.log(previewGalleryPaths);

	const mainImagePath =
		thumbnailPath || (previewGalleryPaths?.length && previewGalleryPaths[0]);

	return (
		<ItemWrap>
			<ItemImageWithCheck
				selected={props.selected}
				onClick={props.selectHandler}
			>
				{(loading || mainImagePath) && (
					<CloudStorageImg loading={loading} path={mainImagePath} />
				)}
				{!(loading || mainImagePath) && (
					// eslint-disable-next-line indent
					<img src={NoImage} alt='' />
					// eslint-disable-next-line indent
				)}
			</ItemImageWithCheck>
			<ItemHeader>
				<h1>{name}</h1>
			</ItemHeader>
			<ItemBodyInline>
				{(loading || (props.data && displayCategories)) && (
					<MetaData
						inline
						metakey={!loading && 'displayCategories'}
						metavalue={props.data && displayCategories}
						loading={loading}
						loadingValueCount={2}
						loadingValueWidth={'100%'}
					/>
				)}
				{(loading || (metadata && displayManualFormat)) && (
					<MetaData
						inline
						metakey={!loading && 'displayManualFormat'}
						metavalue={metadata && displayManualFormat}
						loading={loading}
						loadingValueCount={2}
						loadingValueWidth={'100%'}
					/>
				)}
				{!loading
					? /* eslint-disable */
					  metadata &&
					  Object.keys(metadata)
							.filter((k) => showMetaData.includes(k))
							.sort((a, b) => showMetaData.indexOf(a) - showMetaData.indexOf(b))
							.map((k, idx) => (
								<MetaData
									inline
									key={idx}
									metakey={k}
									metavalue={metadata[k]}
								/>
							))
					: Array.apply(null, Array(3)).map((_e, idx) => (
							<MetaData
								inline
								key={idx}
								loadingValueCount={1}
								loadingValueWidth={'40%'}
								loading={loading}
							/>
							/* eslint-enable */
					  ))}
				{(loading || (props.data && dimensions)) && (
					<MetaData
						inline
						metakey={!loading && 'dimensions'}
						metavalue={props.data && dimensions}
						loading={loading}
						loadingValueCount={2}
						loadingValueWidth={'100%'}
					/>
				)}
			</ItemBodyInline>
			<ItemFooterCollection>
				{!loading && (
					<button onClick={props.removeHandler}>Remove from Collection</button>
				)}
			</ItemFooterCollection>
		</ItemWrap>
	);
}

function AssetListing(props) {
	let Listing;
	switch (props.type) {
		case ListingTypes.MONTHLY:
			Listing = PriorityListing;
			break;
		case ListingTypes.COLLECTION:
			Listing = CollectionListing;
			break;
		default:
			Listing = ExternalListing;
			break;
	}

	return <Listing {...props} />;
}

AssetListing.propTypes = {
	type: PropTypes.oneOf([
		ListingTypes.ACCESSORY,
		ListingTypes.EXTERNAL,
		ListingTypes.MONTHLY,
		ListingTypes.COLLECTION,
	]),
};

AssetListing.defaultProps = {
	type: 'internal',
};

export default AssetListing;
export { ListingTypes };
