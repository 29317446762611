/* eslint-disable indent */
export const ACTION_TYPES = {
	SET_ADMIN_MODE: '_ADMIN_SET_ADMIN_MODE',
	UPDATE_UPLOAD_PROGRESS: '_ADMIN_UPDATE_UPLOAD_PROGRESS',
};

const initialState = {
	adminMode: false,
	uploadProgress: {},
};

export default function adminReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_ADMIN_MODE:
			return {
				...state,
				adminMode: action.payload,
			};
		case ACTION_TYPES.UPDATE_UPLOAD_PROGRESS:
			return {
				...state,
				[action.payload.id]: action.payload.progress,
			};
		default:
			return state;
	}
}
