import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
	refreshSearchClient,
	updateQuery,
} from '../../../config/store/actions/assetDocs';
import { debounce } from 'lodash';
import { ReactComponent as SearchIcon } from './searchIcon.svg';

const Wrapper = styled.div`
	max-width: 65%;
	width: 65%;
	div.searchBar {
		width: 100%;
		border-bottom: 1px solid black;
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		input {
			box-sizing: border-box;
			outline: none;
			height: 100%;
			border: none;
			width: 90%;
			::placeholder {
				color: black;
			}
		}
		.icon {
			cursor: pointer;
		}
	}
	div.error {
		padding-top: 8px;
		padding-bottom: 8px;
		color: red;
	}
	div.terms {
		min-width: 0;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;

		div.term,
		div.clear-all {
			margin-top: 10px;
			margin-right: 10px;
			background-color: black;
			padding: 8px;
			font-size: 15px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 400px;

			span {
				min-width: 0;
				white-space: nowrap;
				overflow: hidden;
				overflow-wrap: break-word;
				text-overflow: ellipsis;
				flex: 1;
				padding: 0;
				margin: 0;
			}

			.x-container {
				cursor: pointer;
				margin-left: 8px;
				margin-right: 4px;
				width: 15px;
				height: 15px;
				min-width: 15px;
				flex-basis: 15px;
				flex-shrink: 0;
				min-width: 0;
			}

			.x-1 {
				height: 15px;
				width: 2px;
				margin-left: 12px;
				background-color: white;
				transform: rotate(45deg);
				z-index: 1;
			}

			.x-2 {
				height: 15px;
				width: 2px;
				background-color: white;
				transform: rotate(90deg);
				z-index: 2;
			}
		}
		div.clear-all {
			background-color: white;
			border: 1px solid black;
			color: black;
			cursor: pointer;
			transition: all 0.5s;
			:hover {
				transition: all 0.5s;
				color: white;
				background-color: black;
			}
		}
	}
`;

const MAX_CHARS = 256;

const getFinalCharCount = (stringArr, newString = '') => {
	let count = newString.length + 1;
	stringArr.forEach((str) => (count += str.length + 1));
	return count;
};

const SearchBar = (props) => {
	const { refreshSearchClient, query, updateQuery, client } = props;
	const [queryArr, setQueryArr] = useState(query || []);

	useEffect(() => {
		setQueryArr(query || []);
	}, [query]);

	const [atCharLimit, setAtCharLimit] = useState(
		getFinalCharCount(queryArr) >= MAX_CHARS
	);

	const delayedUpdate = useCallback(debounce(updateQuery, 500), [updateQuery]);

	const updateLastIndex = (e) => {
		e.persist();
		let string = e.target.value.replace(/\s\s+/g, ' ');
		string = string.trimStart();
		const finalCharCount = getFinalCharCount(
			queryArr.slice(0, queryArr.length - 1),
			string
		);
		if (finalCharCount > MAX_CHARS) {
			setAtCharLimit(true);
			const difference = string.length - (finalCharCount - MAX_CHARS);
			string = string.substring(0, difference);
		} else if (atCharLimit) setAtCharLimit(false);
		setQueryArr((prev) => {
			let newArr = [...prev];
			const index = newArr.length > 0 ? newArr.length - 1 : 0;
			newArr[index] = string;
			return newArr;
		});
	};

	const pushNewTerm = () => {
		setQueryArr((prev) => {
			let newArr = [...prev];
			const index = newArr.length > 0 ? newArr.length - 1 : 0;
			if (newArr[index] && newArr[index].length > 1) newArr.push('');
			return newArr;
		});
	};

	const clearIdx = (idx) => {
		setQueryArr((prev) => {
			let newArr = [...prev];
			newArr.splice(idx, 1);
			if (atCharLimit && getFinalCharCount(newArr) <= MAX_CHARS) {
				setAtCharLimit(false);
			}
			return newArr;
		});
	};

	const clearAll = () => {
		setQueryArr([]);
		if (atCharLimit) setAtCharLimit(false);
	};

	const handleSearchBarKeyDown = (e) => {
		if (e.key === 'Enter') pushNewTerm();
	};

	useEffect(() => {
		refreshSearchClient();
	}, [refreshSearchClient]);

	useEffect(() => {
		if (!client) return;
		delayedUpdate(queryArr);
	}, [queryArr, delayedUpdate, client]);

	return (
		<Wrapper>
			<div className='searchBar'>
				<input
					type='text'
					value={queryArr[queryArr.length - 1] || ''}
					onChange={updateLastIndex}
					placeholder='Search for assets'
					onKeyDown={handleSearchBarKeyDown}
				/>
				<SearchIcon className='icon' onClick={pushNewTerm} />
			</div>
			{atCharLimit && (
				<div className='error'>
					Your search has reached the character limit. Please remove some text
					to continue searching.
				</div>
			)}
			<div className='terms'>
				{queryArr.slice(0, queryArr.length - 1).map((term, idx) => {
					return (
						<div className='term' key={idx}>
							<span>{term}</span>
							<div className='x x-container' onClick={() => clearIdx(idx)}>
								<div className='x x-1'>
									<div className='x x-2'></div>
								</div>
							</div>
						</div>
					);
				})}
				{queryArr.length > 3 && (
					<div className='clear-all' onClick={clearAll}>
						Clear All
					</div>
				)}
			</div>
		</Wrapper>
	);
};

const mapStateToProps = (state) => ({
	query: state.assetDocs.filterSort.query,
	client: state.assetDocs.searchClient.client,
});

const mapDispatchToProps = (dispatch) => ({
	updateQuery: (queryArr) => dispatch(updateQuery(queryArr)),
	refreshSearchClient: () => dispatch(refreshSearchClient()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
