import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MetaData from '../../_molecules/MetaData';
import ThumbnailPreviewer from '../../_molecules/ThumbnailViewer';
import CloudStorageDownloadButton from '../../_atoms/CloudStorageDownloadButton';
import ExIcon from '../../_atoms/ExIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
	collectionCartRemove,
	collectionCartAdd,
} from '../../../config/store/actions/collectionCart';
import Button from '../../_atoms/Button';

// overlays the entire screen
const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
`;

const Wrap = styled.div`
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.15);
	width: 1000px;
	min-height: 600px;
	position: relative;
	padding: 30px;
	display: flex;
`;

const CloseButton = styled.button`
	padding: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	border: none;
	background: none;
	border-radius: 0;
	outline: none;
	margin: 0;
	transform: translate(-25%, 25%);
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;

	section {
		padding-top: 20px;
		padding-left: 30px;
		padding-right: 20px;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex-grow: 1;

		footer {
			margin-top: auto;
			display: flex;
			justify-content: space-between;
		}

		a {
			display: inline-flex;
			max-width: 48%;
			width: 48%;
		}
	}

	header {
		margin-bottom: 10px;
	}

	h1 {
		font-family: 'Saira Extra Condensed', sans-serif;
		font-size: 30px;
		line-height: 47px;
		letter-spacing: 0.03em;
		margin: 0;
		font-weight: 700;
	}

	hr {
		border-color: #000000;
		border-width: 1px;
		margin-top: 24px;
		margin-bottom: 24px;
		width: 100%;
	}

	p {
		font-family: 'Saira', sans-serif;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		margin: 0;

		&:not(:last-of-type) {
			margin-bottom: 1em;
		}
	}
`;

function AssetPreview(props) {
	const { title, longDescription, shortDescription, meta, id } = props.data;
	const dispatch = useDispatch();

	const isInCollection = useSelector((state) => state.collectionCart.byId[id]);

	const toggleInCollection = () => {
		if (isInCollection) dispatch(collectionCartRemove(id));
		else dispatch(collectionCartAdd(id));
	};

	return (
		<Overlay>
			<Wrap>
				<CloseButton onClick={props.onCloseHandler}>
					<ExIcon />
				</CloseButton>
				<Content>
					<ThumbnailPreviewer data={props.data.preview} />
					<section>
						<header>
							<h1>{title}</h1>
						</header>
						<p>{longDescription}</p>
						<p>{shortDescription}</p>
						<hr />
						{Object.keys(meta).map((v) => (
							<MetaData metakey={v} key={v} metavalue={meta[v]} />
						))}
						<footer>
							<CloudStorageDownloadButton
								path={props.data.path}
								alt
								type='download'
							>
								Download
							</CloudStorageDownloadButton>
							{!isInCollection ? (
								<Button
									onClick={toggleInCollection}
									variant='dark'
									type='collection-add'
									el='a'
								>
									Add
								</Button>
							) : (
								<Button
									onClick={toggleInCollection}
									variant='light'
									type='collection-remove'
									el='a'
								>
									Remove
								</Button>
							)}
						</footer>
					</section>
				</Content>
			</Wrap>
		</Overlay>
	);
}

AssetPreview.propTypes = {
	// close handler
	// called by the close button, as well as the background
	onCloseHandler: PropTypes.func.isRequired,
	// data
	data: PropTypes.object.isRequired,
};

export default AssetPreview;
