import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import rewardsCTA from './cta-rewards.png';
import externalCTA from './cta-external.png';
import priorityCTA from './cta-priority.png';
import toolCTA from './cta-salestool.png';
import customShopCTA from './cta-customshop.png';
import hotCTA from './cta-hot.png';

const getImg = (variant) => {
	switch (variant) {
		case 'hot':
			return hotCTA;
		case 'priority':
			return priorityCTA;
		case 'external':
			return externalCTA;
		case 'rewards':
			return rewardsCTA;
		case 'sales':
			return toolCTA;
		case 'custom-shop':
			return customShopCTA;
		default:
			return null;
	}
};

const Wrap = styled.div`
	display: block;
	max-width: 276px;
	max-height: 101px;
	margin: 0 0 20px 0;
	&:first-child,
	&:last-child {
		margin: 0;
	}
`;

function CTANavButton(props) {
	return (
		<Wrap>
			{props.outbound ? (
				<a rel='noopener noreferrer' title={props.title} href={props.href}>
					<img src={getImg(props.type)} alt='' />
				</a>
			) : (
				<Link title={props.title} to={props.href}>
					<img src={getImg(props.type)} alt='' />
				</Link>
			)}
		</Wrap>
	);
}

CTANavButton.propTypes = {
	outbound: PropTypes.bool,
	type: PropTypes.oneOf(['external', 'priority', 'rewards']).isRequired,
};

export default CTANavButton;
