import React from 'react';
// import styled from 'styled-components';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import '@inovua/reactdatagrid-community/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Parts() {
	const [tableDataSource, setTableDataSource] = useState();
	const [multiSelect] = useState(true);

	const [items, setItems] = useState([]);
	const [value, setValue] = useState();
	const [selectedPartIds, setSelectedPartIds] = useState([]);
	const [uploadFlag, setUploadFlag] = useState(false);

	// ------RETRIEVES FIREBASE DATA------- //
	// Calling parts collection
	//changes JSON data into an array of objects to then be able to apply array methods
	useFirestoreConnect([
		{
			collection: 'parts',
			orderBy: ['partNumber', 'asc'],
			storeAs: 'parts',
		},
	]);

	const parts = useSelector((state) => state.firestore.ordered[`parts`]);

	useEffect(() => {
		setTableDataSource(parts);
	}, [parts]);

	// Calling partGroups collection
	useFirestoreConnect([
		{
			collection: 'partGroups',
			orderBy: ['groupName', 'asc'],
			storeAs: 'partGroups',
		},
	]);

	const partGroups = useSelector(
		(state) => state.firestore.ordered[`partGroups`]
	);

	useEffect(() => {
		setItems(partGroups);
	}, [partGroups]);

	const gridStyle = { minHeight: 550 };

	const columns = [
		{ name: 'id', header: 'ID', editable: false, defaultVisible: false },
		{ name: 'partNumber', header: 'Part Number', defaultFlex: 1 },
		{ name: 'partDescription', header: 'Part Description', defaultFlex: 1 },
		{ name: 'partNoun', header: 'Part Noun', defaultFlex: 1 },
		{ name: 'pptCode', header: 'ppt Code', defaultFlex: 1 },
		{ name: 'dealerListPrice', header: 'Dealer List Price', defaultFlex: 1 },
		{ name: 'dealerNetPrice', header: 'Dealer Net Price', defaultFlex: 1 },
		{ name: 'majorCode', header: 'Major Code', defaultFlex: 1 },
		{
			name: 'majorCodeDescription',
			header: 'Major Code Description',
			defaultFlex: 1,
		},
		{ name: 'minorCode', header: 'Minor Code', defaultFlex: 1 },
		{
			name: 'minorCodeDescription',
			header: 'Minor Code Description',
			defaultFlex: 1,
		},
	];

	const onSelectionChange = useCallback(({ data }) => {
		if (data.length > 0) {
			//Left click row
			setSelectedPartIds((current) => [...current, data[0].partId.toString()]);
		} else {
			//Ctrl Click rows
			setSelectedPartIds((current) => [...current, data.partId.toString()]);
		}
	}, []);

	const addPartToGroupName = () => {
		// Pull the partIds from state, and make the db call to update the partIds array
		if (!uploadFlag) {
			setUploadFlag(true);
			selectedPartIds.forEach((partId) => {
				const i = parts.findIndex(
					(e) => e.partNumber?.toString() === partId?.toString()
				);
				const toUploadPart = parts[i];
				if (i > -1) {
					const db = firebase.firestore();
					var docId = value;
					if (docId === undefined) {
						docId = items[0].id;
					}
					const docRef = db.collection('partGroups').doc(docId);
					docRef
						.update({
							partIds: firebase.firestore.FieldValue.arrayUnion({
								id: toUploadPart.id,
								partId: toUploadPart.partNumber,
							}),
						})
						.then(() => {
							window.alert('Parts Successfully Added');
						})
						.catch(() => {
							window.alert('Parts Did Not Successfully Add');
						});
				}
			});
			setUploadFlag(false);
		}
	};

	return (
		<React.Fragment>
			<Hero>
				<h1>Parts Collection</h1>
			</Hero>
			<header>
				<h1 style={{ marginTop: 20 }}>List view:</h1>
				<h2 style={{ marginBottom: 20 }}>
					1. Select a part or parts using the checkboxes
				</h2>
			</header>

			<div style={{ marginBottom: 30 }}>
				{tableDataSource && (
					<ReactDataGrid
						idProperty='id'
						columns={columns}
						dataSource={tableDataSource}
						editable={true}
						style={gridStyle}
						defaultSelected={false}
						pagination
						defaultLimit={10}
						theme='default-light'
						checkboxColumn
						multiSelect={multiSelect}
						defaultCellSelection={{}}
						onSelectionChange={onSelectionChange}
					/>
				)}
			</div>

			<div>
				<h2 style={{ marginTop: 20 }}>2. Choose a group name</h2>
				<label style={{ marginBottom: 20 }}>
					Select a Group Name
					<Form.Select
						size='lg'
						aria-label='Select a Group Name'
						placeholder='Select a Group Name'
						value={value}
						onChange={(e) => setValue(e.currentTarget.value)}
					>
						{items &&
							items.map((item) => (
								<option key={item.id} value={item.id}>
									{item.groupName}
								</option>
							))}
					</Form.Select>
					<h2 style={{ marginTop: 20 }}>
						3. Add the part to the desired group
					</h2>
					<Button
						onClick={() => addPartToGroupName()}
						style={{
							borderRadius: 0,
							paddingTop: 10,
							paddingBottom: 10,
							paddingLeft: 20,
							paddingRight: 20,
							fontSize: 16,
							width: 150,
							backgroundColor: '#005C9D',
							border: 0,
						}}
					>
						+ Add
					</Button>
				</label>
			</div>

			<Footer />
		</React.Fragment>
	);
}

export default Parts;
