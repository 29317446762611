import { ENVIRONMENTS, CURRENT_ENV } from './environments';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const FIREBASE_SDK_SNIPPETS = {
	[ENVIRONMENTS.DEV]: {
		apiKey: 'AIzaSyClKY28v_Ki1WVyGCBNPo-vdWOs9Dy2bJo',
		authDomain: 'mopar-portal-testing.firebaseapp.com',
		databaseURL: 'https://mopar-portal-testing.firebaseio.com',
		projectId: 'mopar-portal-testing',
		storageBucket: 'mopar-portal-testing.appspot.com',
		messagingSenderId: '754459925284',
		appId: '1:754459925284:web:f2e65096893562790ef1b1',
	},
	[ENVIRONMENTS.TESTING]: {
		apiKey: 'AIzaSyClKY28v_Ki1WVyGCBNPo-vdWOs9Dy2bJo',
		authDomain: 'mopar-portal-testing.firebaseapp.com',
		databaseURL: 'https://mopar-portal-testing.firebaseio.com',
		projectId: 'mopar-portal-testing',
		storageBucket: 'mopar-portal-testing.appspot.com',
		messagingSenderId: '754459925284',
		appId: '1:754459925284:web:f2e65096893562790ef1b1',
	},
	[ENVIRONMENTS.STAGING]: {
		apiKey: 'AIzaSyB-dtFMvZvPlaoIBCZI_UAdmCZbCIj9NJk',
		authDomain: 'mopar-portal-staging.firebaseapp.com',
		databaseURL: 'https://mopar-portal-staging.firebaseio.com',
		projectId: 'mopar-portal-staging',
		storageBucket: 'mopar-portal-staging.appspot.com',
		messagingSenderId: '602707628630',
		appId: '1:602707628630:web:2ca45e28ddfa2ec7b4e1f7',
	},
	[ENVIRONMENTS.PRODUCTION]: {
		apiKey: 'AIzaSyC0QZQoIjE0XC8XpvDUtttHV5QjAgTtD-g',
		authDomain: 'mopar-portal-production.firebaseapp.com',
		databaseURL: 'https://mopar-portal-production.firebaseio.com',
		projectId: 'mopar-portal-production',
		storageBucket: 'mopar-portal-production.appspot.com',
		messagingSenderId: '851958127013',
		appId: '1:851958127013:web:38f38c70a57b50e9c6704c',
	},
};

const CONFIG = FIREBASE_SDK_SNIPPETS[CURRENT_ENV];

firebase.initializeApp(CONFIG);

// Use emulators in dev environment
if (CURRENT_ENV === ENVIRONMENTS.DEV) {
	//firebase.auth().useEmulator('http://localhost:9099');
	//firebase.firestore().useEmulator('localhost', 8080);
	//firebase.functions().useEmulator('locahost', 5001);
}

firebase.auth();
firebase.firestore();
firebase.functions();
firebase.storage();

const storage = firebase.storage();
//firebase.analytics();

export { storage, firebase as default };
// export default firebase;
// export default storage;
