import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RouteGuard from './components/_utilities/RouteGuard';
import EditAssetPage from './pages/EditAsset';
import LoginPage from './pages/Login';
import Home from './pages/Home';
import NewAssetPage from './pages/NewAsset';
import LoginSSOCallbackHandlerPage from './pages/LoginSSOCallbackHandler';
import HomeEditPage from './pages/HomeEdit';

import Header, { HeaderNav } from './components/_molecules/Header';
import WaitForAuth from './components/_utilities/WaitForAuth';

import Routes from './pages';
import RenderGuard from './components/_utilities/RenderGuard';

import { setShow } from './config/store/actions/userTutorial';
import NewMonthlyPriorityPage from './pages/NewMonthlyPriority';
import EditMonthlyPriorityPage from './pages/EditMonthlyPriority';
import DPLogin from './pages/DPLogin';

import TagManager from 'react-gtm-module';
import WindowNamer from './components/_utilities/WindowNamer';
import CollectionCartListener from './components/_utilities/CollectionCartListener';
import PartChanges from './pages/PartChanges';
import { MobileBlocker } from './components/_utilities/MobileBlocker/MobileBlocker';

const tagManagerArgs = {
	gtmId: 'GTM-588XB8H',
};

// Add GTM container code.
// Container env can be specified in tagManagerArgs.
TagManager.initialize(tagManagerArgs);

export default function App() {
	const dispatch = useDispatch();

	return (
		<MobileBlocker>
			<Router>
				<WindowNamer />
				<Header>
					<HeaderNav>
						<a
							href='https://www.moparrepairconnect.com/dA/4ff5665807/Warranties_Complete_Statement.pdf'
							title='Click here for warranty information.'
							target='_blank'
							rel='noopener noreferrer'
						>
							Warranty
						</a>
						
						<Link to={Routes.faq.path}>{Routes.faq.title}</Link>
						<RenderGuard>
							<button onClick={() => dispatch(setShow(true))} title='Help'>
								Help
							</button>
						</RenderGuard>
					</HeaderNav>
				</Header>
				<main>
					<WaitForAuth>
						<CollectionCartListener />
						<Switch>
							{Object.keys(Routes).map((v, idx) => {
								let R = Routes[v];
								return (
									<Route key={idx} exact path={R.path}>
										<RouteGuard>
											<R.component />
										</RouteGuard>
									</Route>
								);
							})}
							<Route exact path='/edit'>
								<RouteGuard
									requirePermissions={[
										'assets.write.live',
										'assets.write.draft',
									]}
								>
									<HomeEditPage />
								</RouteGuard>
							</Route>
							<Route exact path='/part-changes-email/:id'>
								<RouteGuard requirePermissions={['partsData.read']}>
									<PartChanges />
								</RouteGuard>
							</Route>
							<Route exact path='/edit-asset/:id'>
								<RouteGuard
									requirePermissions={[
										'assets.write.live',
										'assets.write.draft',
									]}
								>
									<EditAssetPage />
								</RouteGuard>
							</Route>
							<Route exact path='/edit-monthly-priority/:id'>
								<RouteGuard
									requirePermissions={[
										'assets.write.live',
										'assets.write.draft',
									]}
								>
									<EditMonthlyPriorityPage />
								</RouteGuard>
							</Route>
							<Route exact path='/new-asset'>
								<RouteGuard requirePermissions={['assets.write.draft']}>
									<NewAssetPage />
								</RouteGuard>
							</Route>
							<Route exact path='/new-monthly-priority'>
								<RouteGuard requirePermissions={['assets.write.draft']}>
									<NewMonthlyPriorityPage />
								</RouteGuard>
							</Route>
							<Route exact={true} path='/login/_callbackHandler'>
								<LoginSSOCallbackHandlerPage />
							</Route>
							<Route exact={true} path='/login'>
								<RouteGuard requireNoAuth={true}>
									<LoginPage />
								</RouteGuard>
							</Route>
							<Route exact={true} path='/dp'>
								<RouteGuard requireNoAuth={true}>
									<DPLogin />
								</RouteGuard>
							</Route>
							<Route path='/'>
								<RouteGuard redirectProps={{ to: '/login' }}>
									<Home />
								</RouteGuard>
							</Route>
						</Switch>
					</WaitForAuth>
				</main>
			</Router>
		</MobileBlocker>
	);
}
