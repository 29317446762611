import React from 'react';
import Hero from '../components/_molecules/Hero';
import ListingContainer from '../components/_organisms/ListingContainer';
import Footer from '../components/_atoms/Footer';

const RESOURCES = [
	{
		name: 'Mopar Dealer Site',
		description: [
			'The Mopar Dealer Site is your online resource for Mopar parts, programs, marketing and news from Mopar HQ.',
		],
		uri: 'https://dealers-mopar.com',
		preview: 'ExternalResources_DealerSite_304x200.jpg',
	},
	{
		name: 'Mopar Accessory Sales Tool',
		description: [
			'The Mopar Accessory Sales Tool is your one-stop solution for presenting, quoting and selling accessories across all departments and channels. This tool makes it easy to track your sales and get the most up-to-date information directly from Mopar.',
			'<a target="_blank" rel="nofollow noreferrer noopener" href="https://vimeo.com/555247547/cab105c252">Click here</a> to see how the Mopar Accessory Sales Tool can work for you.',
		],
		uri:
			'https://www.dealers-mopar.com/moparone/accessories/programs/sales-tool.action',
		preview: 'ExternalResources_SalesTool_304x200.jpg',
	},
	{
		name: 'Mopar Affiliated Accessories',
		description: [
			'Explore new offerings and new opportunities through our Mopar Affiliated Accessories program that is now live.',
			'Access Affiliated Accessories through DealerCONNECT. Navigate to the Parts tab and find the Order Parts section. Within that section, click on the TireWorks, WheelWorks and Affiliate Accessories Order Entry link.',
		],
		uri: '',
		preview: 'ExternalResources_AffiliatedAccessories_304x200.png',
	},
	{
		name: 'Mopar Merchandise',
		description: [
			'Mopar merchandise is built to embody the very best of Mopar, past and present. Whether it’s for yourself or a gift for your favorite Mopar fan, you can find apparel, garage gear and more from the brand you love right here.',
		],
		uri: 'https://www.wearmopar.com/',
		preview: 'ExternalResources_Merch_304x200.jpg',
	},
	{
		name: 'Mopar Accessory Rewards',
		description: [
			'The Mopar Accessory Rewards program supports dealers in achieving their full potential on Mopar Accessory business with incentives for doing so.',
		],
		uri:
			'https://www.dealers-mopar.com/moparone/accessory/accessory-rewards.action?main',
		preview: 'ExternalResources_MAR_304x200.jpg',
	},
	{
		name: 'DealerCONNECT',
		description: [
			'DealerCONNECT is an intranet portal tool created by FCA to provide information and services to dealership personnel and FCA employees. This platform facilitates the sale of vehicles, parts and merchandise, as well as provide our customers with a pleasant service experience. ',
		],
		uri: 'https://dealerconnect.chrysler.com',
		preview: 'ExternalResources_DealerConnect_304x200.jpg',
	},

	{
		name: 'Mopar WheelWorks',
		description: [
			'Explore wheel offerings from Black Rhino through our Mopar WheelWorks program that is now live!',
			'Access WheelWorks through DealerCONNECT. Navigate to the Parts tab and find the Order Parts section on that tab. Within that section, find and click on the TireWorks and WheelWorks Order link.',
		],
		uri: '',
		preview: 'ExternalResources_WheelWorks_304x200.jpg',
	},
	{
		name: 'Mopar Katzkin® Leather',
		description: [
			'sellmoparleather.com is truly a one-stop shop for your dealership, and your customers. Get all of the tools that you need to sell custom leather interiors through Mopar in one place.',
		],
		uri: 'http://sellmoparleather.com/',
		preview: 'ExternalResources_Katzkin_304x200.jpg',
	},
	{
		name: 'Mopar Planograms',
		description: [
			'Mopar planograms are designed to maximize your floor plan space by arranging items in a way that helps them sell. Increase sales and create a pleasant experience for customers by providing a uniform and detailed sales layout for your dealership.',
		],
		uri:
			'https://www.dealers-mopar.com/moparone/accessories/store/resources/planograms.jsp',
		preview: 'ExternalResources_planograms_304x200.jpg',
	},

	{
		name: 'Accessory Consumer Brochures',
		description: [
			'Get the Accessory Consumer Brochures you need for your dealership. Now available in a digital format, each with a unique URL that makes it easy for you and your customers to view and download. Share the links in your communications, promote on your digital platforms, and connect your customers with all the Mopar accessory information they need!',
		],
		uri: '/assets/Accessory_Consumer_Brochures.pdf',
		preview: 'ExternalResources_AccyBroch_304x200.jpg',
	},
];

function ExternalResources() {
	return (
		<React.Fragment>
			<Hero bg='external-resources-masthead.jpg'>
				<h1>RESOURCES</h1>
				<p>
					Here you’ll find all the Mopar<sub>®</sub> resources you may need to
					support your Accessory sales. To access any of these resources, simply
					click the link to be redirected to that page.
				</p>
			</Hero>
			<ListingContainer data={RESOURCES} />
			<Footer />
		</React.Fragment>
	);
}

export default ExternalResources;
