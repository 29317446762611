import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { newEntry } from '../config/store/actions/admin';

const NewAssetPage = (props) => {
	const { createNewAsset } = props;
	const history = useHistory();
	useEffect(() => {
		createNewAsset().then(({ docId }) =>
			history.replace(`/edit-asset/${docId}`)
		);
	}, [props.createNewAsset, history, createNewAsset]);
	return 'Creating new asset...';
};

const mapDispatchToProps = (dispatch) => ({
	createNewAsset: () => dispatch(newEntry('assets')),
});

export default connect(null, mapDispatchToProps)(NewAssetPage);
