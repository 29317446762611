import ReactDataGrid from '@inovua/reactdatagrid-community';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import firebase from 'firebase';

import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

function ModalComp(props) {
	const [modalData, setModalData] = useState();
	const [selected, setSelected] = useState(null);
	const [items, setItems] = useState([]);
	const [value, setValue] = useState();

	// Calling parts collection
	useFirestoreConnect([
		{
			collection: 'parts',
			orderBy: ['partNumber', 'asc'],
			storeAs: 'parts',
		},
	]);

	const parts = useSelector((state) => state.firestore.ordered[`parts`]);

	useEffect(() => {
		setItems(parts);
	}, [parts]);

	useEffect(() => {
		if (props.data) {
			const myArr = props.data.map((part) => {
				return part.partIds?.map((tempPartId, i) => ({
					id: i,
					partId: tempPartId.partId,
				}));
			});
			if (myArr[0] === undefined) {
				myArr[0] = [];
			}
			setModalData(myArr[0]);
		}
	}, [props.data]);

	const gridStyle = { minHeight: 550 };

	const columns = [
		{
			name: 'partId',
			header: 'Part Ids',
			defaultFlex: 1,
		},
	];

	const addNewPart = () => {
		if (value !== undefined) {
			var pickedItem = items.find((obj) => {
				return obj.id === value;
			});
			const db = firebase.firestore();
			const docRef = db.collection('partGroups').doc(props.show.data.id);
			docRef
				.update({
					partIds: firebase.firestore.FieldValue.arrayUnion({
						id: value,
						partId: pickedItem.partId,
					}),
				})
				.then(() => {
					modalData.push({ id: value, partId: pickedItem.partId });
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const onSelectionChange = useCallback(({ selected: selectedMap }) => {
		const newSelected = Object.keys(selectedMap).map((id) => id * 1);
		setSelected(newSelected);
	}, []);

	// Remove a part or parts from the list
	const handlePartDelete = () => {
		if (selected.length > 0) {
			const filteredArr = modalData.filter(
				(part) => !selected.includes(part.id)
			);

			const newPartIdsArray = filteredArr.map((part) => {
				return part;
			});
			// // 2. Make firebase call to update the partIds array in the doc with the new partIds array based on the filter and map
			const db = firebase.firestore();
			const partRef = db.collection('partGroups').doc(props.show.data.id);
			// // Set the partIds array to the new filteres and mapped array
			partRef
				.update({
					partIds: newPartIdsArray,
				})
				.then(() => {
					setModalData(filteredArr);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	return (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.show.data ? props.show.data.groupName : null}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4>List of Parts</h4>
				<p>Hold CTRL or Shift down to multiselect rows</p>
				<div style={{ marginBottom: 20 }}></div>
				<div>
					{modalData && (
						<ReactDataGrid
							idProperty='id'
							columns={columns}
							style={gridStyle}
							dataSource={modalData}
							onSelectionChange={onSelectionChange}
							defaultSelected={false}
							multiSelect
							theme='default-light'
						/>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<label>
						Select a Part Number
						<Form.Select
							size='lg'
							aria-label='Select a Part Number'
							placeholder='Select a Part Number'
							value={value}
							onChange={(e) => setValue(e.currentTarget.value)}
						>
							{items &&
								items.map((item, key) => (
									<option key={key} value={item.id}>
										{item.partNumber}
									</option>
								))}
						</Form.Select>
					</label>
				</div>
				<Button
					onClick={() => addNewPart()}
					style={{
						borderRadius: 0,
						paddingTop: 10,
						paddingBottom: 10,
						paddingLeft: 20,
						paddingRight: 20,
						fontSize: 16,
						width: 100,
						backgroundColor: '#005C9D',
						border: 0,
					}}
				>
					+ Add
				</Button>
				<Button
					variant='danger'
					type='button'
					onClick={() => handlePartDelete()}
					style={{
						borderRadius: 0,
						paddingTop: 10,
						paddingBottom: 10,
						paddingLeft: 20,
						paddingRight: 20,
						fontSize: 16,
						width: 100,
						border: 0,
					}}
				>
					Remove
				</Button>
				<Button
					variant='success'
					onClick={props.onHide}
					style={{
						borderRadius: 0,
						paddingTop: 10,
						paddingBottom: 10,
						paddingLeft: 20,
						paddingRight: 20,
						fontSize: 16,
						width: 100,
						border: 0,
					}}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ModalComp;
