import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconNext from './icon-next.svg';
import IconPrev from './icon-prev.svg';
import CloudStorageImg from '../../_utilities/CloudStorageImg';

const Wrap = styled.div`
	width: 454px;

	/* houses the selected image */
	figure {
		margin: 0 0 29px;
		width: 454px;
		height: 458px;
	}

	/* controls and thumbnails */
	footer {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		height: 50px;
	}

	/* wrapper for the "carousel" of thumbnails */
	.thumbnail-wrap {
		overflow: hidden;
		height: 100%;
		width: 385px;
		position: relative;
	}

	/* holds all of the thumbnails */
	.thumbnails {
		box-sizing: border-box;
		display: flex;
		position: relative;
		left: ${(props) => props.page * -400}px;
		transition: left 250ms ease-out;
	}

	/* navigation button */
	button.control {
		height: 100%;
		width: 27px;
		flex-grow: 1;
		border: none;
		cursor: pointer;
		background: none;
		background-repeat: no-repeat;
		outline: none;

		&.prev {
			background-position: left center;
			background-image: url(${IconPrev});
		}

		&.next {
			background-position: right center;
			background-image: url(${IconNext});
		}

		&:disabled {
			opacity: 0.25;
			cursor: default;
		}
	}

	/* thumbnail */
	button.thumbnail {
		border: none;
		padding: 0;
		margin: 0;
		width: 65px;
		height: 50px;
		flex: 0 0 65px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		outline: none;
		box-sizing: border-box;

		&:not(:last-child) {
			margin-right: 15px;
		}

		&.active {
			border: 2px solid #0067b1;
		}

		/*
			override size for figure coming in from 
			cloudstorageimg
		 */
		figure {
			width: 100%;
			height: 100%;
		}
	}
`;

function ThumbnailViewer(props) {
	let _pages = 0;
	if (props.data && props.data.length) {
		_pages = Math.ceil(props.data.length / 5) - 1;
	}

	const [currentIdx, setIndex] = useState(0);
	const [currentPage, setPage] = useState(0);

	return (
		<Wrap page={currentPage}>
			<figure>
				<CloudStorageImg
					path={props.data && props.data.length && props.data[currentIdx]}
				/>
			</figure>
			{props.data && props.data.length && props.data.length > 1 ? (
				<footer>
					<button
						onClick={() => setPage(currentPage - 1)}
						className='control prev'
						disabled={currentPage <= 0}
					></button>
					<div className='thumbnail-wrap'>
						<div className='thumbnails'>
							{props.data &&
								props.data.length &&
								props.data.map((i, idx) => (
									<button
										onClick={() => setIndex(idx)}
										key={idx}
										className={`thumbnail ${
											idx === currentIdx ? 'active' : ''
										}`}
									>
										<CloudStorageImg path={i} />
									</button>
								))}
						</div>
					</div>
					<button
						onClick={() => setPage(currentPage + 1)}
						className='control next'
						disabled={currentPage >= _pages}
					></button>
				</footer>
			) : null}
		</Wrap>
	);
}

ThumbnailViewer.propTypes = {
	/**
	 * example (bare minimum):
	 * [{
	 * 	src: path.to.image.jpg
	 * }]
	 */
	data: PropTypes.array.isRequired,
};

export default ThumbnailViewer;
