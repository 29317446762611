import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactHTMLParser from 'react-html-parser';

const Wrap = styled.section`
	margin-bottom: 2em;
	h1,
	h2,
	ol,
	ul,
	p {
		font-family: 'Saira', sans-serif;
		font-size: 18px;
		line-height: 28px;
	}
	h1 {
		font-weight: 600;
		letter-spacing: 0.05em;
		margin: 0 0;
	}

	h2 {
		text-decoration: underline;
		font-weight: 400;
		margin-bottom: 0;
	}

	p {
		margin: 0 0 1em;
		font-weight: 400;

		sub {
			bottom: -0.08em;
		}
	}

	ol,
	ul {
		padding-left: 1em;
	}
`;

function FAQ(props) {
	return (
		<Wrap>
			<h1>
				{props.index ? <span>{`Q${props.index}: `}</span> : null}
				{props.question}
			</h1>
			{ReactHTMLParser(props.answer)}
		</Wrap>
	);
}

FAQ.propTypes = {
	question: PropTypes.string.isRequired,
	answer: PropTypes.string.isRequired,
	index: PropTypes.number,
};

FAQ.defaultProps = {};

export default FAQ;
