import ContactUs from './ContactUs';
import MonthlyPriority from './MonthlyPriority';
import ExternalResources from './ExternalResources';
import Faq from './Faq';
import MyCollection from './MyCollection';
import DownloadHistory from './DownloadHistory';
import CustomShop from './CustomShop';
import PartGroups from './PartGroups';
import PartChanges from './PartChanges';
import Parts from './Parts';
import WhatsHot from './WhatsHot';

const Routes = {
	contact: {
		title: 'Contact Us',
		path: '/contact-us',
		component: ContactUs,
		guards: [],
	},
	customShop: {
		title: 'Custom Shop Assets',
		path: '/custom-shop',
		component: CustomShop,
		guards: [],
	},
	externalResources: {
		title: 'External Resources',
		path: '/external-resources',
		component: ExternalResources,
		guards: [],
	},
	monthlyPriority: {
		title: 'Monthly Priority',
		path: '/monthly-priority',
		component: MonthlyPriority,
		guards: [],
	},
	whatsHot: {
		title: `What's Hot`,
		path: '/whats-hot',
		component: WhatsHot,
		guards: [],
	},
	faq: {
		title: 'FAQ',
		path: '/faq',
		component: Faq,
		guards: [],
	},
	collection: {
		title: 'My Collection',
		path: '/my-collection',
		component: MyCollection,
		guards: [],
	},
	downloadHistory: {
		title: 'Download History',
		path: '/download-history',
		component: DownloadHistory,
		guards: [],
	},
	partGroups: {
		title: 'Part Groups',
		path: '/part-groups',
		component: PartGroups,
		guards: [],
	},
	partChanges: {
		title: 'Part Changes',
		path: '/part-changes',
		component: PartChanges,
		guards: [],
	},
	parts: {
		title: 'Parts',
		path: '/parts',
		component: Parts,
		guards: [],
	},
};

export default Routes;
