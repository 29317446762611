import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function getHeight(baseLength) {
	return (baseLength * Math.sqrt(3)) / 2;
}

const Wrap = styled.div`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	position: relative;
`;

const Orb = styled.span`
	display: block;
	background: #0067b1;
	width: ${(props) => props.orbSize}px;
	height: ${(props) => props.orbSize}px;
	border-radius: 50%;
	position: absolute;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
	animation-duration: 550ms;

	&:first-child {
		animation-name: moveA;
		top: 0;
		left: ${(props) => `${(props.containerWidth - props.orbSize) / 2}px`};
		@keyframes moveA {
			75%,
			100% {
				left: 0;
				top: ${(props) => `${props.containerHeight - props.orbSize}px`};
			}
		}
	}
	&:nth-child(2) {
		animation-name: moveB;
		bottom: 0;
		left: 0;
		@keyframes moveB {
			75%,
			100% {
				left: ${(props) => `${props.containerWidth - props.orbSize}px`};
			}
		}
	}
	&:last-child {
		animation-name: moveC;
		right: 0;
		top: ${(props) => `${props.containerHeight - props.orbSize}px`};
		@keyframes moveC {
			75%,
			100% {
				right: ${(props) => `${(props.containerWidth - props.orbSize) / 2}px`};
				top: 0;
			}
		}
	}
`;

function LoadingIcon(props) {
	let _height = getHeight(props.width);

	return (
		<Wrap height={_height} width={props.width}>
			<Orb
				containerWidth={props.width}
				containerHeight={_height}
				orbSize={props.orbSize}
			/>
			<Orb
				containerWidth={props.width}
				containerHeight={_height}
				orbSize={props.orbSize}
			/>
			<Orb
				containerWidth={props.width}
				containerHeight={_height}
				orbSize={props.orbSize}
			/>
		</Wrap>
	);
}

LoadingIcon.propTypes = {
	// size of the orbs
	orbSize: PropTypes.number.isRequired,
	// length of the base of the triangle
	width: PropTypes.number.isRequired,
};

LoadingIcon.defaultProps = {
	orbSize: 40,
	width: 160,
};

export default LoadingIcon;
