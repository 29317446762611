import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withRouter, useHistory } from 'react-router-dom';
import Select from 'react-select';
import CreateableSelect from 'react-select/creatable';
import styled from 'styled-components';
import {
	uploadFile,
	uploadGalleryImages,
	deleteFile,
	deleteGalleryImage,
	updateField,
	deleteDoc,
	shiftPreviewGalleryImageOrder,
	updateCategories,
	updateFormat,
} from '../config/store/actions/admin';
import CloudStorageImg from '../components/_utilities/CloudStorageImg';
import CloudStorageDownloadButton from '../components/_atoms/CloudStorageDownloadButton';
import _ from 'lodash';

const Wrapper = styled.div`
	padding: 5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.id {
		width: 100%;
		text-align: center;
	}

	h1 {
		width: 100%;
		text-align: center;
	}

	> div {
		width: 25%;
		margin: 25px;
		display: flex;
		flex-direction: column;
	}

	.delete {
		color: red;
		width: 150px;
		margin-bottom: 10px;
	}

	.checkbox {
		padding-top: 15px;
		label {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		input {
			margin: 0 8px;
			width: 20px;
			height: 20px;
		}
		span {
			font-size: 16px;
			display: 'inline-block';
		}
	}

	.delete-gallery {
		color: red;
	}

	.delete-it-all {
		color: red;
	}

	.asset-file,
	.preview-gallery,
	.tn-upload {
		width: 100%;
		border: 1px black solid;
		padding: 10px;
		input {
			margin: 10px 0;
		}
		p {
			margin: 5px 0;
		}
	}

	#tn-container {
		width: 100%;
		max-width: 400px;
		height: 200px;
	}

	.preview-gallery {
		> div {
			display: flex;
			width: 100%;
			justify-content: space-between;
			flex-wrap: wrap;
			> div.item {
				flex-grow: 1;
				flex-basis: 120px;
				margin: 15px 5px;
				height: 160px;
				border: 1px black solid;
				background-color: black;
				.controls {
					display: flex;
					justify-content: space-around;

					button {
						background-color: white;
					}
				}
			}
		}
	}
`;

const multiSelectOptionsParser = (options) => {
	return options.map((o) => ({
		value: o.value,
		label: o.display,
	}));
};

const keywordSelectOptionsParser = (keywords) => {
	if (!keywords) return [];
	return keywords.map((k) => ({
		value: k,
		label: k,
	}));
};

const formatValues = (values, originalOptions) => {
	if (!Array.isArray(values)) values = [values];
	const parsedOptions = multiSelectOptionsParser(originalOptions);
	return values.map((v) => parsedOptions.find((o) => v === o.value));
};

const formatKeywordValues = (values) => {
	if (!values) return [];
	if (!Array.isArray(values)) values = [values];
	const parsedOptions = keywordSelectOptionsParser(values);
	return values.map((v) => parsedOptions.find((o) => v === o.value));
};

const cleanObj = (obj) => {
	const cleaned = _.cloneDeep(obj);
	Object.keys(cleaned).forEach((k) => {
		if (!cleaned[k] || (Array.isArray(cleaned[k]) && cleaned[k].length < 1)) {
			delete cleaned[k];
		} else if (!Array.isArray(cleaned[k]) && typeof cleaned[k] === 'object') {
			cleaned[k] = cleanObj(cleaned[k]);
			if (Object.values(cleaned[k]).length < 1) {
				delete cleaned[k];
			}
		}
	});
	return cleaned;
};

const EditAssetPage = (props) => {
	const assetId = props.match.params.id;

	useFirestoreConnect([
		{
			collection: 'assets',
			doc: assetId,
			storeAs: 'editAsset',
		},
		{
			collection: 'config',
			doc: 'dataModel',
			storeAs: 'dataModel',
		},
	]);

	const dataModel = useSelector((state) => state.firestore.data.dataModel);
	const sourceAsset = useSelector((state) => state.firestore.data.editAsset);
	const sourceAssetErrors = useSelector(
		(state) => state.firestore.errors.byQuery.editAsset
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const [loadingFields, setLoadingFields] = useState({});
	const [fields, setFields] = useState({});

	const handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setFields((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleUpdateField = useCallback(
		(field, value) => {
			console.log('updating: ', field, value);
			setLoadingFields((prev) => ({ ...prev, [field]: true }));
			dispatch(
				updateField('assets', assetId, field, value, () =>
					setLoadingFields((prev) => ({ ...prev, [field]: false }))
				)
			);
		},
		[setLoadingFields, dispatch, assetId]
	);

	const handleCategoryChange = (name, value) => {
		let values;
		if (!Array.isArray(value)) {
			values = value ? value.value : '';
		} else {
			values = value.map((v) => v.value);
		}

		let newCategoriesObj = _.cloneDeep(fields.categories);

		if (!values || values.length < 1) {
			_.unset(newCategoriesObj, name);
		} else {
			_.set(newCategoriesObj, name, values);
		}

		newCategoriesObj = cleanObj(newCategoriesObj);

		setFields((prev) => {
			return {
				...prev,
				categories: newCategoriesObj,
			};
		});

		console.log(newCategoriesObj);
		dispatch(updateCategories(assetId, newCategoriesObj, dataModel));
	};

	const handleCreatableChange = useCallback(
		(id, value) => {
			let values;
			if (!Array.isArray(value)) {
				values = value ? value.value : '';
			} else {
				values = value.map((v) => v.value);
			}
			setFields((prev) => {
				if (!prev[id] || !_.isEqual(prev[id], values)) {
					handleUpdateField(id, values);
				}
				return {
					...prev,
					[id]: values,
				};
			});
		},
		[setFields, handleUpdateField]
	);

	const handleFormatChange = (value) => {
		let newValue = value || '';
		setFields((prev) => {
			return {
				...prev,
				displayManualFormat: newValue,
			};
		});
		console.log(newValue);
		dispatch(updateFormat(assetId, newValue, dataModel));
	};

	const handleAssetUploadChange = (e) => {
		const files = Array.from(e.target.files);
		dispatch(
			uploadFile('assets', assetId, files[0], (_, __) => {
				console.log(_ / __);
			})
		);
	};

	const handleGalleryUploadChange = (e) => {
		const files = Array.from(e.target.files);
		dispatch(uploadGalleryImages('assets', assetId, files));
	};

	const handleThumbnailUploadChange = (e) => {
		const files = Array.from(e.target.files);
		dispatch(uploadGalleryImages('assets', assetId, files, true));
	};

	const handleDeleteAssetFile = () => {
		if (
			window.confirm(
				'Are you sure you want to delete the file associated with this asset?'
			)
		) {
			dispatch(deleteFile(sourceAsset.metadata.path));
		}
	};

	const handleDeleteGalleryImage = (path) => {
		if (window.confirm('Are you sure you want to delete this Gallery Image?')) {
			dispatch(deleteGalleryImage(path));
		}
	};

	const handleGalleryImageShift = (path, forward) => {
		dispatch(shiftPreviewGalleryImageOrder('assets', assetId, path, forward));
	};

	const handleDeleteEntireAsset = () => {
		if (
			window.confirm(
				'Are you sure you want to delete this Asset and all associated files?'
			)
		) {
			if (window.confirm('Are you really sure?')) {
				dispatch(deleteDoc('assets', assetId)).then(() => history.push('/'));
			}
		}
	};

	useEffect(() => {
		let categories = {};
		if (sourceAsset && sourceAsset.categories)
			categories = sourceAsset.categories;
		setFields((prev) => ({
			...prev,
			categories: categories,
			...(sourceAsset || {}),
		}));
	}, [sourceAsset, setFields]);

	if (!isLoaded(sourceAsset) || !isLoaded(dataModel))
		return 'LOADING ASSET DATA';
	if (isEmpty(sourceAsset)) return 'COULD NOT LOCATE ASSET';
	if (sourceAssetErrors) return 'ERROR LOADING ASSET';

	return (
		<Wrapper>
			<p className='id'>ID: {assetId}</p>
			<div className='select'>
				<label htmlFor='status'>Status</label>
				<select
					id='status'
					type='select'
					value={fields['status']}
					onChange={(e) => handleUpdateField('status', e.target.value)}
				>
					<option value='live'>Live</option>
					<option value='draft'>Draft</option>
				</select>
			</div>
			<div className='checkbox'>
				<label>
					<input
						type='checkbox'
						checked={fields['hotAsset']}
						onChange={(e) => {
							handleUpdateField('hotAsset', e.target.checked);
							if (!fields['hotAssetOrder'] && fields['hotAssetOrder'] !== 0) {
								handleUpdateField('hotAssetOrder', 0);
							}
						}}
					/>
					<span>Hot Asset</span>
				</label>
			</div>
			{fields['hotAsset'] && (
				<div className='number'>
					<label htmlFor='hotAssetOrder'>Hot Asset Priority</label>
					<input
						id='hotAssetOrder'
						type='number'
						value={fields['hotAssetOrder']}
						onChange={(e) =>
							handleUpdateField(
								'hotAssetOrder',
								parseFloat(e.target.value ?? '0')
							)
						}
					/>
				</div>
			)}
			{['name', 'dimensions'].map((key) => (
				<div key={key} className='text'>
					<label htmlFor={key}>{key.toUpperCase()}</label>
					<input
						id={key}
						name={key}
						type='text'
						value={fields[key]}
						onChange={handleChange}
						onBlur={() => handleUpdateField(key, fields[key])}
					/>
				</div>
			))}
			<h1>Categories</h1>
			{dataModel.categories.map((category, idx) => {
				const { key } = category;
				let value = category.defaultValue;
				if (fields.categories && fields.categories[key]) {
					value = fields.categories[key];
				}
				return (
					<div className={category.type} key={idx}>
						<label>{category.display}</label>
						{!category.nested && category.type === 'select' && (
							<Select
								{...category}
								isMulti={category.multiple}
								name={key}
								id={key}
								key={idx}
								onChange={(v) => handleCategoryChange(key, v)}
								options={multiSelectOptionsParser(category.options)}
								value={formatValues(value, category.options)}
								disabled={loadingFields[key]}
								input={false}
							/>
						)}
						{category.nested &&
							category.nested.map((nestedCategory, idx) => {
								const nestedKey = `${key}.${nestedCategory.key}`;
								let value = nestedCategory.defaultValue;
								if (
									fields.categories &&
									fields.categories[key] &&
									fields.categories[key][nestedCategory.key]
								) {
									value = fields.categories[key][nestedCategory.key];
								}
								if (nestedCategory.type === 'select') {
									return (
										<div key={nestedKey}>
											<label>{nestedCategory.display}</label>
											<Select
												{...nestedCategory}
												isMulti={nestedCategory.multiple}
												name={nestedKey}
												id={nestedKey}
												key={idx}
												onChange={(v) => handleCategoryChange(nestedKey, v)}
												options={multiSelectOptionsParser(
													nestedCategory.options
												)}
												value={formatValues(value, nestedCategory.options)}
												disabled={loadingFields[nestedKey]}
												input={false}
											/>
										</div>
									);
								} else return null;
							})}
					</div>
				);
			})}
			<div className='select'>
				<label>Keywords</label>
				<CreateableSelect
					isMulti={true}
					name={'keywords'}
					id={'keywords'}
					value={formatKeywordValues(fields['keywords'])}
					onChange={(v) => handleCreatableChange('keywords', v)}
					options={keywordSelectOptionsParser(fields['keywords'])}
				/>
			</div>
			<div className='select'>
				<label>Display Manual Format</label>
				<select
					id='displayManualFormat'
					type='select'
					value={fields['displayManualFormat']}
					onChange={(e) => handleFormatChange(e.target.value)}
				>
					<>
						<option value=''></option>
						{dataModel.formats.map((obj, idx) => {
							return (
								<option key={idx} value={obj.display}>
									{obj.display}
								</option>
							);
						})}
					</>
				</select>
			</div>
			<div className='asset-file'>
				{sourceAsset.metadata && (
					<button className='delete' onClick={handleDeleteAssetFile}>
						Delete File
					</button>
				)}
				<label htmlFor='asset-upload'>Upload a New Asset</label>
				<input
					type='file'
					id='asset-upload'
					onChange={handleAssetUploadChange}
				/>
				<p>Current: {sourceAsset.metadata && sourceAsset.metadata.fileName}</p>
				<p>
					{sourceAsset.metadata &&
						sourceAsset.metadata.processing &&
						'Processing Asset'}
				</p>
				<p style={{ fontSize: '8px' }}>
					<pre>{JSON.stringify(sourceAsset.metadata, null, 2)}</pre>
				</p>
				{sourceAsset.metadata && sourceAsset.metadata.path && (
					<CloudStorageDownloadButton path={sourceAsset.metadata.path} />
				)}
			</div>
			<div className='tn-upload'>
				<label htmlFor='tn-upload'>Upload Thumbnail Image</label>
				<input
					type='file'
					id='tn-upload'
					accept='image/*'
					onChange={handleThumbnailUploadChange}
				/>
				<div id='tn-container'>
					<CloudStorageImg path={sourceAsset.thumbnailPath} />
				</div>
			</div>
			<div className='preview-gallery'>
				<label htmlFor='gallery-upload'>Upload Gallery Images</label>
				<input
					type='file'
					id='gallery-upload'
					accept='image/*'
					multiple
					onChange={handleGalleryUploadChange}
				/>
				<div>
					{sourceAsset.previewGalleryPaths &&
						sourceAsset.previewGalleryPaths.map((path, idx) => {
							return (
								<div className='item' key={idx}>
									<CloudStorageImg path={path} />
									<div className='controls'>
										<button
											onClick={() => handleGalleryImageShift(path, false)}
										>
											{'<<'}
										</button>
										<button
											className='delete-gallery'
											onClick={() => handleDeleteGalleryImage(path)}
										>
											X
										</button>
										<button onClick={() => handleGalleryImageShift(path, true)}>
											{'>>'}
										</button>
									</div>
								</div>
							);
						})}
				</div>
			</div>
			<div>
				<button className='delete-it-all' onClick={handleDeleteEntireAsset}>
					Delete Entire Asset
				</button>
			</div>
		</Wrapper>
	);
};

export default withRouter(EditAssetPage);
