import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { loginWithToken, logOut } from '../config/store/actions/auth';
import styled from 'styled-components';
import queryString from 'query-string';
import LoadingIcon from '../components/_atoms/LoadingIcon';
import { CURRENT_DC_LINKS } from '../config/environments';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	h1 {
		width: 500px;
		max-width: 80%;
	}
`;

const LoginSSOCallbackHandlerPage = (props) => {
	const { uid, logOut, loginWithToken } = props;

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const params = queryString.parse(location.search);
		const process = async () => {
			if (params['token']) {
				if (uid) await logOut();
				await loginWithToken(params['token']);
				history.replace('/');
			} else if (params['error']) {
				if (uid) await logOut();
				window.location.href = CURRENT_DC_LINKS.base;
				//history.replace('/');
			} else {
				history.replace('/');
			}
		};
		process();
	}, [location.search, uid, logOut, loginWithToken, history]);

	return (
		<Wrapper>
			<LoadingIcon />
		</Wrapper>
	);
};

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
});

const mapDispatchToProps = (dispatch) => ({
	loginWithToken: (token) => dispatch(loginWithToken(token)),
	logOut: () => dispatch(logOut()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginSSOCallbackHandlerPage);
