import {
	BaseExtendedFirebaseInstance,
	ExtendedFirestoreInstance,
} from 'react-redux-firebase';
import { ACTION_TYPES } from '../reducers/assetDocsReducer';
import algoliaSearch, { SearchClient } from 'algoliasearch';
import { isEqual, debounce, cloneDeep } from 'lodash';
import genUsageDesc from '../../../utils/usageDescGenerator';
import TagManager from 'react-gtm-module';

const PAGE_SIZE = 6;

const FILTER_SORT_DEFAULTS = {
	filters: {
		fieldEquality: {
			status: ['live'],
		},
	},
	sort: {
		field: 'createdAt',
		direction: 'desc',
	},
	query: [],
};

const filterSortToFirebaseQuery = (
	filterSortObj,
	db: ExtendedFirestoreInstance,
	startAfterDoc
) => {
	let query = db
		.collection('assets')
		.orderBy(filterSortObj.sort.field, filterSortObj.sort.direction);
	const fieldEquality = filterSortObj.filters.fieldEquality;
	const fieldEqualityFields = Object.keys(fieldEquality);
	for (let x = 0; x < fieldEqualityFields.length; x++) {
		const key = fieldEqualityFields[x];
		const value = fieldEquality[key];
		if (Array.isArray(value)) {
			if (value.length > 0) {
				if (key !== 'status') {
					query = query.where(key, 'array-contains-any', value);
				} else {
					query = query.where(key, 'in', value);
				}
			}
		} else {
			if (value) {
				query = query.where(key, '==', value);
			}
		}
	}
	query = query.limit(PAGE_SIZE);
	if (startAfterDoc) query = query.startAfter(startAfterDoc);
	return query;
};

const clearResultsPages = (dispatch) => {
	dispatch({
		type: ACTION_TYPES.CLEAR_RESULTS,
	});
};

const _refreshSearchClient = async (dispatch, getState, getFirebase) => {
	if (getState().assetDocs.searchClient.expiresAt > Date.now()) return;
	const searchKey = await getFirebase()
		.functions()
		.httpsCallable('algolia-getAlgoliaSearchKey')();
	if (searchKey) {
		const searchClient = algoliaSearch(
			searchKey.data.app_id,
			searchKey.data.search_key
		);
		dispatch({
			type: ACTION_TYPES.SET_SEARCH_CLIENT,
			payload: { client: searchClient, expiresAt: Date.now() + 50 * 60000 },
		});
	}
};

export const refreshSearchClient = () => {
	return async (dispatch, getState, getFirebase) =>
		await _refreshSearchClient(dispatch, getState, getFirebase);
};

export const sortRuleToAlgoliaIndex = (sortRule) => {
	let index = 'ASSETS';
	if (sortRule.field === 'createdAt' && sortRule.direction === 'desc')
		index = 'ASSETS_NEWEST_FIRST';
	else if (sortRule.field === 'createdAt' && sortRule.direction === 'asc')
		index = 'ASSETS_OLDEST_FIRST';
	else if (sortRule.field === 'name' && sortRule.direction === 'asc')
		index = 'ASSETS_NAME_AZ';
	else if (sortRule.field === 'name' && sortRule.direction === 'desc')
		index = 'ASSETS_NAME_ZA';
	return index;
};

export const filterRulesToAlgoliaFilters = (filterRules) => {
	let filters: any = [];
	Object.keys(filterRules).forEach((key) => {
		let toPush: any;
		if (Array.isArray(filterRules[key])) {
			toPush = [];
			filterRules[key].forEach((value) => {
				toPush.push(`${key}:${value}`);
			});
			toPush = toPush.join(' OR ');
		} else {
			toPush = `${key}:${filterRules[key]}`;
		}

		toPush = `(${toPush})`;
		filters.push(toPush);
	});

	return filters.join(' AND ');
};

const _getNextPageAlgolia = async (
	dispatch,
	getState,
	getFirebase: () => BaseExtendedFirebaseInstance
) => {
	await _refreshSearchClient(dispatch, getState, getFirebase);
	const searchClient: SearchClient = getState().assetDocs.searchClient.client;
	const filterSort = getState().assetDocs.filterSort;
	const index = searchClient.initIndex(sortRuleToAlgoliaIndex(filterSort.sort));
	const filterRulesString = filterRulesToAlgoliaFilters(
		filterSort.filters.fieldEquality
	);
	let query = filterSort.query;
	if (Array.isArray(query)) query = query.join(' ');
	const res = await index.search(query, {
		filters: filterRulesString,
		page: getState().assetDocs.results.length,
		hitsPerPage: PAGE_SIZE,
	});

	dispatch({
		type: ACTION_TYPES.SET_ALL_RESULTS_FOUND,
		payload: res.nbPages <= getState().assetDocs.results.length,
	});

	if (res.hits && res.hits.length > 0) return res.hits;
	else return [];
};

const _getNextPageFirebase = async (
	dispatch,
	getState,
	getFirebase: () => BaseExtendedFirebaseInstance
) => {
	const state = getState();
	const filterSortObj = state.assetDocs.filterSort;
	let lastPage;
	if (state.assetDocs.results.length > 0) {
		lastPage = state.assetDocs.results[state.assetDocs.results.length - 1];
	}
	let lastAssetDocRef;
	if (lastPage) {
		lastAssetDocRef = lastPage[lastPage.length - 1]._transformed._doc;
	}
	const query = filterSortToFirebaseQuery(
		filterSortObj,
		getFirebase().firestore(),
		lastAssetDocRef
	);
	const res = await query.get();

	if (res && res.docs && res.docs.length > 0) {
		const newPageAssets: any[] = [];
		res.forEach((doc) => {
			const data = doc.data();
			data._transformed = {
				_doc: doc,
			};
			newPageAssets.push(data);
		});
		return newPageAssets;
	}
	return [];
};

// eslint-disable-next-line no-undef
const _flattenCategories = (obj: object): string[] => {
	let flattened: string[] = [];
	if (!obj) return flattened;
	Object.keys(obj).forEach((key) => {
		const value = obj[key];

		if (Array.isArray(value)) {
			flattened.push(...value.map((v) => `${key}.${v}`));
		} else if (typeof value === 'object')
			flattened.push(..._flattenCategories(value).map((x) => `${key}.${x}`));
		else if (typeof obj[key] === 'string') flattened.push(`${key}.${value}`);
	});
	return flattened;
};

export const applyUsageDesc = (asset) => {
	if ((asset.categories && !asset.shortDescription) || !asset.longDescription) {
		const { short, long } = genUsageDesc(_flattenCategories(asset.categories));
		asset.shortDescription = asset.shortDescription || short;
		asset.longDescription = asset.longDescription || long;
	}
	return asset;
};

const _addPage = async (
	dispatch: any,
	getState: any,
	getFirebase: () => BaseExtendedFirebaseInstance
) => {
	if (getState().assetDocs.allResultsFound) return;
	dispatch({
		type: ACTION_TYPES.SET_PAGES_REQUESTED_COUNT,
		payload: getState().assetDocs.resultsPagesRequested + 1,
	});
	if (
		getState().assetDocs.loadingResults ||
		getState().assetDocs.resultsPagesRequested > 1
	)
		return;
	dispatch({
		type: ACTION_TYPES.SET_LOADING_RESULTS,
		payload: true,
	});
	while (getState().assetDocs.resultsPagesRequested > 0) {
		dispatch({
			type: ACTION_TYPES.SET_PAGES_REQUESTED_COUNT,
			payload: getState().assetDocs.resultsPagesRequested - 1,
		});
		let newPage;
		let pageCountOnRequest = getState().assetDocs.results.length;
		let filterSortOnRequest = cloneDeep(getState().assetDocs.filterSort);
		if (getState().assetDocs.resultsSource === '_algolia') {
			newPage = await _getNextPageAlgolia(dispatch, getState, getFirebase);
		} else {
			newPage = await _getNextPageFirebase(dispatch, getState, getFirebase);
		}
		if (
			pageCountOnRequest !== getState().assetDocs.results.length ||
			!isEqual(filterSortOnRequest, getState().assetDocs.filterSort)
		) {
			console.error('Change occured during request. Skipping addition.');
			continue;
		}
		if (newPage && newPage.length > 0) {
			newPage.forEach((asset) => applyUsageDesc(asset));
			dispatch({
				type: ACTION_TYPES.ADD_RESULTS_PAGE,
				payload: newPage,
			});
		}
		if (newPage.length < PAGE_SIZE) {
			dispatch({
				type: ACTION_TYPES.SET_ALL_RESULTS_FOUND,
				payload: true,
			});
		}
	}
	dispatch({
		type: ACTION_TYPES.SET_LOADING_RESULTS,
		payload: false,
	});
};

const _getNewResults = async (
	dispatch: any,
	getState: any,
	getFirebase: () => BaseExtendedFirebaseInstance
) => {
	clearResultsPages(dispatch);
	if (
		getState().assetDocs.filterSort.query.length > 0 ||
		Object.keys(getState().assetDocs.filterSort.filters.fieldEquality).length >
			1
	) {
		dispatch({
			type: ACTION_TYPES.SET_RESULTS_SOURCE,
			payload: '_algolia',
		});
	} else {
		dispatch({
			type: ACTION_TYPES.SET_RESULTS_SOURCE,
			payload: '_firebase',
		});
	}
	return await _addPage(dispatch, getState, getFirebase);
};

const NEW_RESULTS_DEBOUNCE = 200;
const _getNewResultsDebounced = debounce(_getNewResults, NEW_RESULTS_DEBOUNCE);

export const getPage = () => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		return await _addPage(dispatch, getState, getFirebase);
	};
};

const applyCategoriesPrefix = (filtersObj) => {
	const returnObj = {};
	Object.keys(filtersObj).forEach((key) => {
		returnObj[`categories.${key}`] = filtersObj[key];
	});
	return returnObj;
};

export const updateStatus = (status: string[] = ['live']) => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const currentObj = getState().assetDocs.filterSort;
		const newObj = {
			...FILTER_SORT_DEFAULTS,
			...currentObj,
			filters: {
				...FILTER_SORT_DEFAULTS.filters,
				...currentObj.filters,
				fieldEquality: {
					...FILTER_SORT_DEFAULTS.filters.fieldEquality,
					...currentObj.filters.fieldEquality,
					status,
				},
			},
		};
		if (isEqual(currentObj, newObj)) return;
		dispatch({
			type: ACTION_TYPES.SET_FILTER_SORT,
			payload: newObj,
		});
		await _getNewResultsDebounced(dispatch, getState, getFirebase);
	};
};

const _removeCategoryProperties = (fieldEqualityObj) => {
	const newObj = { ...fieldEqualityObj };
	Object.keys(newObj)
		.filter((key) => key.startsWith('categories.'))
		.forEach((key) => {
			delete newObj[key];
		});
	return newObj;
};

export const updateFilters = (categoryFilters) => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const currentObj = getState().assetDocs.filterSort;
		if (!categoryFilters) categoryFilters = {};
		const newObj = {
			...FILTER_SORT_DEFAULTS.filters,
			...currentObj.filters,
			noCatPrefix: categoryFilters,
			fieldEquality: {
				...FILTER_SORT_DEFAULTS.filters.fieldEquality,
				..._removeCategoryProperties(currentObj.filters.fieldEquality),
				...applyCategoriesPrefix(categoryFilters),
			},
		};
		if (isEqual(currentObj.filters, newObj)) return;

		let old_filters = currentObj.filters.noCatPrefix;
		let new_filters = newObj.noCatPrefix;
		let filter = { category: '', name: '' };

		for (const cat in new_filters) {
			if (!old_filters || !old_filters[cat]) {
				filter.category = cat;
				filter.name = new_filters[cat][0];
				break;
			}
			if (new_filters[cat].length > old_filters[cat].length) {
				filter.category = cat;
				filter.name = new_filters[cat][new_filters[cat].length - 1];
				break;
			}
		}

		if (filter.name && filter.category) {
			TagManager.dataLayer({
				dataLayer: {
					event: 'filter_applied',
					filter_added: filter.name,
					filter_category: filter.category,
				},
				dataLayerName: 'dataLayer',
			});
		}

		dispatch({
			type: ACTION_TYPES.SET_FILTER_SORT,
			payload: {
				...FILTER_SORT_DEFAULTS,
				...currentObj,
				filters: newObj,
			},
		});
		await _getNewResultsDebounced(dispatch, getState, getFirebase);
	};
};

export const updateSort = (sortObj) => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		if (!sortObj) sortObj = FILTER_SORT_DEFAULTS.sort;
		const currentObj = getState().assetDocs.filterSort;
		const newObj = {
			...FILTER_SORT_DEFAULTS,
			...currentObj,
			sort: sortObj,
		};
		if (isEqual(currentObj, newObj)) return;

		dispatch({
			type: ACTION_TYPES.SET_FILTER_SORT,
			payload: newObj,
		});
		await _getNewResultsDebounced(dispatch, getState, getFirebase);
	};
};

export const updateQuery = (queryArr = []) => {
	return async (dispatch, getState, getFirebase) => {
		if (queryArr.length === 1 && queryArr[0] === '') queryArr = [];
		const currentObj = getState().assetDocs.filterSort;
		const newObj = {
			...FILTER_SORT_DEFAULTS,
			...currentObj,
			query: queryArr,
		};
		if (isEqual(currentObj, newObj)) return;

		if (
			queryArr.length > 1 &&
			!queryArr[queryArr.length - 1] &&
			(currentObj.query.length < newObj.query.length || !currentObj.query[0])
		) {
			TagManager.dataLayer({
				dataLayer: {
					event: 'search',
					search_term: queryArr[queryArr.length - 2],
				},
				dataLayerName: 'dataLayer',
			});
		}

		dispatch({
			type: ACTION_TYPES.SET_FILTER_SORT,
			payload: newObj,
		});
		await _getNewResultsDebounced(dispatch, getState, getFirebase);
	};
};

export const resetFilterSort = () => {
	return async (dispatch, getState, getFirebase) => {
		const currentObj = getState().assetDocs.filterSort;
		const newObj = {
			...FILTER_SORT_DEFAULTS,
			...currentObj,
			filters: {
				...FILTER_SORT_DEFAULTS.filters,
				...currentObj.filters,
				noCatPrefix: {},
				fieldEquality: {
					...FILTER_SORT_DEFAULTS.filters.fieldEquality,
					..._removeCategoryProperties(currentObj.filters.fieldEquality),
					...applyCategoriesPrefix({}),
				},
			},
			query: [],
			sort: {
				direction: 'desc',
				field: 'createdAt',
				manuallySet: false,
				label: 'Newest',
				value: 'newest',
			},
		};

		if (isEqual(currentObj, newObj)) return;

		dispatch({
			type: ACTION_TYPES.SET_FILTER_SORT,
			payload: newObj,
		});

		await _getNewResultsDebounced(dispatch, getState, getFirebase);
	};
};
