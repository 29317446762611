import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressiveImg from '../../_utilities/ProgressiveImg';

const blueGradient = 'linear-gradient(23deg, #00559D, #00559D)';
const greyGradient = 'linear-gradient(-104deg, #323E48 , #5B6770 );';

const ContainerBase = styled.div`
	position: relative;
	width: 100%;

	/* container that holds the text */
	> div {
		position: absolute;
		box-sizing: border-box;
		color: ${(props) => (!props.dark ? '#ffffff' : '#000000')};
		z-index: 5;
	}

	h1 {
		font-family: 'Saira Extra Condensed', sans-serif;
		font-weight: 700;
		margin: 0;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	p {
		font-family: 'Saira', sans-serif;

		sub {
			bottom: 0;
		}
	}

	figure {
		z-index: 1;
		margin: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	img {
		vertical-align: bottom;
		width: 100%;
		height: auto;
	}
`;

const ContainerLg = styled(ContainerBase)`
	height: 460px;

	> div {
		color: #000000;
		width: 478px;
		right: 30px;
		top: 155px;
		text-align: left;
	}

	h1 {
		font-size: 55px;
		line-height: calc(55 / 54);
		text-align: right;
		font-weight: bold;
		span {
			display: block;
		}
	}

	p {
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 0.05em;
		width: 93%;
	}

	footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 28px;
		> a {
			margin: 0 26px;
		}
	}
`;

const ContainerMd = styled(ContainerBase)`
	height: 291px;
	background: ${(props) =>
		props.bg === 'default' ? blueGradient : greyGradient};
	> div {
		width: 100%;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
	}
	h1 {
		font-size: 48px;
		line-height: 76px;
	}
	p {
		box-sizing: border-box;
		font-size: 24px;
		line-height: 38px;
		letter-spacing: normal;
		width: 62%;
		margin: 0 auto;
	}
`;

const ContainerSm = styled(ContainerBase)`
	height: 100px;
	background: #0067b1;

	> div {
		width: 100%;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
	}
	h1 {
		font-size: 48px;
		line-height: 76px;
		letter-spacing: 0.05em;
	}
`;

function Hero(props) {
	let Container;
	switch (props.size) {
		case 'lg':
			Container = ContainerLg;
			break;
		case 'sm':
			Container = ContainerSm;
			break;
		default:
			Container = ContainerMd;
			break;
	}
	return (
		<Container
			className={props.className}
			dark={props.dark}
			bg={props.bg}
			size={props.size}
		>
			{props.bg ? (
				<ProgressiveImg
					cover={true}
					src={`${process.env.PUBLIC_URL}/img/${props.bg}`}
					previewSrc={`${process.env.PUBLIC_URL}/img/${props.bgPreview}`}
					alt=''
				/>
			) : (
				<></>
			)}
			<div>{props.children}</div>
		</Container>
	);
}

Hero.propTypes = {
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	bg: PropTypes.string.isRequired,
	dark: PropTypes.bool,
};

Hero.defaultProps = {
	size: 'md',
	bg: 'default',
	dark: false,
};

export default Hero;
