import React, { useCallback } from 'react';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import '@inovua/reactdatagrid-community/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import firebase from 'firebase';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import ModalComp from '../components/_molecules/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

function PartGroups() {
	const [newDoc, setNewDoc] = useState('');
	const [tableDataSource, setTableDataSource] = useState();
	const [selected, setSelected] = useState({});
	const [disabled, setDisabled] = useState(true);
	const [modalShow, setModalShow] = useState(false);
	const [modalData, setModalData] = useState();
	const [cellStyle] = useState({});
	const [lastClickedCell, setLastClickedCell] = useState(null);

	// RETRIEVES FIREBASE DATA
	//changes JSON data into an array of objects to then be able to apply array methods
	useFirestoreConnect([
		{
			collection: 'partGroups',
			orderBy: ['groupName', 'asc'],
			storeAs: 'partGroups',
		},
	]);

	const partGroups = useSelector(
		(state) => state.firestore.ordered[`partGroups`]
	);

	useEffect(() => {
		setTableDataSource(partGroups);
	}, [partGroups]);

	const gridStyle = { minHeight: 550 };

	const columns = [
		{ name: 'id', header: 'ID', editable: false, defaultVisible: false },
		{ name: 'groupName', header: 'Group Name', defaultFlex: 1 },
		{
			name: 'partIds',
			header: 'Part Ids',
			defaultFlex: 1,
			render: ({ value }) =>
				value &&
				value.map((c, i, row) => {
					if (i + 1 === row.length) {
						return c.partId;
					} else {
						return c.partId + ', ';
					}
				}),
		},
		{ name: 'user', header: 'Created By', defaultFlex: 1, editable: false },
	];

	const createPartGroup = () => {
		const db = firebase.firestore();
		let UserDisplay = '';
		if (firebase.auth().currentUser.displayName) {
			UserDisplay += firebase.auth().currentUser.displayName + ' ';
		}
		if (firebase.auth().currentUser.email) {
			UserDisplay += firebase.auth().currentUser.email;
		}

		db.collection('partGroups')
			.doc()
			.set({
				groupName: newDoc,
				user: UserDisplay,
				createdAt: new Date(),
			})
			.then(() => {})
			.catch(() => {});
	};

	const onEditComplete = useCallback(
		({ value, rowId }) => {
			const updateState = tableDataSource.map((obj) => {
				if (obj.id === rowId) {
					// RETURN updated obj groupName value
					return { ...obj, groupName: value };
				}
				// Else RETURN original obj
				return obj;
			});
			setTableDataSource(updateState);

			const firebaseUpdateObj = updateState.find((obj) => obj.id === rowId);
			const db = firebase.firestore();
			db.collection('partGroups')
				.doc(rowId)
				.update(firebaseUpdateObj)
				.then(() => {})
				.catch(() => {});
		},
		[tableDataSource]
	);

	// SELECT A ROW(s)
	// Store the id of the selected row in order to delete in firebase (must have access to doc id in order to update the database)
	const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
		setModalData(data);
		setSelected(selectedMap);
	}, []);

	// DELETE DATA
	const handleDocDelete = () => {
		// Checking to see if an Object is Empty
		if (JSON.stringify(selected) === '{}') {
			return;
		}
		if (selected !== undefined) {
			const confirmed = window.confirm('Are you sure you want to delete?');
			if (confirmed === true) {
				for (var single in selected) {
					const db = firebase.firestore();
					db.collection('partGroups')
						.doc(single)
						.delete()
						.then(() => {})
						.catch(() => {});
					setDisabled(disabled);
					setSelected();
				}
			}
		}
	};

	const onCellClick = useCallback((event, cellProps) => {
		const { columnIndex, rowIndex } = cellProps;
		setModalShow(cellProps);
		setLastClickedCell({ columnIndex, rowIndex });
	}, []);

	let cellText = null;

	if (lastClickedCell) {
		cellText = (
			<span>
				Row {lastClickedCell.rowIndex}, column: {lastClickedCell.columnIndex}
			</span>
		);
	}

	return (
		<React.Fragment>
			<Hero>
				<h1>Part Groups</h1>
			</Hero>
			<header style={{ marginTop: 20 }}>
				<h1>List view:</h1>
			</header>

			<input
				type='text'
				placeholder='Create a new group...'
				onChange={(event) => {
					setNewDoc(event.target.value);
				}}
				style={{
					marginTop: 20,
					paddingTop: 10,
					paddingBottom: 10,
					paddingLeft: 10,
					paddingRight: 10,
					borderWidth: 1,
					width: 250,
					borderStyle: 'solid',
					borderColor: '#A8A9AC',
				}}
			></input>
			<Button
				variant='primary'
				type='button'
				onClick={createPartGroup}
				style={{
					borderRadius: 0,
					paddingTop: 10,
					paddingBottom: 10,
					paddingLeft: 20,
					paddingRight: 20,
					fontSize: 16,
					width: 150,
					backgroundColor: '#005C9D',
					border: 0,
					marginLeft: 20,
				}}
			>
				{' '}
				+ Create
			</Button>

			<Button
				variant='danger'
				type='button'
				disabled={selected ? !disabled : disabled}
				onClick={() => handleDocDelete()}
				style={{
					borderRadius: 0,
					paddingTop: 10,
					paddingBottom: 10,
					paddingLeft: 20,
					paddingRight: 20,
					fontSize: 16,
					width: 150,
					border: 0,
				}}
			>
				x Delete
			</Button>

			<ModalComp
				style={{ marginTop: 20 }}
				show={modalShow}
				onHide={() => setModalShow(false)}
				data={modalData}
			/>

			<div style={{ marginBottom: 20 }}>
				<p>Click on a grid cell for a pop up with parts list!</p>
				<p>Last clicked cell: {cellText}.</p>
			</div>

			<div style={{ marginBottom: 50 }}>
				{tableDataSource && (
					<ReactDataGrid
						idProperty='id'
						columns={columns}
						dataSource={tableDataSource}
						editable={true}
						style={gridStyle}
						onEditComplete={onEditComplete}
						onSelectionChange={onSelectionChange}
						defaultSelected={false}
						multiSelect
						pagination
						defaultLimit={10}
						theme='default-light'
						cellStyle={cellStyle}
						onCellClick={onCellClick}
					/>
				)}
			</div>
			<Footer />
		</React.Fragment>
	);
}

export default PartGroups;
