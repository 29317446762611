/* eslint-disable no-undef */
import { ACTION_TYPES } from '../reducers/adminReducer';
import { BaseExtendedFirebaseInstance } from 'react-redux-firebase';
import { v4 as uuidv4 } from 'uuid';
import compress from '../../../utils/imageCompression';

export interface OperationResponse {
	success: boolean;
	docId?: string;
	err?: any;
}

export const updateField = (
	collection: string,
	id: string,
	field: string,
	value: any
) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	): Promise<OperationResponse> => {
		const db = getFirebase().firestore();
		const doc = db.collection(collection).doc(id);
		try {
			await doc.update({
				[field]: value,
				lastUpdateAt: Date.now(),
			});
			return { success: true, docId: doc.id };
		} catch (err) {
			console.error(err);
			return { success: false, err };
		}
	};
};

const excludeKeys = ['brand', 'commodity'];
const _categoriesToDisplay = (categories, dataModelCategories) => {
	let displayArr: string[] = [];
	dataModelCategories.forEach(
		(obj: {
			key: string;
			options: { value: string; display: string }[];
			nested: any;
			display: string;
		}) => {
			const { key, options, nested, display } = obj;
			if (!excludeKeys.includes(key)) {
				if (categories[key]) {
					displayArr.push(display);
					if (!nested) {
						if (options && options.length > 0) {
							displayArr.push(
								...categories[key].map((el: string) => {
									const found = options.find((o) => o.value === el);
									if (found) return found.display;
									else return '';
								})
							);
						}
					} else {
						displayArr.push(..._categoriesToDisplay(categories[key], nested));
					}
				}
			}
		}
	);
	return displayArr;
};

export const updateCategories = (id, newCategoriesObj, dataModel) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		const doc = db.collection('assets').doc(id);
		try {
			await doc.update({
				categories: newCategoriesObj,
				displayCategories: _categoriesToDisplay(
					newCategoriesObj,
					dataModel.categories
				),
				lastUpdateAt: Date.now(),
			});
			return { success: true, docId: doc.id };
		} catch (err) {
			console.error(err);
			return { success: false, err };
		}
	};
};

const _formatToValue = (format, dataModel) => {
	if (!format) return '';
	return dataModel.formats.find((f) => f.display === format).value;
};

export const updateFormat = (id, newFormatDisplay, dataModel) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		const doc = db.collection('assets').doc(id);
		try {
			await doc.update({
				displayManualFormat: newFormatDisplay,
				manualFormat: _formatToValue(newFormatDisplay, dataModel),
				lastUpdateAt: Date.now(),
			});
			return { success: true, docId: doc.id };
		} catch (err) {
			console.error(err);
			return { success: false, err };
		}
	};
};

export const newEntry = (collection) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	): Promise<OperationResponse> => {
		const db = getFirebase().firestore();
		try {
			const ref = db.collection(collection).doc();
			const data = {
				id: ref.id,
				status: 'draft',
				createdAt: Date.now(),
			};
			if (collection === 'monthlyPriority') {
				data['month'] = 0;
				data['priority'] = 0;
			}
			await ref.set(data);
			return {
				success: true,
				docId: ref.id,
			};
		} catch (err) {
			return {
				success: false,
				err,
			};
		}
	};
};

function _array_move(arr, old_index, new_index) {
	while (old_index < 0) {
		old_index += arr.length;
	}
	while (new_index < 0) {
		new_index += arr.length;
	}
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export const shiftPreviewGalleryImageOrder = (
	collection: string,
	id: string,
	path: string,
	forwards: boolean
) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		return await db.runTransaction(async (t) => {
			const docRef = db.doc(`${collection}/${id}`);
			const assetDoc = await t.get(docRef);
			const data = assetDoc.data();
			if (data) {
				const paths: string[] = data.previewGalleryPaths;
				const currentIdx = paths.indexOf(path);
				let targetIdx = currentIdx;
				if (forwards) {
					if (currentIdx < paths.length - 1) {
						targetIdx += 1;
					} else {
						targetIdx = 0;
					}
				} else {
					if (currentIdx > 0) {
						targetIdx -= 1;
					} else {
						targetIdx = paths.length - 1;
					}
				}
				_array_move(paths, currentIdx, targetIdx);
				t.update(docRef, {
					previewGalleryPaths: paths,
				});
			}
		});
	};
};

export const deleteDoc = (collection, id) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const db = getFirebase().firestore();
		return await db.doc(`${collection}/${id}`).delete();
	};
};

export const uploadFile = (collection, id, file: File) => {
	return async (
		dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		const storageRef = storage.ref();

		const assetRef = storageRef.child(`${collection}/${id}/file`);

		const customMetadata = {
			forGallery: 'false',
			fileName: file.name,
			forPreview: 'false',
		};

		if (collection === 'assets') customMetadata['assetId'] = id;
		else if (collection === 'monthlyPriority')
			customMetadata['monthlyPriorityId'] = id;

		const uploadTask = assetRef.put(file, {
			contentDisposition: `attachment; filename=${file.name}`,
			customMetadata,
		});

		uploadTask.on('state_changed', (snapshot) => {
			dispatch({
				type: ACTION_TYPES.UPDATE_UPLOAD_PROGRESS,
				payload: {
					id: `${collection}/${id}/file`,
					progress: snapshot.bytesTransferred / snapshot.totalBytes,
				},
			});
		});

		if (file.type === 'application/pdf') {
			const previewAssetRef = storageRef.child(
				`${collection}/${id}/file-forPreview`
			);
			previewAssetRef.put(file, {
				contentDisposition: `inline; filename=${file.name}`,
				customMetadata: {
					forGallery: 'false',
					fileName: file.name,
					forPreview: 'true',
				},
			});
		}
	};
};

export const deleteFile = (path) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		const storageRef = storage.ref();

		await storageRef.child(path).delete();
	};
};

const _fileToBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const r = new FileReader();
		r.readAsDataURL(file);
		r.onloadend = () => {
			const base64 = r.result;
			if (typeof base64 === 'string') resolve(base64);
			else reject('No Base64 string found from file');
		};
		r.onerror = (err) => reject(err);
	});
};

const _base64ToFile = (base64: string, name: string): File => {
	let arr: string[] = base64.split(',');
	let str = arr[0].match(/:(.*?);/) || '';
	let mime = str[1];
	let bstr = atob(arr[1]);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], name, { type: mime });
};

const GALLERY_IMAGE_MAX_SIZE: number = 720;
const IMAGE_TINY_MAX_SIZE: number = 24;

export const uploadGalleryImages = (
	collection: string,
	id: string,
	files: File[],
	thumbnail: boolean = false
) => {
	return async (
		dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		const storageRef = storage.ref();

		const uploadTasks: any[] = [];

		if (thumbnail && files.length > 1)
			throw new Error('Thumbnail call can only include a single image');

		files.forEach((file: File) => {
			const fileName = `${uuidv4()}.png`;
			const tinyFileName = `tiny--${fileName}`;
			_fileToBase64(file).then((base64) => {
				//upload full-size version
				compress(base64, GALLERY_IMAGE_MAX_SIZE, 1).then((fullSize) => {
					const file = _base64ToFile(fullSize, fileName);
					const assetRef = storageRef.child(
						`${collection}/${id}/gallery/${fileName}`
					);

					const customMetadata = {
						forGallery: 'true',
						fileName: fileName,
						tiny: 'false',
						thumbnail: thumbnail ? 'true' : 'false',
					};

					if (collection === 'assets') customMetadata['assetId'] = id;
					else if (collection === 'monthlyPriority')
						customMetadata['monthlyPriorityId'] = id;

					const uploadTask = assetRef.put(file, {
						customMetadata: customMetadata,
					});

					uploadTask.on('state_changed', (snapshot) => {
						dispatch({
							type: ACTION_TYPES.UPDATE_UPLOAD_PROGRESS,
							payload: {
								id: `${collection}/${id}/gallery/${fileName}`,
								progress: snapshot.bytesTransferred / snapshot.totalBytes,
							},
						});
					});

					uploadTasks.push(uploadTask);
				});

				//upload compressed version
				compress(base64, IMAGE_TINY_MAX_SIZE, 0.2).then((tiny) => {
					const file = _base64ToFile(tiny, tinyFileName);
					const assetRef = storageRef.child(
						`${collection}/${id}/gallery/${tinyFileName}`
					);

					const customMetadata = {
						forGallery: 'true',
						fileName: tinyFileName,
						tiny: 'true',
						thumbnail: thumbnail ? 'true' : 'false',
					};

					if (collection === 'assets') customMetadata['assetId'] = id;
					else if (collection === 'monthlyPriority')
						customMetadata['monthlyPriorityId'] = id;

					const uploadTask = assetRef.put(file, {
						customMetadata: customMetadata,
					});

					uploadTasks.push(uploadTask);
				});
			});
		});
	};
};

export const deleteGalleryImage = (path) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const firebase = getFirebase();
		const storage = firebase.storage();
		const storageRef = storage.ref();

		await storageRef.child(path).delete();
	};
};
