import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AssetListing, { ListingTypes } from '../../_organisms/AssetListing';

const Wrap = styled.div`
	box-sizing: border-box;
	padding: 30px 30px 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	justify-items: center;
	row-gap: 50px;
	column-gap: 30px;
	margin-bottom: 100px;
`;

function ListingContainer(props) {
	return (
		<Wrap columns={props.columns}>
			{!props.loading &&
				props.data.map((v, idx) => (
					<AssetListing key={idx} data={v} type={props.listingType} />
				))}
			{props.loading &&
				Array.apply(null, Array(props.columns)).map((_el, idx) => (
					<AssetListing key={idx} loading={true} type={props.listingType} />
				))}
		</Wrap>
	);
}

ListingContainer.propTypes = {
	columns: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired,
	listingType: PropTypes.oneOf([ListingTypes.ACCESSORY, ListingTypes.EXTERNAL]),
};

ListingContainer.defaultProps = {
	columns: 4,
	listingType: ListingTypes.EXTERNAL,
};

export default ListingContainer;
