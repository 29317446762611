import { SearchClient } from 'algoliasearch';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
	filterRulesToAlgoliaFilters,
	refreshSearchClient,
	sortRuleToAlgoliaIndex,
} from '../config/store/actions/assetDocs';
import { merge } from 'lodash';

type Options = {
	sort: any;
	filters: any;
	pageSize: number;
};

const defaultOptions: Options = {
	pageSize: 8,
	filters: {
		fieldEquality: {
			status: ['live'],
		},
	},
	sort: {
		field: 'createdAt',
		direction: 'desc',
	},
};

const useSearch = (
	query: string[] | string,
	options: Partial<Options> = {}
) => {
	const opts: Options = merge(defaultOptions, options);
	console.log(opts);
	const { client } = useSelector(
		(state: any) =>
			(state.assetDocs?.searchClient as { client: SearchClient }) ?? {}
	);
	const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

	const handleRefreshSearchClient = useCallback(async () => {
		await dispatch(refreshSearchClient());
	}, [dispatch]);

	const [pages, setPages] = useState<object[]>();
	const [targetPage, setTargetPage] = useState<number>(0);
	const [totalHits, setTotalHits] = useState<number>();
	const [totalPages, setTotalPages] = useState<number>();
	const [loading, setLoading] = useState<boolean>();

	const loadPage = useCallback(
		async (idx: number) => {
			setLoading(true);
			try {
				const index = client?.initIndex(sortRuleToAlgoliaIndex(opts.sort));
				const filterString = filterRulesToAlgoliaFilters(
					opts.filters.fieldEquality
				);
				const q = Array.isArray(query) ? query.join(' ') : query;
				const res = await index.search(q, {
					filters: filterString,
					page: idx,
					hitsPerPage: opts.pageSize,
				});
				setPages((prev) => {
					if (idx !== (prev?.length ?? 0) || !res?.hits?.length) {
						return prev;
					}
					return [...(prev ?? []), res.hits];
				});
				setTotalPages(res.nbPages);
				setTotalHits(res.nbHits);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		},
		[client, opts, query]
	);

	const reset = useCallback(async () => {
		setTargetPage(0);
		setPages(undefined);
		setTotalHits(undefined);
		setTotalPages(undefined);
	}, []);

	const loadMore = useCallback(async () => {
		if ((totalPages ?? 1) <= (pages?.length ?? 0)) return;
		const nextTarget = targetPage + 1;
		setTargetPage(nextTarget);
		await loadPage(nextTarget);
	}, [loadPage, totalPages, pages, targetPage]);

	useEffect(() => {
		const run = async () => {
			if (!client) {
				await handleRefreshSearchClient();
			} else if (targetPage === 0) {
				reset();
				loadPage(0);
			}
		};
		run();
	}, [client, reset, handleRefreshSearchClient, targetPage, loadPage]);

	return {
		pages,
		loadMore,
		hasMore: (totalPages ?? 1) > (pages?.length ?? 0),
		loading,
		totalPages,
		totalHits,
	};
};

export default useSearch;
