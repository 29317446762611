import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { setShow } from '../../../config/store/actions/userTutorial';

import Button from '../../_atoms/Button';
import ExIcon from '../../_atoms/ExIcon';
import Heading, { HEADING_TYPES } from '../../_atoms/Heading';

const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
`;

const CloseButton = styled.button`
	border: none;
	background: none;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 46px;
	right: 46px;
	outline: none;
	cursor: pointer;
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
`;

const Content = styled.div`
	box-sizing: border-box;
	width: 639px;
	min-height: 600px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	.slideContainer {
		box-sizing: border-box;
		display: flex;
		position: relative;
		left: ${(props) => 639 * props.idx * -1}px;
		transition: left 250ms ease-out;
	}

	section {
		box-sizing: border-box;
		padding: 24px 40px 8px;
	}

	p,
	.learn {
		margin: 0.4em 0 0;
		font-family: 'Saira', sans-serif;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.05em;
	}

	.learn {
		display: block;
		color: #0067b1;
		margin-top: 0.3em;
	}
`;

const Controls = styled.footer`
	margin-top: auto;
	display: flex;
	margin-bottom: 30px;
	padding: 0 30px;

	.next,
	.finish {
		margin-left: auto;
	}
	.back {
		color: #aaa;
		background-color: #ffffff;
		justify-content: left;
	}
`;

const Indicators = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 27px;
	span {
		display: block;
		width: 16px;
		height: 16px;
		border: 2px solid #ffffff;
		border-radius: 50%;
		box-sizing: border-box;
		margin: 0 7px;
		cursor: pointer;

		&.active {
			background: #ffffff;
		}
	}
`;

const Slide = styled.div`
	width: 639px;
	figure {
		margin: 0;
		width: 100%;
		min-width: 639px;
		min-height: 300px;
	}
	img {
		vertical-align: bottom;
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
`;

const newUserCarouselData = [
	{
		img: 'digital-assets.jpg',
		title: 'Digital Assets',
		copy:
			'Here you can access web tiles to use on your own website, as well as content for social media, headers to add to email communications, TV monitor content for use on your showroom monitors and interactive PDFs to bring part offerings to life.',
		learn: 'Mopar-Accessory-Portal-How-To-Guide.pdf',
	},
	{
		img: 'printable-assets.jpg',
		title: 'Printable Assets',
		copy:
			'Access ready-to-print content here. Browse consumer sales guides, dealer sales guides, Dream Cards and other sales tools that you can download and print at your convenience. All elements come in prefilled and editable options so you can customize with your own pricing and information.',
		learn: 'Mopar-Accessory-Portal-How-To-Guide.pdf',
	},
	{
		img: 'catalogs-and-brochures.jpg',
		title: 'Catalogs and Brochures',
		copy:
			'Through this portal, you can access the latest catalogs and Accessory brochures. Stay up to date on the latest offerings and price points so you can be better prepared to help your customers.',
		learn: 'Mopar-Accessory-Portal-How-To-Guide.pdf',
	},
	{
		img: 'filtering-through-assets.jpg',
		title: 'Filtering Through Assets',
		copy:
			'The home page will display every available asset. Use the filters in the blue panel on the left side of the page to narrow your asset search. When using these filters, the assets displayed will automatically update to match your selections. You can filter assets by vehicle brand, commodity and type of asset, such as printables, digital, catalogs and Mopar® Custom Shop.',
		learn: 'Mopar-Accessory-Portal-How-To-Guide.pdf',
	},
	{
		img: 'searching-for-assets.jpg',
		title: 'Searching For Assets',
		copy:
			'If you are looking for a specific asset or don’t want to use the filters, you can enter keywords in the search bar at the top of the asset display section such as “Jeep,” “Dream Card” or “Tube Steps.”',
		learn: 'Mopar-Accessory-Portal-How-To-Guide.pdf',
	},
];

function NewUserCarousel() {
	const [frozenClosed, setFrozenClosed] = useState(false);
	const [slides] = useState(newUserCarouselData);
	const [currentSlide, setSlideIndex] = useState(0);

	useFirestoreConnect(() => [
		{
			collection: 'config',
			doc: 'userTutorial',
			storeAs: 'userTutorialData',
		},
	]);

	const profile = useSelector((state) => state.firebase.profile);
	const userTutorialData = useSelector(
		(state) => state.firestore.data['userTutorialData']
	);
	const show = useSelector((state) => state.userTutorial.show);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!frozenClosed && !show && !!profile) {
			if (!profile.seenTutorialVersion) dispatch(setShow(true));
			else if (userTutorialData && userTutorialData.version) {
				if (profile.seenTutorialVersion < userTutorialData.version) {
					dispatch(setShow(true));
				} else {
					setFrozenClosed(true);
				}
			}
		}
	}, [
		profile,
		show,
		frozenClosed,
		setFrozenClosed,
		userTutorialData,
		dispatch,
	]);

	if (!show) return null;

	const handleClose = async () => {
		setFrozenClosed(true);
		dispatch(setShow(false));
	};

	return (
		<Overlay>
			<CloseButton onClick={handleClose}>
				<ExIcon color='#ffffff' />
			</CloseButton>
			<Wrap>
				<Content idx={currentSlide}>
					<div className='slideContainer'>
						{slides.map((v, idx) => (
							<Slide key={idx}>
								<figure>
									<img
										src={`${process.env.PUBLIC_URL}/img/newUserWalkthrough/${v.img}`}
										alt=''
									/>
								</figure>
								<section>
									<Heading type={HEADING_TYPES.PREVIEW}>{v.title}</Heading>
									<p>{v.copy}</p>
									{v.learn !== '' ? (
										<a
											target='_blank'
											rel='noopener noreferrer'
											className='learn'
											href={`${process.env.PUBLIC_URL}/assets/${v.learn}`}
										>
											Learn More
										</a>
									) : (
										<></>
									)}
								</section>
							</Slide>
						))}
					</div>
					<Controls>
						{currentSlide > 0 ? (
							<Button
								onClick={() => setSlideIndex(currentSlide - 1)}
								className='back'
								el='a'
							>
								Back
							</Button>
						) : (
							<></>
						)}
						{currentSlide < slides.length - 1 ? (
							<Button
								onClick={() => setSlideIndex(currentSlide + 1)}
								className='next'
								el='a'
								variant='alternate'
							>
								Next
							</Button>
						) : (
							<Button
								onClick={handleClose}
								className='finish'
								el='a'
								variant='alternate'
							>
								Go Explore
							</Button>
						)}
					</Controls>
				</Content>
				<Indicators>
					{slides.map((v, idx) => (
						<span
							key={idx}
							onClick={() => setSlideIndex(idx)}
							className={idx === currentSlide ? 'active' : ''}
						></span>
					))}
				</Indicators>
			</Wrap>
		</Overlay>
	);
}

export default NewUserCarousel;
