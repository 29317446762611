/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	uploadFile,
	uploadGalleryImages,
	deleteFile,
	deleteGalleryImage,
	updateField,
	deleteDoc,
	shiftPreviewGalleryImageOrder,
} from '../config/store/actions/admin';
import CloudStorageImg from '../components/_utilities/CloudStorageImg';
import CloudStorageDownloadButton from '../components/_atoms/CloudStorageDownloadButton';

const Wrapper = styled.div`
	padding: 5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.id {
		width: 100%;
		text-align: center;
	}

	h1 {
		width: 100%;
		text-align: center;
	}

	> div {
		width: 25%;
		margin: 25px;
		display: flex;
		flex-direction: column;
	}

	.delete {
		color: red;
		width: 150px;
		margin-bottom: 10px;
	}

	.delete-gallery {
		color: red;
	}

	.delete-it-all {
		color: red;
	}

	.monthlyPriority-file,
	.preview-gallery {
		width: 100%;
		border: 1px black solid;
		padding: 10px;
		input {
			margin: 10px 0;
		}
		p {
			margin: 5px 0;
		}
	}

	.preview-gallery {
		> div {
			display: flex;
			width: 100%;
			justify-content: space-between;
			flex-wrap: wrap;
			> div.item {
				flex-grow: 1;
				flex-basis: 120px;
				margin: 15px 5px;
				height: 160px;
				border: 1px black solid;
				background-color: black;
				.controls {
					display: flex;
					justify-content: space-around;

					button {
						background-color: white;
					}
				}
			}
		}
	}
`;

const monthStringArr = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const EditMonthlyPriorityPage = (props) => {
	const monthlyPriorityId = props.match.params.id;

	useFirestoreConnect([
		{
			collection: 'monthlyPriority',
			doc: monthlyPriorityId,
			storeAs: 'editMonthlyPriority',
		},
		{
			collection: 'config',
			doc: 'dataModel',
			storeAs: 'dataModel',
		},
	]);

	const dataModel = useSelector((state) => state.firestore.data.dataModel);
	const sourceMonthlyPriority = useSelector(
		(state) => state.firestore.data.editMonthlyPriority
	);
	const sourceMonthlyPriorityErrors = useSelector(
		(state) => state.firestore.errors.byQuery.editMonthlyPriority
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const [fields, setFields] = useState({});

	useEffect(() => {
		let categories = {};
		if (sourceMonthlyPriority && sourceMonthlyPriority.categories)
			categories = sourceMonthlyPriority.categories;
		setFields((prev) => ({
			...prev,
			categories: categories,
			...(sourceMonthlyPriority || {}),
		}));
	}, [sourceMonthlyPriority, setFields]);

	if (!isLoaded(sourceMonthlyPriority) || !isLoaded(dataModel))
		return 'LOADING MONTHLY PRIORITY DATA';
	if (isEmpty(sourceMonthlyPriority))
		return 'COULD NOT LOCATE MONTHLY PRIORITY';
	if (sourceMonthlyPriorityErrors) return 'ERROR LOADING MONTHLY PRIORITY';

	const handleChange = (e) => {
		const value = e.target.valueAsNumber
			? e.target.valueAsNumber
			: e.target.value;
		const name = e.target.name;
		setFields((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleMonthlyPriorityUploadChange = (e) => {
		const files = Array.from(e.target.files);
		dispatch(
			uploadFile('monthlyPriority', monthlyPriorityId, files[0], (_, __) => {
				console.log(_ / __);
			})
		);
	};

	const handleGalleryUploadChange = (e) => {
		const files = Array.from(e.target.files);
		dispatch(uploadGalleryImages('monthlyPriority', monthlyPriorityId, files));
	};

	const handleUpdateField = (field, value) => {
		dispatch(updateField('monthlyPriority', monthlyPriorityId, field, value));
	};

	const handleDeleteMonthlyPriorityFile = () => {
		if (
			window.confirm(
				'Are you sure you want to delete the file associated with this monthly priority?'
			)
		) {
			dispatch(deleteFile(sourceMonthlyPriority.metadata.path));
		}
	};

	const handleDeleteGalleryImage = (path) => {
		if (window.confirm('Are you sure you want to delete this Gallery Image?')) {
			dispatch(deleteGalleryImage(path));
		}
	};

	const handleGalleryImageShift = (path, forward) => {
		dispatch(
			shiftPreviewGalleryImageOrder(
				'monthlyPriority',
				monthlyPriorityId,
				path,
				forward
			)
		);
	};

	const handleDeleteEntireMonthlyPriority = () => {
		if (
			window.confirm(
				'Are you sure you want to delete this Monthly Priority and all associated files?'
			)
		) {
			if (window.confirm('Are you really sure?')) {
				dispatch(deleteDoc('monthlyPriority', monthlyPriorityId)).then(() =>
					history.push('/')
				);
			}
		}
	};

	return (
		<Wrapper>
			<p className='id'>ID: {monthlyPriorityId}</p>
			<div className='select'>
				<label htmlFor='status'>Status</label>
				<select
					id='status'
					type='select'
					value={fields['status']}
					onChange={(e) => handleUpdateField('status', e.target.value)}
				>
					<option value='live'>Live</option>
					<option value='draft'>Draft</option>
				</select>
			</div>
			<div className='select'>
				<label htmlFor='month'>Month</label>
				<select
					id='month'
					type='select'
					value={fields['month']}
					onChange={(e) => handleUpdateField('month', parseInt(e.target.value))}
				>
					{Array.apply(null, Array(12)).map((_e, idx) => (
						<option value={idx} key={idx}>
							{monthStringArr[idx]}
						</option>
					))}
				</select>
			</div>
			<div className='number'>
				<label htmlFor='year'>Year</label>
				<input
					id='year'
					name='year'
					min='2020'
					step='1'
					type='number'
					value={fields['year']}
					onChange={handleChange}
					onBlur={() => handleUpdateField('year', fields['year'])}
				/>
			</div>
			<div className='number'>
				<label htmlFor='priority'>{`Sort Priority (Lowest First)`}</label>
				<input
					id='priority'
					name='priority'
					min='0'
					step='1'
					type='number'
					value={fields['priority']}
					onChange={handleChange}
					onBlur={() => handleUpdateField('priority', fields['priority'])}
				/>
			</div>
			{['name'].map((key) => (
				<div key={key} className='text'>
					<label htmlFor={key}>{key.toUpperCase()}</label>
					<input
						id={key}
						name={key}
						type='text'
						value={fields[key]}
						onChange={handleChange}
						onBlur={() => handleUpdateField(key, fields[key])}
					/>
				</div>
			))}
			<div className='monthlyPriority-file'>
				{sourceMonthlyPriority.metadata && (
					<button className='delete' onClick={handleDeleteMonthlyPriorityFile}>
						Delete File
					</button>
				)}
				<label htmlFor='monthlyPriority-upload'>
					Upload a New Monthly Priority PDF
				</label>
				<input
					type='file'
					id='monthlyPriority-upload'
					onChange={handleMonthlyPriorityUploadChange}
				/>
				<p>
					Current:{' '}
					{sourceMonthlyPriority.metadata &&
						sourceMonthlyPriority.metadata.fileName}
				</p>
				<p>
					{sourceMonthlyPriority.metadata &&
						sourceMonthlyPriority.metadata.processing &&
						'Processing Monthly Priority'}
				</p>
				<p style={{ fontSize: '8px' }}>
					<pre>{JSON.stringify(sourceMonthlyPriority.metadata, null, 2)}</pre>
				</p>
				{sourceMonthlyPriority.metadata &&
					sourceMonthlyPriority.metadata.path && (
						<CloudStorageDownloadButton
							path={sourceMonthlyPriority.metadata.path}
						/>
					)}
			</div>
			<div className='preview-gallery'>
				<label htmlFor='gallery-upload'>Upload Gallery Images</label>
				<input
					type='file'
					id='gallery-upload'
					accept='image/*'
					multiple
					onChange={handleGalleryUploadChange}
				/>
				<div>
					{sourceMonthlyPriority.previewGalleryPaths &&
						sourceMonthlyPriority.previewGalleryPaths.map((path, idx) => {
							return (
								<div className='item' key={idx}>
									<CloudStorageImg path={path} />
									<div className='controls'>
										<button
											onClick={() => handleGalleryImageShift(path, false)}
										>
											{'<<'}
										</button>
										<button
											className='delete-gallery'
											onClick={() => handleDeleteGalleryImage(path)}
										>
											X
										</button>
										<button onClick={() => handleGalleryImageShift(path, true)}>
											{'>>'}
										</button>
									</div>
								</div>
							);
						})}
				</div>
			</div>
			<div>
				<button
					className='delete-it-all'
					onClick={handleDeleteEntireMonthlyPriority}
				>
					Delete Entire Monthly Priority
				</button>
			</div>
		</Wrapper>
	);
};

export default withRouter(EditMonthlyPriorityPage);
