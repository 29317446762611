import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrap = styled.span`
	display: block;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	position: relative;

	> span {
		position: absolute;
		display: block;
		height: ${(props) => props.thickness}px;
		width: ${(props) => props.hyp}px;
		background-color: ${(props) => props.color};
		transform-origin: center;
		left: 50%;
		top: 50%;
		&:first-child {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:last-child {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
`;

function ExIcon(props) {
	// hypotenuse
	const _hypotenuse = props.length;
	// assuming 45deg, convert the angle to radians
	const _radians = (45 * Math.PI) / 180;
	// the legs will be equal since it's a 45deg angle
	const _legs = Math.sin(_radians) * _hypotenuse;

	return (
		<Wrap size={_legs} hyp={_hypotenuse} {...props}>
			<span></span>
			<span></span>
		</Wrap>
	);
}

ExIcon.propTypes = {
	length: PropTypes.number.isRequired,
	thickness: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
};

ExIcon.defaultProps = {
	length: 20,
	thickness: 2,
	color: '#000000',
};

export default ExIcon;
