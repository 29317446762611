import React, { useEffect } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LoadingIcon from '../_atoms/LoadingIcon';
import NewUserCarousel from '../_organisms/NewUserCarousel';
import TagManager from 'react-gtm-module';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	h1 {
		width: 500px;
		max-width: 80%;
	}
`;

const WaitForAuth = (props) => {
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);

	useEffect(() => {
		if (!(isLoaded(auth) && auth && auth.uid && isLoaded(profile) && profile))
			return;

		TagManager.dataLayer({
			dataLayer: {
				event: 'user_authorized',
				fca_user_id: auth.uid,
				individual_user_id: profile.nameId || auth.uid,
				dealer_code: profile.dealerCode || auth.uid,
			},
			dataLayerName: 'dataLayer',
		});
	}, [auth, profile]);

	if (!isLoaded(auth) || !isLoaded(profile))
		return (
			<Wrapper>
				<LoadingIcon />
			</Wrapper>
		);

	return (
		<>
			{auth.uid && <NewUserCarousel />}
			{props.children}
		</>
	);
};

export default WaitForAuth;
