/* eslint-disable indent */
export const ACTION_TYPES = {
	SET_LOGGED_OUT: '_AUTH_SET_LOGGED_OUT',
	SET_WAS_FCA: '_AUTH_SET_WAS_FCA',
};

const initialState = {
	loggedOut: false,
	wasFca: {},
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_LOGGED_OUT:
			return {
				...state,
				loggedOut: action.payload,
			};
		case ACTION_TYPES.SET_WAS_FCA:
			return {
				...state,
				wasFca: action.payload,
			};
		default:
			return state;
	}
}
