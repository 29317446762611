export const ACTION_TYPES = {
	SET_FILTER_SORT: '_ASSET_DOCS_SET_FILTER_SORT',
	CLEAR_RESULTS: '_ASSET_DOCS_CLEAR_RESULTS',
	ADD_RESULTS_PAGE: '_ASSET_DOCS_ADD_RESULTS_PAGE',
	SET_ALL_RESULTS_FOUND: '_ASSET_DOCS_SET_ALL_RESULTS_FOUND',
	SET_LOADING_RESULTS: '_ASSET_DOCS_SET_LOADING_RESULTS',
	SET_SEARCH_CLIENT: '_ASSET_DOCS_SET_SEARCH_CLIENT',
	SET_RESULTS_SOURCE: '_ASSET_DOCS_SET_RESULTS_SOURCE',
	SET_PAGES_REQUESTED_COUNT: '_ASSET_DOCS_SET_PAGES_REQUESTED_COUNT',
};

const initialState = {
	filterSort: {},
	allResultsFound: false,
	results: [],
	resultsSource: '',
	resultsPagesRequested: 0,
	loadingResults: false,
	searchClient: {
		client: null,
		expiresAt: 0,
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_FILTER_SORT:
			return {
				...state,
				filterSort: action.payload,
			};
		case ACTION_TYPES.CLEAR_RESULTS:
			return {
				...state,
				results: [],
				allResultsFound: false,
				resultsPagesRequested: 0,
			};
		case ACTION_TYPES.ADD_RESULTS_PAGE:
			// eslint-disable-next-line no-case-declarations
			const newResults = [...state.results];
			newResults.push(action.payload);
			return {
				...state,
				results: newResults,
			};
		case ACTION_TYPES.SET_ALL_RESULTS_FOUND:
			return {
				...state,
				allResultsFound: action.payload,
			};
		case ACTION_TYPES.SET_ALL_FILTERED_RESULTS_FOUND:
			return {
				...state,
				loadingResults: action.payload,
			};
		case ACTION_TYPES.SET_SEARCH_CLIENT:
			return {
				...state,
				searchClient: action.payload,
			};
		case ACTION_TYPES.SET_RESULTS_SOURCE:
			return {
				...state,
				results: action.payload === state.resultsSource ? state.results : [],
				resultsSource: action.payload,
			};
		case ACTION_TYPES.SET_PAGES_REQUESTED_COUNT:
			return {
				...state,
				resultsPagesRequested: action.payload,
			};
		default:
			return state;
	}
};
