/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import Text from '../../_atoms/Text';
import prettyBytes from 'pretty-bytes';
import Skeleton from 'react-loading-skeleton';
import TitleTruncator from '../../_utilities/TitleTruncator';
import ReactHtmlParser from 'react-html-parser';

const MetaWrapper = styled.div`
	margin: 0 0 12px 0;
`;

const MetaWrapperInline = styled(MetaWrapper)`
	display: flex;
	margin: 0;
	h2,
	p {
		font-size: 12px !important;
		line-height: 18px;
		color: #000000;
	}
	h2 {
		flex: 0 0 35%;
		&:after {
			content: ': ';
			display: inline-block;
			margin-right: 0.25em;
		}
	}
`;

const camelToTitleCase = (camelCase) => {
	if (!camelCase) return '';
	let result = camelCase.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
};

const transformMetaKeys = {
	contentType: 'format',
	displayManualFormat: 'format',
	displayCategories: 'assetCategories',
};

const transformKey = (key) => {
	return camelToTitleCase(transformMetaKeys[key]) || camelToTitleCase(key);
};

const transformValue = (key, value) => {
	switch (key) {
		case 'size':
			return prettyBytes(parseFloat(value));
		case 'displayCategories':
			return value && value.length
				? value.reduce((prev, curr) => [prev, ', ', curr])
				: '';
		default:
			return value;
	}
};

function MetaData(props) {
	let {
		metakey,
		metavalue,
		loading,
		loadingValueCount,
		loadingValueWidth,
	} = props;
	if (!props.useRaw) {
		if (metakey && metavalue) metavalue = transformValue(metakey, metavalue);
		if (metakey) metakey = transformKey(metakey);
	}

	if (!metakey || !metavalue) return null;

	if (metakey.toLowerCase() === 'file name')
		metavalue = ReactHtmlParser(TitleTruncator(metavalue));

	let Container = props.inline ? MetaWrapperInline : MetaWrapper;
	return (
		<Container inline={props.inline}>
			<Text type='metaTitle'>
				{metakey || (loading && <Skeleton width={'30%'} />)}
			</Text>
			<Text>
				{metavalue ||
					(loading && (
						<Skeleton width={loadingValueWidth} count={loadingValueCount} />
					))}
			</Text>
		</Container>
	);
}

export default MetaData;
