import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import Heading, { HEADING_TYPES } from '../../_atoms/Heading';
import IconNext from './caret-right.svg';
import IconPrev from './caret-left.svg';
import IconNextOver from './caret-right-over.svg';
import IconPrevOver from './caret-left-over.svg';

const PaginationButton = styled.button`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
	color: ${({ active }) =>
		active ? 'rgba(130, 130, 130, 1)' : 'rgba(130, 130, 130, 0.3)'};
	height: 32px;
	cursor: ${({ active }) => (active ? 'pointer' : 'default')};
	&:first-child {
		background: url(${IconPrev}) no-repeat left center;
		padding-left: 28px;
	}
	&:last-child {
		background: url(${IconNext}) no-repeat right center;
		padding-right: 28px;
	}

	${({ active }) =>
		active
			? `&:hover {
		color: #0067b1;
		&:first-child {
			background: url(${IconPrevOver}) no-repeat left center;
		}
		&:last-child {
			background: url(${IconNextOver}) no-repeat right center;
		}
	}`
			: ''}
`;

const Controls = styled.div`
	padding: 28px 30px 0;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;

	h5 {
		text-transform: uppercase;
	}
`;

const monthStringArr = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const getNextMonthYear = (month, year) => {
	if (month < 11)
		return {
			year,
			month: month + 1,
		};
	else
		return {
			year: year + 1,
			month: 0,
		};
};

const getPrevMonthYear = (month, year) => {
	if (month > 0)
		return {
			year,
			month: month - 1,
		};
	else
		return {
			year: year - 1,
			month: 11,
		};
};

const MonthlyPriorityNav = (props) => {
	const { month, year, changePage } = props;
	const nextMonthYear = getNextMonthYear(month, year);
	const prevMonthYear = getPrevMonthYear(month, year);

	useFirestoreConnect(() => [
		{
			collection: 'monthlyPriority',
			limit: 1,
			where: [
				['month', '==', nextMonthYear.month],
				['year', '==', nextMonthYear.year],
				['status', '==', 'live'],
			],
			storeAs: `monthlyPriority_exists_${nextMonthYear.month}_${nextMonthYear.year}`,
		},
		{
			collection: 'monthlyPriority',
			limit: 1,
			where: [
				['month', '==', prevMonthYear.month],
				['year', '==', prevMonthYear.year],
				['status', '==', 'live'],
			],
			storeAs: `monthlyPriority_exists_${prevMonthYear.month}_${prevMonthYear.year}`,
		},
	]);

	const next = useSelector(
		(state) =>
			state.firestore.ordered[
				`monthlyPriority_exists_${nextMonthYear.month}_${nextMonthYear.year}`
			]
	);
	const prev = useSelector(
		(state) =>
			state.firestore.ordered[
				`monthlyPriority_exists_${prevMonthYear.month}_${prevMonthYear.year}`
			]
	);

	const incrementPage = () => {
		if (!next || next.length < 1) return;
		changePage(nextMonthYear);
	};

	const decrementPage = () => {
		if (!prev || prev.length < 1) return;
		changePage(prevMonthYear);
	};

	return (
		<Controls>
			<PaginationButton
				active={prev && prev.length > 0}
				onClick={decrementPage}
			>
				Previous Month
			</PaginationButton>
			<Heading type={HEADING_TYPES.ASSET}>
				{monthStringArr[month]} {year} Priority
			</Heading>
			<PaginationButton
				active={next && next.length > 0}
				onClick={incrementPage}
			>
				Next Month
			</PaginationButton>
		</Controls>
	);
};

export default MonthlyPriorityNav;
