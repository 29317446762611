import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { newEntry } from '../config/store/actions/admin';

const NewMonthlyPriorityPage = (props) => {
	const { createNewAsset } = props;
	const history = useHistory();
	useEffect(() => {
		createNewAsset().then(({ docId }) =>
			history.replace(`/edit-monthly-priority/${docId}`)
		);
	}, [props.createNewAsset, history, createNewAsset]);
	return 'Creating new asset...';
};

const mapDispatchToProps = (dispatch) => ({
	createNewAsset: () => dispatch(newEntry('monthlyPriority')),
});

export default connect(null, mapDispatchToProps)(NewMonthlyPriorityPage);
