/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import HomeTop from '../components/_organisms/HomeTop';
import Footer from '../components/_atoms/Footer';
import FilterMenu from '../components/_organisms/FilterMenu';
import { connect } from 'react-redux';
import { updateSort, updateStatus } from '../config/store/actions/assetDocs';

import styled from 'styled-components';
import AssetResults from '../components/_organisms/AssetResults';
import SearchBar from '../components/_organisms/SearchBar';
import AssetPreview from '../components/_organisms/AssetPreview';

import TagManager from 'react-gtm-module';

const FilterWrap = styled.div`
	background-color: #0067b1;
	width: 333px;
	padding: 0 29px;
	box-sizing: border-box;
	flex: 0 0 333px;
	height: 100vh;
	position: sticky;
	top: 0;
	overflow-x: hidden;
	overflow-y: scroll;
`;

const HomeBody = styled.div`
	.ref-container {
		display: flex;
		min-height: 800px;
	}
`;

const Column2 = styled.div`
	flex-grow: 1;
`;

const ActionBar = styled.div`
	padding: 30px 30px 0;
	padding-bottom: 15px;
	display: flex;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: white;
	max-width: 100%;
	z-index: 200;
	/* box-shadow:  1px 10px 14px -8px rgba(0,0,0,1); */
`;

const SORTING_METHODS = {
	MOST_RELEVANT: {
		value: 'mostRelevant',
		label: 'Most Relevant',
	},
	NEWEST: {
		value: 'newest',
		label: 'Newest',
		field: 'createdAt',
		direction: 'desc',
	},
	OLDEST: {
		value: 'oldest',
		label: 'Oldest',
		field: 'createdAt',
		direction: 'asc',
	},
	AZ: { value: 'az', label: 'A-Z', field: 'name', direction: 'asc' },
	ZA: { value: 'za', label: 'Z-A', field: 'name', direction: 'desc' },
};

const Select = styled.div`
	box-sizing: border-box;
	display: flex;
	background-color: #000;
	height: 30px;
	width: 150px;
	color: #ffffff;
	align-items: center;
	font-family: 'Saira Extra Condensed';
	position: relative;
	p {
		margin: 0;
		font-weight: 700;
		font-size: 18px;
		letter-spacing: 0.05em;
		position: relative;
		z-index: 25;
		padding: 0 0 0 13px;
		pointer-events: none;
		display: block;
	}
	select {
		box-sizing: border-box;
		color: #ffffff;
		font-size: 18px;
		font-weight: 500;
		background-color: #000000;
		letter-spacing: 0.05em;
		padding-right: 32px;
		padding-left: 54px;
		border: none;
		outline: none;
		cursor: pointer;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 15;
		appearance: none;
	}
`;

const hasSearchQuery = (filterSort) =>
	filterSort && filterSort.query && filterSort.query.length > 0;

function Home(props) {
	const {
		resultsPageCount,
		updateSort,
		filterSort,
		withEdit,
		updateStatus,
	} = props;
	// current sort method
	const [sort, setSort] = useState(
		filterSort.sort
			? filterSort.sort.value
			: hasSearchQuery(filterSort)
			? SORTING_METHODS.MOST_RELEVANT.value
			: SORTING_METHODS.NEWEST.value
	);

	useEffect(() => {
		setSort(
			filterSort.sort
				? filterSort.sort.value
				: hasSearchQuery(filterSort)
				? SORTING_METHODS.MOST_RELEVANT.value
				: SORTING_METHODS.NEWEST.value
		);
	}, [filterSort]);

	// preview
	const [isPreviewing, setPreview] = useState(false);
	// preview data
	const [previewData, setPreviewData] = useState({});
	// open preview
	const openPreview = (data) => {
		setPreviewData(data);
		setPreview(true);
	};

	const [manuallySetSort, setManuallySetSort] = useState(
		filterSort.sort ? (filterSort.sort.manuallySet ? true : false) : false
	);

	useEffect(() => {
		const isSearch = hasSearchQuery(filterSort);
		if (
			isSearch &&
			sort !== SORTING_METHODS.MOST_RELEVANT.value &&
			!manuallySetSort
		) {
			setSort(SORTING_METHODS.MOST_RELEVANT.value);
		} else if (!isSearch && sort === SORTING_METHODS.MOST_RELEVANT.value) {
			setSort(SORTING_METHODS.NEWEST.value);
			setManuallySetSort(false);
		}
	}, [setSort, sort, filterSort, manuallySetSort, setManuallySetSort]);

	useEffect(() => {
		const SORT_METHOD = Object.values(SORTING_METHODS).find(
			(o) => o.value === sort
		);
		updateSort({ ...SORT_METHOD, manuallySet: manuallySetSort });
	}, [updateSort, sort, manuallySetSort]);

	const handleChangeSort = (e) => {
		e.persist();

		TagManager.dataLayer({
			dataLayer: {
				event: 'sort_applied',
				sort_rule: e.currentTarget.value,
			},
			dataLayerName: 'dataLayer',
		});

		setManuallySetSort(
			hasSearchQuery(filterSort) &&
				e.currentTarget.value !== SORTING_METHODS.MOST_RELEVANT.value
		);

		setSort(e.currentTarget.value);
	};

	const homeBodyRef = useRef(null);
	useEffect(() => {
		if (!homeBodyRef || !homeBodyRef.current) return;
		if (
			window.pageYOffset > homeBodyRef.current.offsetTop &&
			resultsPageCount === 0
		) {
			window.scrollTo(0, homeBodyRef.current.offsetTop);
		}
	}, [resultsPageCount, homeBodyRef]);

	useEffect(() => {
		if (withEdit) updateStatus(['live', 'draft']);
		else updateStatus(['live']);
	}, [withEdit, updateStatus]);

	return (
		<React.Fragment>
			{isPreviewing ? (
				<AssetPreview
					onCloseHandler={() => setPreview(false)}
					data={previewData}
				/>
			) : null}

			<HomeTop />
			<HomeBody>
				<div className='ref-container' ref={homeBodyRef}>
					<FilterWrap>
						<FilterMenu />
					</FilterWrap>
					<Column2>
						<ActionBar>
							<SearchBar />
							<Select>
								<p>SORT:</p>
								<select onChange={handleChangeSort} value={sort}>
									{Object.keys(SORTING_METHODS)
										.filter(
											(key) =>
												SORTING_METHODS[key].value !==
													SORTING_METHODS.MOST_RELEVANT.value ||
												hasSearchQuery(filterSort)
										)
										.map((v, idx) => (
											<option key={idx} value={SORTING_METHODS[v].value}>
												{SORTING_METHODS[v].label}
											</option>
										))}
								</select>
							</Select>
						</ActionBar>
						<AssetResults
							withEdit={withEdit}
							openPreviewHandler={openPreview}
						/>
					</Column2>
				</div>
			</HomeBody>
			<Footer />
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	filterSort: state.assetDocs.filterSort,
	resultsPageCount: state.assetDocs.results.length,
});

const mapDispatchToProps = (dispatch) => ({
	updateSort: (sortObj) => dispatch(updateSort(sortObj)),
	updateStatus: (statusArr) => dispatch(updateStatus(statusArr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
