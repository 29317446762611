import React, { useEffect } from 'react';
import styled from 'styled-components';
import CloudStorageImg from '../components/_utilities/CloudStorageImg';
import useDownloadHistory from '../hooks/useDownloadHistory';
import useRangeState from '../hooks/useRangeState';
import format from 'date-fns/format';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import { ReactComponent as DownloadIcon } from '../components/_atoms/Button/download-blue.svg';
import { useDispatch } from 'react-redux';
import { downloadFile } from '../config/store/actions/cloudStorage';

const TableWrapper = styled.div`
	margin-top: 60px;
	margin-bottom: 100px;
	table {
		display: table;
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		font-family: 'Saira', sans-serif;
		font-weight: 500;

		thead {
			color: #828282;
			font-size: 12px;
			text-transform: uppercase;
		}

		tr {
			border-bottom: lightgray 1px solid;
		}

		th,
		td {
			width: auto;
			padding: 5px 10px;
		}

		td {
			font-size: 12px;
		}

		.wide {
			width: 40%;
		}

		.align-left {
			text-align: left;
		}

		.tn-and-title {
			height: 80px;
			display: flex;
			align-items: center;
			.tn-container {
				height: 100%;
				width: 100px;
				margin-right: 20px;
			}
			font-weight: bold;
			font-size: 16px;
		}
		.hoverable {
			cursor: pointer;
		}
	}
`;

const ControlsWrapper = styled.div`
	margin: 0 auto;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	.item {
		color: #aaaaaa;
		cursor: pointer;
		padding: 8px;
	}
	.active {
		color: #0067b1;
	}
	.hidden {
		visibility: hidden;
	}
`;

const PaginationControls = ({ currentIdx, maxIdx, onChange }) => {
	const indices = [...Array(maxIdx + 1).keys()];

	return (
		<ControlsWrapper>
			<div
				className={`item${currentIdx < 1 ? ' hidden' : ''}`}
				onClick={() => onChange(currentIdx - 1)}
			>
				{`<`}
			</div>
			{indices.length &&
				indices.map((idx) => {
					return (
						<div
							className={`item${currentIdx === idx ? ' active' : ''}`}
							onClick={() => onChange(idx)}
							key={idx}
						>
							{idx + 1}
						</div>
					);
				})}
			<div
				className={`item${currentIdx >= maxIdx ? ' hidden' : ''}`}
				onClick={() => onChange(currentIdx + 1)}
			>
				{`>`}
			</div>
		</ControlsWrapper>
	);
};

const DownloadHistoryTable = () => {
	const { pages, fillTo, availablePagesCount } = useDownloadHistory();
	const [selectedIdx, setSelectedIdx] = useRangeState(0, {
		min: 0,
		max: availablePagesCount,
	});

	const handlePageChange = (idx) => {
		setSelectedIdx(idx);
	};

	const dispatch = useDispatch();
	const handleDownload = (path, id) => {
		dispatch(
			downloadFile(path, {
				verifyPathId: id,
				doNotRecord: true,
			})
		);
	};

	useEffect(() => {
		if (!pages[selectedIdx] || !pages[selectedIdx].length) {
			fillTo(selectedIdx);
		}
	}, [selectedIdx, pages, fillTo]);

	const currentPage = pages[selectedIdx];

	return (
		<TableWrapper>
			<table>
				<thead>
					<tr>
						<th className='align-left wide'>Asset</th>
						<th>Download Date</th>
						<th>Asset Category</th>
						<th>Format</th>
						<th>Dimensions</th>
						<th>Download</th>
					</tr>
				</thead>
				<tbody>
					{currentPage &&
						currentPage.map(({ data }) => {
							const {
								title,
								thumbnailPath,
								displayCategories,
								displayManualFormat,
								downloadPath,
								downloadedAt,
								dimensions,
								id,
							} = data;
							return (
								<tr key={id}>
									<td className='align-left wide'>
										<div className='tn-and-title'>
											<div className='tn-container'>
												<CloudStorageImg path={thumbnailPath} />
											</div>
											<span>{title}</span>
										</div>
									</td>
									<td>{format(downloadedAt, 'MMM. d, yyy')}</td>
									<td>{displayCategories && displayCategories.join(', ')}</td>
									<td>{displayManualFormat}</td>
									<td>{dimensions}</td>
									<td>
										<DownloadIcon
											className='hoverable'
											width='20px'
											height='20px'
											onClick={() => handleDownload(downloadPath, id)}
										/>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			{availablePagesCount > 1 && (
				<PaginationControls
					currentIdx={selectedIdx}
					maxIdx={availablePagesCount - 1}
					onChange={handlePageChange}
				/>
			)}
		</TableWrapper>
	);
};

const DownloadHistoryPage = () => {
	return (
		<>
			<Hero size='sm'>
				<h1>Download History</h1>
			</Hero>
			<DownloadHistoryTable />
			<Footer />
		</>
	);
};

export default DownloadHistoryPage;
