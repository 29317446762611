export const ACTION_TYPES = {
	SET_CACHE: '_COLLECTION_CART_SET_CACHE',
	ADD_JOINS: '_COLLECTION_CART_ADD_JOINS',
	CLEAR_JOINS: '_COLLECTION_CART_CLEAR_JOINS',
};

const initialState = {
	byId: {},
	assetJoins: {},
	list: [],
	loaded: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_CACHE:
			return {
				...state,
				byId: action.payload.byId,
				list: action.payload.list,
				loaded: true,
			};
		case ACTION_TYPES.ADD_JOINS:
			return {
				...state,
				assetJoins: {
					...state.assetJoins,
					...action.payload.newJoins,
				},
			};
		case ACTION_TYPES.CLEAR_JOINS:
			return {
				...state,
				assetJoins: initialState.assetJoins,
			};
		default:
			return state;
	}
};
