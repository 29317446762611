import { ACTION_TYPES } from '../reducers/downloadHistoryReducer';
import { BaseExtendedFirebaseInstance } from 'react-redux-firebase';
import firebase from 'firebase';

const PAGE_SIZE = 10; // This is the maximum currently supported. Can be increased by implementing chunking.

export interface PageItem {
	historyDoc: firebase.firestore.DocumentSnapshot;
	assetDoc: firebase.firestore.DocumentSnapshot | null;
	data: {
		thumbnailPath: string;
		title: string;
		displayCategories: string[];
		displayManualFormat: string;
		downloadedAt: number;
		id: string;
		downloadPath: string;
		dimensions: string;
	};
}

const getNextPage = async (
	dispatch: any,
	getState: any,
	getFirebase: () => BaseExtendedFirebaseInstance
) => {
	const currentPages: PageItem[][] = getState().downloadHistory.pages;
	const lastPage = currentPages && currentPages[currentPages.length - 1];
	const lastHistoryDoc =
		(lastPage && lastPage[lastPage.length - 1]?.historyDoc) || null;

	const db = getFirebase().firestore();
	const uid = getState().firebase.auth.uid;
	const downloadHistoryRef = db.collection(`users/${uid}/downloadHistory`);

	const newPage: PageItem[] = [];
	// The distinction between these is necassary to ignore deleted/moved to draft assets
	let filteredNewPage: PageItem[] = [];
	while (filteredNewPage.length < PAGE_SIZE) {
		const lastDocRef = newPage.length
			? newPage[newPage.length - 1].historyDoc
			: lastHistoryDoc;
		let query = downloadHistoryRef
			.orderBy('latestTimestamp', 'desc')
			.limit(PAGE_SIZE);
		if (lastDocRef) {
			query = query.startAfter(lastDocRef || null);
		}

		const historyItems = await query.get();
		if (historyItems.empty) break;

		const assetIds = historyItems.docs.map((doc) => doc.data().assetId);

		const assets = await db
			.collection('assets')
			.where('status', '==', 'live')
			.where('id', 'in', assetIds)
			.get();

		const pageItems: PageItem[] = historyItems.docs.map((doc) => {
			const historyData = doc.data();
			const assetDoc =
				assets.docs.find((el) => el.data().id === historyData.assetId) || null;
			const assetData = assetDoc ? assetDoc.data() : null;
			return {
				historyDoc: doc,
				assetDoc: assetDoc || null,
				data: {
					displayCategories: assetData?.displayCategories,
					displayManualFormat: assetData?.displayManualFormat,
					downloadPath: assetData?.metadata?.path,
					downloadedAt: historyData.latestTimestamp,
					id: historyData.assetId,
					thumbnailPath:
						assetData?.thumbnailPath ||
						assetData?.previewGalleryPaths[0] ||
						null,
					title: assetData?.name,
					dimensions: assetData?.dimensions,
				},
			};
		});
		newPage.push(...pageItems);
		filteredNewPage = newPage.filter(({ assetDoc }) => !!assetDoc);

		// Compare current pages to pages at process start
		// If they're mismatched, throw this process out
		if (currentPages.length !== getState().downloadHistory.pages.length) {
			throw new Error(
				'Async collision with a seperate call to the same process.'
			);
		}
	}

	if (filteredNewPage?.length) {
		dispatch({
			type: ACTION_TYPES.ADD_PAGE,
			payload: filteredNewPage,
		});
	} else {
		dispatch({
			type: ACTION_TYPES.SET_NO_MORE,
			payload: true,
		});
	}
};

export const fillToPage = (pageIdx: number) => {
	return async (
		dispatch: any,
		getState: any,
		getFirebase: () => BaseExtendedFirebaseInstance
	) => {
		const uniqueDownloads =
			getState().firebase.profile.uniqueDownloadCount || 0;
		const maxPageCount = Math.ceil(uniqueDownloads / PAGE_SIZE);
		const fillTo = Math.min(pageIdx + 1, maxPageCount);

		while (getState().downloadHistory.pages.length < fillTo) {
			const noMore = getState().downloadHistory.noMore;
			if (noMore) break;
			try {
				await getNextPage(dispatch, getState, getFirebase);
			} catch (e) {
				console.error(e);
				break;
			}
		}
	};
};

export const clearAllPages = () => {
	return async (dispatch: any) => {
		dispatch({
			type: ACTION_TYPES.CLEAR_PAGES,
		});
	};
};
