// Use the length of the flat category to determine priority
const _sort_func = (a, b) => {
	if (a.length < b.length) return 1;
	else return -1;
};

export default (flatCatList) => {
	flatCatList.sort(_sort_func);
	const usage = {
		short: '',
		long: '',
	};
	for (let x = 0; x < flatCatList.length; x++) {
		const item = flatCatList[x];
		switch (item) {
			case 'digital.email':
				usage.short =
					'This is a consumer-facing asset meant to be used in email communications to your customers.';
				usage.long =
					'These assets are to be used as a header in email communications to your customers. Use them when specifically talking about accessories to draw customers into your dealership.';
				break;
			case 'digital.socialMedia':
				usage.short =
					'This is a consumer-facing asset meant to be used on your preferred social platforms.';
				usage.long =
					'This social content can be used on any of your preferred social platforms, such as Facebook and Instagram.';
				break;
			case 'digital.socialMediaStatic':
				usage.short =
					'This is a consumer-facing asset meant to be used on your preferred social platforms.';
				usage.long =
					'These single image posts can be used on any of your preferred social platforms, such as Facebook and Instagram.';
				break;
			case 'digital.images':
				usage.short =
					'This is consumer-facing imagery of vehicles with Mopar Accessories for use at your discretion.';
				usage.long =
					'Due to high demand, vehicle imagery with Mopar Accessories will now be available for you to download. New imagery will be added as it becomes available, allowing you to showcase the latest accessory offerings from Mopar to help drive sales. ';
				break;
			case 'digital.socialMediaSplitGrid':
				usage.short =
					'This is a consumer-facing asset meant to be used on your preferred social platforms.';
				usage.long =
					'These multi-image assets consist of 6 individual images that, when posted separately and in order, create one larger image on your Instagram grid. Each asset is labeled for the order in which it needs to be posted. Note: This is for Instagram only.';
				break;
			case 'digital.socialMediaCarousel':
				usage.short =
					'This is a consumer-facing asset meant to be used on your preferred social platforms.';
				usage.long =
					'Carousels allow you to showcase up to 10 images or videos within a single post on Facebook or Instagram. The assets are labeled for the order in which they need to be posted. If you have a business account and want to post this as a paid ad, you can add a link and description to each individual image that will lead the customer back to your website.';
				break;
			case 'digital.socialMediaVideos':
				usage.short =
					'This is a consumer-facing asset meant to be used on your preferred social platforms.';
				usage.long =
					'This video asset can be used on any of your preferred social platforms, such as Facebook and Instagram.';
				break;
			case 'digital.tvMonitor':
				usage.short =
					'This is a consumer-facing asset meant to be displayed on the TV monitors in your dealership.';
				usage.long =
					'This asset is made to be used on TV monitors throughout your dealership. They are also meant to be used as a set and are labeled in order. Make sure to place them in order when uploading to your TV so the content presents correctly.';
				break;
			case 'digital.website':
				usage.short =
					'This is a consumer-facing asset meant to be uploaded and used on your dealership website.';
				usage.long = '';
				break;
			case 'digital.websiteBranded':
				usage.short =
					'This is a consumer-facing asset meant to be uploaded and used on your dealership website.';
				usage.long =
					'The branded tiles are meant to be used on the dedicated Mopar Accessories and Performance Parts section of your site.';
				break;
			case 'digital.websitePromotional':
				usage.short =
					'This is a consumer-facing asset meant to be uploaded and used on your dealership website.';
				usage.long =
					'These assets are designed to be placed on the homepage of your dealership website to promote certain Mopar accessories. These should be linked to the corresponding parts page to make it easy for interested customers to learn more.';
				break;
			case 'printables.editable.consumerSalesGuide':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'This consumer-facing promotional material serves as a product guide for your customers to review. Use these in high-traffic areas of your dealership to allow your customers to pursue parts and products that interest them. This sales guide is editable, allowing you to fill in your own dealership and pricing info.';
				break;
			case 'printables.editable.dealerSalesGuide':
				usage.short =
					'This is a dealer-facing communication and is not meant to be shown to consumers.';
				usage.long =
					'We’ve created sell sheets and cheat sheets with editable fields so you can customize them with your own pricing and dealership information. These materials are made to be a quick reference guide for available parts on certain vehicle lines and also provide why-buy benefits of those parts.';
				break;
			case 'printables.editable.dreamCards':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'Help customers visualize their dream vehicle with Mopar accessories. Dream Cards are updated quarterly and feature a variety of parts and accessories for FCA vehicles. These cards are editable, allowing you to insert your dealership and pricing information.';
				break;
			case 'printables.prefilled.consumerSalesGuide':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'This consumer-facing promotional material serves as a product guide for your customers to review. Use these in high-traffic areas of your dealership to allow your customers to pursue parts and products that interest them. This sales guide comes with prefilled pricing information.';
				break;
			case 'printables.prefilled.dealerSalesGuide':
				usage.short =
					'This is a dealer-facing communication and is not meant to be shown to consumers.';
				usage.long =
					'We’ve created sell sheets and cheat sheets with key information about Mopar parts and accessories. These materials are made to be a quick reference guide for available parts on certain vehicle lines and also provide why-buy benefits of those parts to your staff internally.';
				break;
			case 'printables.prefilled.dreamCards':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'Help customers visualize their dream vehicle with Mopar accessories. Dream Cards are updated quarterly and feature a variety of parts and accessories for FCA vehicles. These cards come with prefilled pricing information.';
				break;
			case 'printables.prefilled.dealerOrderingAndPromotions':
				usage.short =
					'This is a dealer-facing communication and is not meant to be shown to consumers.';
				usage.long =
					'Get information about the latest promotions and program updates from Mopar. This asset is designed for communication with dealership personnel only.';
				break;
			case 'catalog.productCatalogs':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'Access the latest product catalogs to see up-to-date parts, specs and pricing information.';
				break;
			case 'catalog.accessoryBrochures':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide.';
				usage.long =
					'Stay up to date on the latest offerings from Mopar. This brochure reflects the latest product and pricing information on Mopar products and can be used for reference and sales purposes.';
				break;
			case 'moparCustomShop.heroCards':
				usage.short =
					'This is a dealer-facing asset meant to be used as an upfit guide.';
				usage.long =
					'Show your customers the vehicle they can create with Mopar parts and accessories. These cards are designed to showcase parts packages available to customers looking for a custom vehicle.';
				break;
			case 'moparCustomShop.referenceGuides':
				usage.short =
					'This is a consumer-facing asset meant to be used as a sales aide. Visit the FAQs for our suggested print recommendations.';
				usage.long =
					'This is a consumer-facing asset meant to be used as a sales aide. Visit the FAQs for our suggested print recommendations.';
				break;
			default:
				break;
		}
		if (usage.short && usage.long) break;
	}

	return usage;
};
