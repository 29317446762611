import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import 'core-js/es/string/repeat';
import React from 'react';
import ReactDOM from 'react-dom';
import { Normalize } from 'styled-normalize';
// redux
import { Provider } from 'react-redux';
import store from './config/store/index';
// firebase
import firebase from './config/firebaseConfig';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
// app
import App from './app';

import GlobalStyle from './components/_utilities/GlobalStyle';

const rrfProps = {
	firebase,
	createFirestoreInstance,
	config: { userProfile: 'users', useFirestoreForProfile: true },
	dispatch: store.dispatch,
};

const Index = () => {
	return (
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<Normalize />
				<GlobalStyle />
				<App />
			</ReactReduxFirebaseProvider>
		</Provider>
	);
};

ReactDOM.render(<Index />, document.getElementById('root'));
