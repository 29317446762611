import React from 'react';
import propTypes from 'prop-types';
import extraPropTypes from 'react-extra-prop-types';
import styled from 'styled-components';

const BodyCopy = styled.p`
	font-size: 14px;
	margin: 0;
	color: ${(props) => props.color};
`;

const AssetTitle = styled.h1`
	margin: 0;
	font-size: 20px;
	font-weight: bold;
`;

const MetaTitle = styled.h2`
	font-size: 14px;
	font-family: 'Saira', 'sans-serif';
	font-weight: 600;
	line-height: 18px;
	margin: 0 0 0.2em 0;
`;

function TextFactory(props) {
	switch (props.type) {
		case 'assetTitle':
			return <AssetTitle {...props}>{props.children}</AssetTitle>;
		case 'metaTitle':
			return <MetaTitle {...props}>{props.children}</MetaTitle>;
		default:
			return <BodyCopy {...props}>{props.children}</BodyCopy>;
	}
}

function Text(props) {
	return <TextFactory {...props}>{props.children}</TextFactory>;
}

Text.propTypes = {
	/**
	 *
	 */
	type: propTypes.oneOf(['body', 'assetTitle', 'metaTitle']),
	color: extraPropTypes.color.isRequired,
};

Text.defaultProps = {
	type: 'body',
	color: '#60605b',
};

export default Text;
