import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/**
 * types of headings
 */
const HEADING_TYPES = {
	PAGE: 'pageHeading',
	SECTION: 'sectionHeading',
	PREVIEW: 'previewHeading',
	FILTER: 'filterHeading',
	ASSET: 'assetHeading',
	CATEGORY: 'categoryHeading',
	META: 'metaHeading',
	INFO: 'infoHeading',
	FAQ: 'faq',
};

/**
 * font options
 */
const FONTS = {
	NORMAL: 'Saira, sans-serif',
	CONDENSED: 'Saira Extra Condensed, sans-serif',
};

/**
 * Page titles
 * example: Titles in the heros
 */
const TitleH1 = styled.h1`
	font-family: ${FONTS.CONDENSED};
	font-size: 48px;
	font-weight: 700;
	letter-spacing: 0.05em;
	line-height: 76px;
	text-transform: uppercase;
	margin: 0;
`;

/**
 * Section titles
 * example: Contact Us > Have a Specific Need?
 */
const TitleH2 = styled(TitleH1).attrs({ as: 'h2' })`
	font-size: 36px;
	line-height: 57px;
`;

/**
 * FAQ section headers
 * example: FAQ > Technology
 */
const FAQ = styled(TitleH1).attrs({ as: 'h2' })`
	font-size: 24px;
	line-height: 38px;
`;

/**
 * Asset Preview titles
 */
const TitleH3 = styled(TitleH1).attrs({ as: 'h3' })`
	font-size: 30px;
	line-height: 47px;
	letter-spacing: 0.03em;
	text-transform: none;
`;

/**
 * Example: "FILTERS" title in filter menu
 */
const TitleH4 = styled(TitleH1).attrs({ as: 'h4' })`
	font-size: 25px;
	line-height: 38px;
`;

/**
 * Asset titles
 * examples:
 * 	titles in assets on asset page
 * 	titles in assets on monthly priority page
 */
const TitleH5 = styled(TitleH3).attrs({ as: 'h5' })`
	font-size: 24px;
	line-height: 31px;
`;

/**
 * Used in the category headers of filters
 */
const TitleH6 = styled(TitleH1).attrs({ as: 'h5' })`
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.08em;
`;

/**
 * Asset meta titles
 * common in both asset listings and preview
 */
const AssetMetaTitle = styled.p`
	font-family: ${FONTS.NORMAL};
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	margin: 0;
`;

/**
 * Smaller information titles
 * example: Contact Us > Mopar Parts Assistant Center
 */
const InfoTitle = styled(AssetMetaTitle)`
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
`;

/**
 * Heading generator
 * @param {string} type the type of heading to return
 */
function GetHeading(type) {
	switch (type) {
		case HEADING_TYPES.INFO:
			return InfoTitle;
		case HEADING_TYPES.META:
			return AssetMetaTitle;
		case HEADING_TYPES.CATEGORY:
			return TitleH6;
		case HEADING_TYPES.FILTER:
			return TitleH4;
		case HEADING_TYPES.ASSET:
			return TitleH5;
		case HEADING_TYPES.PREVIEW:
			return TitleH3;
		case HEADING_TYPES.SECTION:
			return TitleH2;
		case HEADING_TYPES.FAQ:
			return FAQ;
		default:
			return TitleH1;
	}
}

/**
 * headings in their various styles
 * @param {object} props
 */
function Heading(props) {
	let HeadingComponent = GetHeading(props.type);
	return <HeadingComponent>{props.children}</HeadingComponent>;
}

Heading.propTypes = {
	type: PropTypes.oneOf(Object.keys(HEADING_TYPES).map((v) => HEADING_TYPES[v]))
		.isRequired,
};

Heading.defaultProps = {
	type: HEADING_TYPES.PAGE,
};

export { HEADING_TYPES };
export default Heading;
