import React, { useState } from 'react';
import styled from 'styled-components';
import { useViewportSize } from '../../../hooks/useViewportSize';
import LogoSrc from './Logo.svg';

const BlockMessage = styled.div`
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Img = styled.img`
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	scale: 1.2;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
`;

const Content = styled.div`
	position: relative;
	text-align: center;
	width: 100%;
	padding: 25px;
`;

const Title = styled.h1`
	font-family: 'Saira', sans-serif;
	font-weight: 500;
	font-size: 28px;
	line-height: 34px;
	margin: 0 auto;
	margin-top: 88px;
	margin-bottom: 20px;

	@media (max-width: 500px) {
		font-size: 14px;
		line-height: 18px;
	}
`;

const Logo = styled.img`
	width: 317px;

	@media (max-width: 500px) {
		width: 180px;
	}
`;

const Button = styled.div`
	font-family: 'Saira Extra Condensed', sans-serif;
	text-transform: uppercase;
	margin: 0 auto;
	font-size: 20px;
	font-weight: 500;
	margin-top: 77px;
	background-color: #000;
	width: max-content;
	color: #fff;
	padding: 5px 15px;

	@media (max-width: 500px) {
		font-size: 14px;
		line-height: 18px;
	}
`;

export const MobileBlocker = ({ children }: { children: any }) => {
	const [override, setOverride] = useState(false);
	const { width } = useViewportSize();
	const isMobile = !!width && width < 880;
	if (isMobile && !override) {
		return (
			<BlockMessage>
				<Img
					src={`${process.env.PUBLIC_URL}/img/mobile-blocker-background.png`}
					alt=''
				/>
				<Content>
					<Logo src={LogoSrc} alt='' />
					<Title>
						For the smoothest ride possible, please visit our Accessory Portal
						home page on a desktop computer.
					</Title>
					<Button onClick={() => setOverride(true)}>
						Proceed to the mopar accessory portal
					</Button>
				</Content>
			</BlockMessage>
		);
	}
	return <>{children}</>;
};
