import React from 'react';
import { connect } from 'react-redux';
import { getPage } from '../../../config/store/actions/assetDocs';
import AccessoryAssetContainer from '../AccessoryAssetContainer';
import { ListingTypes } from '../AssetListing';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding-top: 20px;
	padding-bottom: 80px;
`;

const NoResults = styled.p`
	font-family: 'Saira Extra Condensed', sans-serif;
	font-size: 19px;
	line-height: 28px;
	letter-spacing: 0.03em;
	font-weight: 500;
	color: #aaaaaa;
	margin: 0;
	padding: 0 30px;
`;

const AssetResults = (props) => {
	const { resultPages, getNext, noMore, loadingResults } = props;

	const loadMore = async () => {
		if (loadingResults) return;
		return await getNext();
	};

	return (
		<React.Fragment>
			{noMore && (!resultPages || resultPages.length < 1) ? (
				<NoResults>No result available, please try something else.</NoResults>
			) : (
				<></>
			)}

			<Wrapper>
				<InfiniteScroll
					threshold={600}
					pageStart={0}
					loadMore={loadMore}
					hasMore={!noMore}
					loader={
						<AccessoryAssetContainer
							loading={true}
							listingType={ListingTypes.ACCESSORY}
							columns={3}
							key={0}
						/>
					}
					initialLoad={false}
				>
					{resultPages &&
						resultPages.map((page, idx) => {
							return (
								<AccessoryAssetContainer
									openPreviewHandler={props.openPreviewHandler}
									key={idx}
									data={page}
									listingType={ListingTypes.ACCESSORY}
									columns={3}
									withEdit={props.withEdit}
								/>
							);
						})}
				</InfiniteScroll>
			</Wrapper>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		resultPages: state.assetDocs.results,
		noMore: state.assetDocs.allResultsFound,
		loadingResults: state.assetDocs.loadingResults,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNext: () => dispatch(getPage()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetResults);
