import { connect } from 'react-redux';
import hasPermissions from '../../utils/hasPermissions';

interface Props {
	//** Hide if the user is not authenticated */
	requireAuth?: boolean;
	//** Hide if the user is authenticated (overrides requireAuth) */
	requireNoAuth?: boolean;
	//** Require permissions to be present on the authenticated user's profile (written in dot notation) */
	requirePermissions?: string | string[];
	children?: any;
	auth?: any;
	profile?: any;
}

const RenderGuard = (props: Props) => {
	const {
		auth,
		profile,
		requireAuth,
		requireNoAuth,
		requirePermissions,
	} = props;

	let _passesGuards = true;

	if (requireNoAuth) {
		if (auth && auth.uid) _passesGuards = false;
	} else if (requireAuth && (!auth || !auth.uid)) _passesGuards = false;

	if (requirePermissions) {
		if (profile && profile.permissions) {
			if (!hasPermissions(requirePermissions, profile.permissions)) {
				_passesGuards = false;
			}
		} else _passesGuards = false;
	}

	return _passesGuards ? props.children : null;
};

RenderGuard.defaultProps = {
	requireAuth: true,
	redirectProps: { to: '/' },
};

const mapStateToProps = (state) => ({
	auth: state.firebase.auth,
	profile: state.firebase.profile,
});

export default connect(mapStateToProps)(RenderGuard);
