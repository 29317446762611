import React from 'react';
import styled from 'styled-components';
import Heading, { HEADING_TYPES } from '../../_atoms/Heading';
import FAQ from '../../_atoms/FAQ';

const Wrap = styled.section`
	box-sizing: border-box;
	header {
		margin-bottom: 20px;
	}
`;

function FAQSection(props) {
	const { label, faqs } = props.data;
	return (
		<Wrap>
			<header>
				<Heading type={HEADING_TYPES.FAQ}>{label}</Heading>
			</header>
			<div>
				{faqs.map((v, idx) => (
					<FAQ
						key={idx}
						question={v.question}
						answer={v.answer}
						index={idx + 1}
					/>
				))}
			</div>
		</Wrap>
	);
}

export default FAQSection;
