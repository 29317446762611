import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { downloadFile } from '../../../config/store/actions/cloudStorage';

import Button from '../Button';

function CloudStorageDownloadButton({ download }) {
	return (
		<Button type='download' el='a' variant='alternate' onClick={download}>
			Download
		</Button>
	);
}

CloudStorageDownloadButton.propTypes = {
	//** The path to the file resource in the cloud storage bucket */
	path: propTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	download: () => dispatch(downloadFile(ownProps.path)),
});

export default connect(null, mapDispatchToProps)(CloudStorageDownloadButton);
