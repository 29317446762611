import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AccessoryAsset from '../AccessoryAsset';

const Wrap = styled.div`
	box-sizing: border-box;
	padding: 30px 30px 0;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	justify-items: center;
	row-gap: 50px;
	column-gap: 30px;
`;

function AccessoryAssetContainer(props) {
	return (
		<Wrap columns={props.columns}>
			{!props.loading &&
				props.data.map((v, idx) => (
					<AccessoryAsset
						openPreviewHandler={props.openPreviewHandler}
						key={idx}
						data={v}
						withEdit={props.withEdit}
						titleOnly={props.titleOnly}
					/>
				))}
			{props.loading &&
				Array.apply(null, Array(props.columns)).map((_el, idx) => (
					<AccessoryAsset
						key={idx}
						loading={true}
						titleOnly={props.titleOnly}
					/>
				))}
		</Wrap>
	);
}

AccessoryAssetContainer.propTypes = {
	columns: PropTypes.number.isRequired,
	data: PropTypes.array,
};

AccessoryAssetContainer.defaultProps = {
	columns: 3,
};

export default AccessoryAssetContainer;
