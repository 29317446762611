export const ENVIRONMENTS = {
	DEV: '_ENV_DEV',
	TESTING: '_ENV_TESTING',
	STAGING: '_ENV_STAGING',
	PRODUCTION: '_ENV_PRODUCTION',
};

export const CURRENT_ENV = Object.values(ENVIRONMENTS).includes(window._env_)
	? window._env_
	: ENVIRONMENTS.PRODUCTION;

const DEALER_CONNECT_LINKS = {
	[ENVIRONMENTS.DEV]: {
		base: 'https://testdealerconnect.extra.chrysler.com',
		access:
			'https://federation-test.chrysler.com/idp/startSSO.ping?PartnerSpId=testing.moparaccessoryportal.com&IdpAdapterId=B2DTestGeneric',
	},
	[ENVIRONMENTS.TESTING]: {
		base: 'https://testdealerconnect.extra.chrysler.com',
		access:
			'https://federation-test.chrysler.com/idp/startSSO.ping?PartnerSpId=testing.moparaccessoryportal.com&IdpAdapterId=B2DTestGeneric',
	},
	[ENVIRONMENTS.STAGING]: {
		base: 'https://stagdealerconnect.extra.chrysler.com',
		access:
			'https://federation-stage.chrysler.com/idp/startSSO.ping?PartnerSpId=staging.moparaccessoryportal.com&IdpAdapterId=B2D2IdPStage',
	},
	[ENVIRONMENTS.PRODUCTION]: {
		base: 'https://dealerconnect.chrysler.com',
		access:
			'https://federation.chrysler.com/idp/startSSO.ping?PartnerSpId=moparaccessoryportal.com&IdpAdapterId=B2D2IdPProd',
	},
};

export const CURRENT_DC_LINKS = DEALER_CONNECT_LINKS[CURRENT_ENV];
