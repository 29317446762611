import React, { useState } from 'react';
import styled from 'styled-components';
import { CURRENT_DC_LINKS } from '../../../config/environments';

import Button from '../../_atoms/Button';

/**
 * styled component for the toggle button
 * that switches the view from SSO login
 * to non-SSO login
 */
// const Toggle = styled.button`
// 	display: block;
// 	border: none;
// 	color: #0067b1;
// 	font-size: 16px;
// 	font-weight: 400;
// 	background: none;
// 	text-decoration: underline;
// 	cursor: pointer;
// 	margin: 30px auto 0;
// 	outline: none;
// `;

const SSOWrapper = styled.div`
	box-sizing: border-box;
	width: 100%;

	section {
		box-sizing: border-box;
		padding: 30px 100px 30px 170px;
		display: flex;
		align-items: center;

		h1 {
			font-family: 'Saira Extra Condensed', sans-serif;
			font-weight: 600;
			font-size: 40px;
			line-height: 54px;
			margin-right: auto;
		}

		div {
			width: 342px;
		}

		p {
			font-family: 'Saira', sans-serif;
			font-size: 18px;
			line-height: 24px;
		}

		a {
			width: 100%;
		}

		&:first-child {
			border-bottom: 1px solid #aaaaaa;

			div {
				display: flex;
				flex-wrap: wrap;
				p {
					flex-shrink: 1;
					margin: 0 0 2em 0;
					position: relative;
					padding-left: 80px;
				}
				span {
					display: inline-block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	.toggle {
		box-sizing: border-box;
		background-color: #0067b1;
		padding: 25px 0;
		height: 80px;

		button {
			color: #ffffff;
			margin: 0 auto;
		}
	}

	.help {
		box-sizing: border-box;
		padding-left: 100px;
		padding-right: 108px;
		color: #ffffff;
		background-color: #000000;
		display: flex;
		align-items: center;
		height: 113px;

		h2 {
			font-family: 'Saira Extra Condensed', sans-serif;
			font-weight: 600;
			font-size: 40px;
			line-height: 54px;
			margin: 0 auto 0 0;
		}
	}
`;

/**
 * view to log in a user with SSO
 * @param {object} props
 */
function LoginSSO(props) {
	const AccessClickHandler = (e) => {
		e.persist();
		if (e.altKey && e.ctrlKey) {
			props.onToggle();
		} else {
			window.location.href = CURRENT_DC_LINKS.access;
		}
	};

	const contextMenuEventHandler = (e) => {
		e.persist();
		if (e.altKey && e.ctrlKey) {
			e.preventDefault();
			props.onToggle();
		}
	};

	return (
		<SSOWrapper>
			<section>
				<h1>
					You may access this site by first
					<br />
					logging into DealerCONNECT.
				</h1>
				<div>
					<p>
						<span>Step 1:</span>Log into DealerCONNECT
						<br />
						(opens new window)
					</p>
					<p>
						<span>Step 2:</span>RETURN to this page to access the Mopar®
						Accessory Portal
					</p>
					<Button
						el='a'
						target='_blank'
						rel='noopener noreferrer'
						variant='alternate'
						href={CURRENT_DC_LINKS.base}
					>
						Log Into DealerCONNECT
					</Button>
				</div>
			</section>
			<section>
				<h1>Already logged into DealerCONNECT? </h1>
				<div>
					<p>Click below to access the Mopar Accessory Portal.</p>
					<Button
						onClick={AccessClickHandler}
						onContextMenu={contextMenuEventHandler}
						variant='alternate'
					>
						Access the Portal
					</Button>
				</div>
			</section>
			<div className='help'>
				<h2>Need help?</h2>
				<p>
					Contact us at{' '}
					<a href='mailto:help@moparaccessoryportal.com'>
						help@moparaccessoryportal.com
					</a>
				</p>
			</div>
		</SSOWrapper>
	);
}

/**
 * Wrapper for the Non-SSO login
 */
const NonSSOWrapper = styled.div`
	width: 342px;
	h1 {
		margin: 0 0 0.5em 0;
		font-family: 'Saira Extra Condensed', sans-serif;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 36px;
		line-height: 32px;
		letter-spacing: 5%;
		span {
			font-size: 24px;
			line-height: 37.78px;
			letter-spacing: 10%;
			display: block;
			font-weight: 500;
		}
	}

	p {
		margin: 0 0 32px 0;
	}

	.error {
		color: #e54242;
		line-height: 22px;
		padding: 0 5px;
	}

	input {
		border-color: ${(props) => (props.error ? '#E54242' : '#aaa')};
		border-width: 1px;
		border-style: solid;
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		margin-bottom: 20px;
		color: #324146;
		font-weight: 400;
		padding: 0 18px;
	}
`;

/**
 * view to log in a user without SSO
 * @param {object} props
 */
function LoginNonSSO(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	return (
		<NonSSOWrapper>
			<h1>
				<span>Welcome to the</span> mopar&reg; accessory portal
			</h1>
			<p>Your one-stop shop for all your Accessory marketing needs.</p>
			<input
				disabled={props.loading}
				type='email'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder='Email'
			/>
			<input
				disabled={props.loading}
				type='password'
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				placeholder='Password'
			/>
			{props.error ? (
				<p className='error'>
					The email and password you entered was incorrect. Please try again.
				</p>
			) : (
				<></>
			)}
			<Button onClick={() => props.submitHandler(email, password)}>
				Log In
			</Button>
			{/* <Toggle onClick={props.onToggle}>FCA users log in here</Toggle> */}
		</NonSSOWrapper>
	);
}

/**
 * login component
 * @param {object} props
 */
function Login(props) {
	const [useSSO, setUseSSO] = useState(false);

	let loginType = useSSO ? (
		<LoginSSO
			error={props.error}
			loading={props.loading}
			onToggle={() => setUseSSO(false)}
		/>
	) : (
		<LoginNonSSO
			error={props.error}
			loading={props.loading}
			submitHandler={props.nonSSOHandler}
			onToggle={() => setUseSSO(true)}
		/>
	);
	return loginType;
}

export default Login;
