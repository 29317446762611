import React, { useState } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import Heading, { HEADING_TYPES as TYPES } from '../components/_atoms/Heading';

import ContactForm from '../components/_organisms/ContactForm';

const ContactSection = styled.section`
	max-width: 640px;
	width: 100%;
	margin: 0 auto 48px;

	&:first-child {
		margin-top: 74px;
	}

	&:last-child {
		margin-bottom: 96px;
	}

	> header {
		margin-bottom: 28px;
		p {
			margin: 0;
			font-weight: 400;
			font-family: 'Saira', sans-serif;
			font-size: 18px;
			line-height: 28px;
			letter-spacing: 0.05em;
		}
	}

	.contact-info {
		display: flex;
		flex-wrap: wrap;
	}
`;

const Contact = styled.section`
	margin-bottom: 20px;
	width: 50%;
	box-sizing: border-box;

	p:not(:first-child) {
		margin: 0;
		font-weight: 400;
		font-family: 'Saira', sans-serif;
		font-size: 14px;
		line-height: 22px;
	}

	a {
		color: #0067b1;
	}
`;

const ContactInformation = [
	{ title: 'Mopar Parts Assistance Center', info: ['1-800-846-6727'] },
	{ title: 'DealerCONNECT', info: ['1-800-374-4040'] },
	{ title: 'Dealer Personalized Merchandise', info: ['1-877-247-0261'] },
	{ title: 'On Demand Program', info: ['1-855-213-5483'] },
	{
		title: 'Mopar Program Headquarters',
		info: [
			'1-800-356-3490',
			'Fax: 1-248-553-2138',
			"Email: <a href='mailto:customerservice@dealers-mopar.com'>customerservice@dealers-mopar.com</a>",
		],
	},
];

const PSMWrap = styled.div`
	text-align: center;
	width: 100%;
	margin-top: 100px;
	p {
		margin: 0 0 0.5em;
	}
`;

function ContactUs() {
	const [formSubmitted, updateFormSubmitted] = useState(false);

	const postSubmitHandler = () => {
		updateFormSubmitted(true);
	};

	return (
		<React.Fragment>
			<Hero bg='alternate'>
				<Heading>Contact us</Heading>
				<p>
					If you have questions or need assistance with your marketing
					materials, we can help. First check out the frequently asked questions
					(FAQ) page for quick answers. If your issue isn’t resolved there, find
					the appropriate helpline for your needs in the options below.
				</p>
			</Hero>
			<div>
				<ContactSection>
					<header>
						<Heading type={TYPES.SECTION}>Have a specific need?</Heading>
						<p>Reach out to us about certain needs by calling these numbers.</p>
					</header>
					<div className='contact-info'>
						{ContactInformation.map((contact, cidx) => (
							<Contact key={cidx}>
								<Heading type={TYPES.INFO}>{contact.title}</Heading>
								{contact.info.map((info, iidx) => (
									<p key={iidx}>{ReactHtmlParser(info)}</p>
								))}
							</Contact>
						))}
					</div>
				</ContactSection>
				<ContactSection>
					{formSubmitted ? (
						<PSMWrap>
							<p>Thank you for contacting us.</p>
							<p>We’ll address your concern or request as soon as possible.</p>
						</PSMWrap>
					) : (
						<>
							<header>
								<Heading type={TYPES.SECTION}>Have a question?</Heading>
								<p>
									Send us a message, and we’ll get back to you with an answer.
								</p>
							</header>
							<ContactForm postSubmissionHandler={postSubmitHandler} />
						</>
					)}
				</ContactSection>
			</div>
			<Footer />
		</React.Fragment>
	);
}

export default ContactUs;
