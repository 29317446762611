export const ACTION_TYPES = {
	SET_PATH_DOWNLOAD_URL: '_SET_PATH_DOWNLOAD_URL',
};

const initialState = {
	pathDownloadUrls: {},
};

export default function alertsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_PATH_DOWNLOAD_URL:
			return {
				...state,
				pathDownloadUrls: {
					...state.pathDownloadUrls,
					[action.payload.path]: action.payload.downloadUrl,
				},
			};
		default:
			return state;
	}
}
