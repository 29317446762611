import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import cloudStorageReducer from './cloudStorageReducer';
import assetDocsReducer from './assetDocsReducer';
import userTutorialReducer from './userTutorialReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import collectionCartReducer from './collectionCartReducer';
import downloadHistoryReducer from './downloadHistoryReducer';

export default combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	cloudStorage: cloudStorageReducer,
	assetDocs: assetDocsReducer,
	userTutorial: userTutorialReducer,
	admin: adminReducer,
	auth: authReducer,
	collectionCart: collectionCartReducer,
	downloadHistory: downloadHistoryReducer,
});
