import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loginWithEmailPass } from '../config/store/actions/auth';
import Login from '../components/_molecules/Login';
import styled from 'styled-components';

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	height: 100%;
	max-height: 660px;
	> * {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const DPLoginPage = (props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const handleEmailPassSubmit = async (email, password) => {
		setLoading(true);
		setError(false);
		const { success, err } = await props.emailPassLogin(email, password);
		if (err) console.log(err);
		setLoading(false);
		setError(!success);
	};

	return (
		<Container>
			<div>
				<Login
					error={error}
					loading={loading}
					nonSSOHandler={handleEmailPassSubmit}
				/>
			</div>
		</Container>
	);
};

const mapDispatchToProps = (dispatch) => ({
	emailPassLogin: (email, pass) => dispatch(loginWithEmailPass(email, pass)),
});

export default connect(null, mapDispatchToProps)(DPLoginPage);
