import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { populatePathDownloadUrl } from '../../config/store/actions/cloudStorage';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import ProgressiveImg from './ProgressiveImg';

const getTinyPath = (path) => {
	let pos = path.lastIndexOf('/') + 1;
	const tinyPath = [path.slice(0, pos), 'tiny--', path.slice(pos)].join('');
	return tinyPath;
};

const CloudStorageImg = ({
	path,
	finalSrc,
	finalTinySrc,
	loading,
	imgProps,
	populate,
	cover,
}) => {
	const [tinyLoaded, setTinyLoaded] = useState(false);

	useEffect(() => {
		if (!path) return;
		if (finalTinySrc && finalSrc) return;
		populate();
	}, [finalSrc, populate, path, finalTinySrc]);

	return (
		<>
			{!loading && finalTinySrc && finalSrc && (
				<ProgressiveImg
					{...imgProps}
					src={finalSrc}
					previewSrc={finalTinySrc}
					onTinyLoaded={() => setTinyLoaded(true)}
					hide={!tinyLoaded}
					cover={cover}
				/>
			)}
			{(loading || !tinyLoaded) && <Skeleton className='img-skeleton' />}
		</>
	);
};

CloudStorageImg.defaultProps = {
	imgProps: {},
};

CloudStorageImg.propTypes = {
	/** The path to the image resource in the cloud storage bucket */
	path: PropTypes.string,
	/** Props to pass to the internal img component. src can be used as a fallback */
	imgProps: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
	finalSrc: ownProps.path
		? state.cloudStorage.pathDownloadUrls[ownProps.path]
		: null,
	finalTinySrc: ownProps.path
		? state.cloudStorage.pathDownloadUrls[getTinyPath(ownProps.path)]
		: null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	populate: () => {
		dispatch(populatePathDownloadUrl(getTinyPath(ownProps.path)));
		dispatch(populatePathDownloadUrl(ownProps.path));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudStorageImg);
