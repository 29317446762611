import { ACTION_TYPES } from '../reducers/userTutorialReducer';

const _setShow = (show: boolean, dispatch) => {
	dispatch({
		type: ACTION_TYPES.SET_SHOW,
		payload: show,
	});
};

export const setShow = (show: boolean) => {
	return async (dispatch, _getState, getFirebase) => {
		_setShow(show, dispatch);
		if (!show) {
			const f = getFirebase()
				.functions()
				.httpsCallable('users-seenTutorialVersion');
			await f();
		}
	};
};
