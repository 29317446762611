export const ACTION_TYPES = {
	ADD_PAGE: '_DOWNLOAD_HISTORY_ADD_PAGE',
	SET_NO_MORE: '_DOWNLOAD_HISTORY_SET_NO_MORE',
	CLEAR_PAGES: '_DOWNLOAD_HISTORY_CLEAR_PAGES',
};

const initialState = {
	pages: [],
	noMore: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_NO_MORE:
			return {
				...state,
				noMore: action.payload,
			};
		case ACTION_TYPES.ADD_PAGE:
			return {
				...state,
				pages: [...state.pages, action.payload],
			};
		case ACTION_TYPES.CLEAR_PAGES:
			return {
				...state,
				pages: initialState.pages,
				noMore: false,
			};
		default:
			return state;
	}
};
