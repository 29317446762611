/* eslint-disable indent */
import { ExtendedFirebaseInstance } from 'react-redux-firebase';
import { ACTION_TYPES } from '../reducers/cloudStorageReducer';
import TagManager from 'react-gtm-module';

export const populatePathDownloadUrl = (path: string) => {
	return (
		dispatch: any,
		getState: any,
		getFirebase: () => ExtendedFirebaseInstance
	) => {
		const cached = getState().cloudStorage.pathDownloadUrls[path];
		if (cached) return cached;
		if (path.startsWith('http')) {
			dispatch({
				type: ACTION_TYPES.SET_PATH_DOWNLOAD_URL,
				payload: {
					path,
					downloadUrl: path,
				},
			});
			return path;
		}

		const firebase = getFirebase();
		const storageRef = firebase.storage().ref();
		storageRef
			.child(path)
			.getDownloadURL()
			.then((downloadUrl) => {
				dispatch({
					type: ACTION_TYPES.SET_PATH_DOWNLOAD_URL,
					payload: {
						path,
						downloadUrl,
					},
				});
				return downloadUrl;
			})
			// eslint-disable-next-line no-unused-vars
			.catch((_err) => {
				dispatch({
					type: ACTION_TYPES.SET_PATH_DOWNLOAD_URL,
					payload: {
						path,
						downloadUrl: 'none',
					},
				});
			});
	};
};

type DL_File_Options = {
	open?: boolean;
	verifyPathId?: string;
	doNotRecord?: boolean;
};

const _recordDownloadEvent = async (assetId: string, firebase: any) => {
	try {
		const f = firebase
			.functions()
			.httpsCallable('assetDocs-handleDownloadEvent');
		const data = { assetId: assetId };
		const res = await f(data);
		if (res?.data !== 'SUCCESS') console.error(res);
	} catch (e) {
		console.error((e as any).message);
	}
};

export const downloadFile = (path, options: DL_File_Options = {}) => {
	return async (
		_dispatch: any,
		_getState: any,
		getFirebase: () => ExtendedFirebaseInstance
	) => {
		const { open = false, verifyPathId, doNotRecord = false } = options;

		const firebase = getFirebase();
		const storageRef = firebase.storage().ref();

		if (verifyPathId) {
			const db = getFirebase().firestore();
			const doc = await db.doc(`assets/${verifyPathId}`).get();
			path = doc?.data()?.metadata?.path;
			if (!path) {
				console.error(`Could not verify path of asset ${verifyPathId}`);
				return;
			}
		}

		const metadata = await storageRef.child(path).getMetadata();

		if (!doNotRecord && !open) {
			const assetId = metadata?.customMetadata?.assetId;
			if (assetId) await _recordDownloadEvent(assetId, getFirebase());
		}

		let fileName = 'asset';
		if (metadata.customMetadata && metadata.customMetadata.fileName)
			fileName = metadata.customMetadata.fileName;

		const downloadUrl = await storageRef.child(path).getDownloadURL();

		const a = document.createElement('a');
		if (open) {
			a.target = '_blank';
		} else {
			a.download = fileName;
		}
		a.href = downloadUrl;
		a.click();
		if (a.remove) {
			a.remove();
		} else {
			const parent = a.parentNode;
			// eslint-disable-next-line
			parent?.removeChild(a);
		}

		if (open) return;

		// Send download event. Attach filter, sort, and search data if it exists.
		try {
			const { filters, query, sort } = _getState().assetDocs.filterSort;

			// Push download event to GTM
			TagManager.dataLayer({
				dataLayer: {
					event: 'download_asset',
					asset_name: fileName,
					sort_applied: sort?.value,
					search_term: query?.slice(0, query?.length - 1),
					partial_search_term:
						query && query[query.length - 1]
							? query[query.length - 1]
							: undefined,
					filters_applied: ((obj) => {
						if (!obj || !Object.keys(obj).length) return [];
						return Object.keys(obj)
							.map((key) => {
								return obj[key].map((val) => {
									const res = {};
									res[key] = val;
									return JSON.stringify(res);
								});
							})
							.reduce((a, b) => a.concat(b));
					})(filters?.noCatPrefix),
				},
				dataLayerName: 'dataLayer',
			});
		} catch (e) {
			console.error(e);
		}
	};
};
