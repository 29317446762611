import React from 'react';
import styled from 'styled-components';
import Logo from './Logo.svg';
import { connect, useDispatch } from 'react-redux';
import { logOut } from '../../../config/store/actions/auth';
import { resetFilterSort } from '../../../config/store/actions/assetDocs';
import { Link, useLocation } from 'react-router-dom';
import Routes from '../../../pages';
import NavIcon from '../../_atoms/NavIcon';

// Navigation in the header
const HeaderNavWrapper = styled.nav`
	display: flex;
	align-items: center;

	a,
	button,
	.dlrcode {
		font-family: 'Saira Extra Condensed', sans-serif;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: 0.08em;
	}

	a,
	button {
		cursor: pointer;
	}

	> a:not(:last-child),
	button:not(:last-child) {
		margin-right: 25px;
	}

	.dlrcode {
		color: #0067b1;
		margin-right: 18px;
	}

	a:hover,
	button:hover {
		color: #0072ce;
	}

	> div {
		height: 50px;
		border-left: 1px solid #aaa;
		padding-left: 19px;
		display: flex;
		align-items: center;
	}
`;

class Nav extends React.Component {
	constructor() {
		super();
		this.profilettonClickHandler = this.profileButtonClickHandler.bind(this);
		this.handleLogout = this.handleLogout.bind(this);

		this.state = {
			menuOpen: true,
		};
	}

	handleLogout() {
		this.props.logOut();
	}

	profileButtonClickHandler() {
		this.setState({ menuOpen: !this.state.menuOpen });
	}

	render() {
		// hide navigation if user isn't logged in.
		if (!this.props.uid) return null;
		return (
			<HeaderNavWrapper>
				{this.props.children}
				<div>
					<NavIcon
						href={Routes.downloadHistory.path}
						title={Routes.downloadHistory.title}
						type='downloadHistory'
					/>
					<NavIcon
						href={Routes.collection.path}
						title={Routes.collection.title}
						type='collection'
						notifications={this.props.collectionCartCount}
					/>
					{this.props.dlrcode ? (
						<p className='dlrcode'>dealer code {this.props.dlrcode}</p>
					) : null}
					<button onClick={this.handleLogout}>Log out</button>
				</div>
			</HeaderNavWrapper>
		);
	}
}

// Header Component
const HeaderWrapper = styled.header`
	min-height: 108px;
	border-bottom: 1px solid #aaa;
	box-sizing: border-box;
	padding: 23px 31px;
	display: flex;
	align-items: center;
	> a {
		margin-right: auto;
	}
`;

function Header(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const onLogoClick = () => {
		if (location.pathname !== '/') return;
		dispatch(resetFilterSort());
	};

	return (
		<HeaderWrapper>
			<Link to='/' title='Mopar Accessory Portal' onClick={onLogoClick}>
				<img src={Logo} alt='' />
			</Link>
			{props.children}
		</HeaderWrapper>
	);
}

const mapStateToProps = (state) => ({
	uid: state.firebase.auth.uid,
	dlrcode: state.firebase.profile && state.firebase.profile.dealerCode,
	collectionCartCount:
		state.collectionCart.list && state.collectionCart.list.length,
});

const mapDispatchToProps = (dispatch) => ({
	logOut: () => dispatch(logOut()),
});

const HeaderNav = connect(mapStateToProps, mapDispatchToProps)(Nav);

export default Header;
export { HeaderNav };
