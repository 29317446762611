import React, { useState, useMemo } from 'react';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import AssetListing, {
	ListingTypes,
} from '../components/_organisms/AssetListing';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
	collectionCartRemove,
	joinAssets,
} from '../config/store/actions/collectionCart';
import Button from '../components/_atoms/Button';
import { downloadFile } from '../config/store/actions/cloudStorage';

const CollectionAssetContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(4, 1fr);
	justify-items: center;
	row-gap: 50px;
	column-gap: 30px;
	padding: 30px 30px 0;
	box-sizing: border-box;
`;

const ActionBar = styled.div`
	background-color: #dcdcdc;
	box-sizing: border-box;
	padding-top: 37px;
	padding-bottom: 38px;
	display: flex;
	justify-content: center;
	margin-top: 50px;
	button {
		max-width: 304px;
		margin: 0 16px;
	}
`;

const _timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function MyCollection({ downloadFile, collectionCartRemove }) {
	const joinedAssets = useSelector((state) => state.collectionCart.assetJoins);
	const assetsList = useSelector((state) => state.collectionCart.list);

	const dispatch = useDispatch();

	const joinMore = () => {
		dispatch(joinAssets(6));
	};

	const removeFromCollection = async (id, fromDownload = false) => {
		_removeFromSelected(id);
		await collectionCartRemove(id, fromDownload);
	};

	const [selectedAssets, updateSelectedAssets] = useState([]);

	const _removeFromSelected = (id) => {
		updateSelectedAssets((prev) => {
			let _tmp = [...prev];
			return _tmp.filter((v) => v !== id);
		});
	};

	const onAssetSelect = (id) => {
		let _tmp = [...selectedAssets];
		if (_tmp.length < 1) _tmp = [id];
		else {
			if (_tmp.includes(id)) {
				_tmp = _tmp.filter((v) => v !== id);
			} else {
				_tmp.push(id);
			}
		}
		updateSelectedAssets(_tmp);
	};

	const _multiDownload = async (assetsArr = []) => {
		for (const id of assetsArr) {
			// This doesn't await the actual completion of the file download
			// Just the metadata transfer/handshake w/ Cloud Storage
			await downloadFile(`assets/${id}/file`, {
				verifyPathId: id,
			});
			await removeFromCollection(id, true);
			// Additional throttling just in case
			await _timeout(150);
		}
	};

	const downloadAll = () => {
		_multiDownload(assetsList);
	};

	const downloadSelected = () => {
		_multiDownload(selectedAssets);
	};

	const joinedSorted = useMemo(() => {
		return Object.keys(joinedAssets)
			.filter((key) => {
				return assetsList.includes(key);
			})
			.sort((a, b) => {
				return assetsList.indexOf(a) - assetsList.indexOf(b);
			})
			.map((key) => joinedAssets[key])
			.filter((v) => v !== 'NOT_FOUND');
	}, [assetsList, joinedAssets]);

	return (
		<React.Fragment>
			<Hero size='sm'>
				<h1>My Collection</h1>
			</Hero>
			<InfiniteScroll
				threshold={600}
				pageStart={0}
				loadMore={joinMore}
				hasMore={Object.keys(joinedAssets).length < assetsList.length}
				initialLoad={true}
				loader={
					<CollectionAssetContainer>
						{[null, null, null].map((_, key) => {
							return (
								<AssetListing
									key={key}
									data={{ name: 'LOADING...' }}
									type={ListingTypes.COLLECTION}
									loading={true}
								/>
							);
						})}
					</CollectionAssetContainer>
				}
			>
				<CollectionAssetContainer>
					{joinedSorted.map((data, key) => {
						return (
							<AssetListing
								selected={selectedAssets && selectedAssets.includes(data.id)}
								type={ListingTypes.COLLECTION}
								data={data}
								key={key}
								removeHandler={() => {
									removeFromCollection(data.id);
								}}
								selectHandler={() => {
									onAssetSelect(data.id);
								}}
							/>
						);
					})}
				</CollectionAssetContainer>
			</InfiniteScroll>
			<ActionBar>
				<Button
					disabled={selectedAssets.length === 0}
					onClick={downloadSelected}
				>
					Download Selected ({selectedAssets.length})
				</Button>
				<Button variant='alternate' onClick={downloadAll}>
					Download All
				</Button>
			</ActionBar>
			<Footer />
		</React.Fragment>
	);
}

const mapDispatchToProps = (dispatch) => ({
	downloadFile: (id, options = {}) => dispatch(downloadFile(id, options)),
	collectionCartRemove: (id, fromDownload = false) =>
		dispatch(collectionCartRemove(id, fromDownload)),
});

export default connect(null, mapDispatchToProps)(MyCollection);
