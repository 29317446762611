import React from 'react';
import styled from 'styled-components';
import CTANavButton from '../../_atoms/CTANavButton';

import Routes from '../../../pages';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	background-color: #000000;
	padding: 30px;
	box-sizing: border-box;
	min-height: 420px;
	align-content: space-between;
	max-width: 333px;
	flex-basis: 333px;
	width: 333px;
`;

const Divider = styled.hr`
	border-top: 1px solid #ffffff;
	margin-top: 28px;
	margin-bottom: 28px;
	width: 100%;
	opacity: 1;
`;

function CTANav() {
	return (
		<Container>
			<CTANavButton
				href={Routes.whatsHot.path}
				title={Routes.whatsHot.title}
				type='hot'
			/>
			<Divider />
			<CTANavButton
				outbound
				title='Accessory Rewards'
				href='https://www.dealers-mopar.com/moparone/accessory/accessory-rewards.action?main'
				type='rewards'
			/>
			<CTANavButton
				outbound
				href='/custom-shop'
				title='Custom Shop Assets'
				type='custom-shop'
			/>
			<CTANavButton
				href='/external-resources'
				title='External Resources'
				type='external'
			/>
		</Container>
	);
}

export default CTANav;
