function TitleTruncator(input) {
	const charLimit = 60;
	const truncationSymbol = '...';
	// if character length <= charLimit then return unchanged
	if (input.length <= charLimit) return input;

	let reduced = input.substr(0, charLimit - truncationSymbol.length);
	const el = `<span title='${input}'>${reduced}${truncationSymbol}</span>`;

	return el;
}

export default TitleTruncator;
