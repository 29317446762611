import React from 'react';
import styled from 'styled-components';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import Heading, { HEADING_TYPES } from '../components/_atoms/Heading';
import Button from '../components/_atoms/Button';
import FAQSection from '../components/_molecules/FAQSection';

import FAQData from '../data/faq';

const Wrap = styled.div`
	box-sizing: border-box;
	padding-top: 65px;
	width: 640px;
	margin: 0 auto 60px;
	> header {
		text-align: center;
		border-bottom: 1px solid #000000;
		padding-bottom: 54px;
		margin-bottom: 54px;

		a {
			margin-top: 20px;
		}
	}

	> section:not(:last-of-type) {
		margin-bottom: 50px;
	}
`;

function Faq() {
	return (
		<React.Fragment>
			<Hero>
				<h1>frequently asked questions</h1>
				<p>
					Have a question? You’ve come to the right place. Search our frequently
					asked questions below for quick answers to general inquiries.
				</p>
			</Hero>
			<Wrap>
				<header>
					<Heading type={HEADING_TYPES.ASSET}>
						BEFORE YOU BEGIN, CHECK OUT OUR COMPREHENSIVE HOW-TO GUIDE:
					</Heading>
					<Button
						rel='noopener noreferrer'
						target='_blank'
						href={`${process.env.PUBLIC_URL}/assets/Mopar-Accessory-Portal-How-To-Guide.pdf`}
						el='a'
						type='preview'
					>
						View
					</Button>
				</header>
				{Object.keys(FAQData).map((k, idx) => (
					<FAQSection key={idx} data={FAQData[k]} />
				))}
			</Wrap>
			<Footer />
		</React.Fragment>
	);
}

export default Faq;
