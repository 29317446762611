import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	fillToPage,
	clearAllPages,
	PageItem,
} from '../config/store/actions/downloadHistory';

const useDownloadHistory = () => {
	const { totalDownloadCount, uniqueDownloadCount } = useSelector(
		(state: any) => state.firebase.profile
	);
	const { pages, noMore } = useSelector(
		(state: any) => state.downloadHistory
	) as {
		pages: PageItem[][];
		noMore: boolean;
	};

	const dispatch = useDispatch();

	const fillTo = useCallback(
		(count: number) => {
			dispatch(fillToPage(count));
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(clearAllPages());
	}, [totalDownloadCount, dispatch]);

	const availablePagesCount = noMore
		? pages.length
		: Math.ceil(uniqueDownloadCount / 10);

	return {
		pages,
		noMore,
		totalDownloadCount,
		uniqueDownloadCount,
		fillTo,
		availablePagesCount,
	};
};

export default useDownloadHistory;
