import React, { useState } from 'react';
import ControlledAssetResults from '../components/ControlledAssetResults';
import Footer from '../components/_atoms/Footer';
import Hero from '../components/_molecules/Hero';
import useSearch from '../hooks/useSearch';
import AssetPreview from '../components/_organisms/AssetPreview';

const CustomShop = () => {
	const { pages, loadMore, hasMore, loading } = useSearch('', {
		filters: {
			fieldEquality: {
				'categories.moparCustomShop': ['referenceGuides', 'heroCards'],
			},
		},
	});
	// preview
	const [isPreviewing, setPreview] = useState(false);
	// preview data
	const [previewData, setPreviewData] = useState({});
	// open preview
	const openPreview = (data) => {
		setPreviewData(data);
		setPreview(true);
	};
	return (
		<>
			{isPreviewing ? (
				<AssetPreview
					onCloseHandler={() => setPreview(false)}
					data={previewData}
				/>
			) : null}
			<Hero bg='custom-shop-hero-background.jpg'>
				<h1>Custom Shop</h1>
				<p>
					Increase your sales with our custom upfit packages! <br /> Click to
					download the guides here, and give every customer <br /> their own
					unique ride for the open road.
				</p>
			</Hero>
			<ControlledAssetResults
				openPreviewHandler={openPreview}
				noMore={!hasMore}
				loadMore={loadMore}
				resultPages={pages}
				loading={loading}
				titleOnly
				columns={4}
			/>
			<Footer />
		</>
	);
};

export default CustomShop;
