import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import AccessoryAssetContainer from '../components/_organisms/AccessoryAssetContainer/AccessoryAssetContainer';
import { ListingTypes } from '../components/_organisms/AssetListing/AssetListing';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import AssetPreview from '../components/_organisms/AssetPreview/AssetPreview';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';

const PAGE_SIZE = 12;

const HotItemsPage = ({ pageNumber, openPreviewHandler }) => {
	const prevPage = useSelector(
		(state) => state.firestore.ordered[`hotAssets_all_${pageNumber - 1}`]
	);
	const lastItem = prevPage?.[prevPage.length - 1];
	useFirestoreConnect(() => [
		{
			collection: 'assets',
			where: [
				['status', '==', 'live'],
				['hotAsset', '==', true],
			],
			orderBy: [
				['hotAssetOrder', 'desc'],
				['__name__', 'desc'],
			],
			storeAs: `hotAssets_all_${pageNumber}`,
			startAfter: lastItem
				? [lastItem.hotAssetOrder ?? 0, lastItem.id]
				: undefined,
			limit: PAGE_SIZE,
		},
	]);
	const data = useSelector(
		(state) => state.firestore.ordered[`hotAssets_all_${pageNumber}`]
	);

	if (!data) return null;

	return (
		<AccessoryAssetContainer
			data={data}
			listingType={ListingTypes.ACCESSORY}
			columns={4}
			openPreviewHandler={openPreviewHandler}
		/>
	);
};

const Wrap = styled.div`
	margin-bottom: 100px;
`;

export default function WhatsHot() {
	const [pages, setPages] = useState(1);
	const [isPreviewing, setIsPreviewing] = useState(false);
	const [previewData, setPreviewData] = useState({});

	const openPreview = useCallback((data) => {
		setPreviewData(data);
		setIsPreviewing(true);
	}, []);

	const currentPage = useSelector(
		(state) => state.firestore.ordered[`hotAssets_all_${pages - 1}`]
	);

	return (
		<React.Fragment>
			<Wrap>
				{isPreviewing ? (
					<AssetPreview
						onCloseHandler={() => setIsPreviewing(false)}
						data={previewData}
					/>
				) : null}
				<Hero bg='whats-hot-head.jpg'>
					<h1>What&apos;s Hot!</h1>
					<p>
						See what&apos;s hot for internal use within your dealership like
						up-to-date parts lists, custom shop files and more. Assets are
						uploaded regularly, making it easier to understand what&apos;s
						trending so you can better streamline internal processes.
					</p>
				</Hero>
				<InfiniteScroll
					threshold={600}
					pageStart={0}
					loadMore={() => setPages(pages + 1)}
					hasMore={(currentPage?.length ?? 0) === PAGE_SIZE}
					loader={
						<AccessoryAssetContainer
							loading={true}
							listingType={ListingTypes.ACCESSORY}
							columns={3}
							key={0}
						/>
					}
					initialLoad={false}
				>
					{Array.apply(null, Array(pages)).map((_el, idx) => {
						return (
							<HotItemsPage
								key={idx}
								pageNumber={idx}
								openPreviewHandler={openPreview}
							/>
						);
					})}
				</InfiniteScroll>
			</Wrap>
			<Footer />
		</React.Fragment>
	);
}
