import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Wrap = styled.div`
	a {
		position: relative;
		display: block;
		width: 41px;
		height: 41px;
		margin-right: 14px;
	}
`;
const Badge = styled.span`
	font-family: 'Saira Extra Condensed', sans-serif;
	width: 19px;
	height: 19px;
	background: #0067b1;
	border-radius: 50%;
	color: #ffffff;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	top: -2px;
	right: -7px;
	visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

const Svg = ({ type }) => {
	if (type === 'collection') {
		return (
			<svg
				width='48'
				height='43'
				viewBox='0 0 48 43'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle
					cx='20.5'
					cy='22.5'
					r='19.5'
					fill='white'
					stroke='#AAAAAA'
					strokeWidth='2'
				/>
				<rect
					width='15'
					height='24'
					transform='translate(13 11)'
					fill='white'
				/>
				<path
					d='M26.74 32.6096L20.5 29.0432L14.26 32.6096V13.3904H26.74V32.6096Z'
					stroke='#828282'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M14.26 16.8127H26.74'
					stroke='#828282'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		);
	}

	if (type === 'downloadHistory') {
		return (
			<svg
				width='45'
				height='42'
				viewBox='0 0 45 42'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M4.90918 21.4039C4.90918 17.9308 5.80918 14.7 7.44554 11.9539C10.8001 6.21925 17.0183 2.4231 24.1365 2.4231C34.7728 2.4231 43.3637 10.9039 43.3637 21.4039C43.3637 31.9039 34.7728 40.3846 24.1365 40.3846C16.691 40.3846 10.2274 36.1846 7.03645 30.0462'
					stroke='#AAAAAA'
					strokeWidth='2'
					strokeMiterlimit='10'
					strokeLinecap='round'
				/>
				<path
					d='M1.63623 19.3846L4.90896 22.6154L8.18169 19.3846'
					fill='#AAAAAA'
				/>
				<path
					d='M1.63623 19.3846L4.90896 22.6154L8.18169 19.3846H1.63623Z'
					stroke='#AAAAAA'
					strokeWidth='2'
					strokeMiterlimit='10'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M23.7271 10.5V24.15L32.7271 29.8846'
					stroke='#828282'
					strokeWidth='2'
					strokeMiterlimit='10'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		);
	}
};

function NavIcon(props) {
	return (
		<Wrap>
			<Link to={props.href} title={props.title}>
				<Badge hide={!props.notifications}>{props.notifications || 0}</Badge>
				<Svg type={props.type} />
			</Link>
		</Wrap>
	);
}

NavIcon.propTypes = {
	notifications: PropTypes.number,
	type: PropTypes.oneOf(['collection', 'downloadHistory']),
};

export default NavIcon;
