/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import MetaData from '../../_molecules/MetaData';
import CloudStorageImg from '../../_utilities/CloudStorageImg';
import Skeleton from 'react-loading-skeleton';
import { cloneDeep } from 'lodash';
import ParseHTML from 'react-html-parser';
import CloudStorageDownloadButton from '../../_atoms/CloudStorageDownloadButton';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from '../../../config/store/actions/cloudStorage';
import BrandMutator from '../../_utilities/BrandMutator';
import PreviewFlag from './preview-flag.png';
import HotFlagCheck from './hot-flag-check.png';
import HotFlagEye from './hot-flag-eye.png';
import Button from '../../_atoms/Button';
import {
	collectionCartAdd,
	collectionCartRemove,
} from '../../../config/store/actions/collectionCart';
import { useLocation } from 'react-router-dom';
import Routes from '../../../pages';

// asset image
const ItemImage = styled.figure`
	&:before {
		content: '';
		display: block;
		width: 60px;
		height: 60px;
		position: absolute;
		left: -2px;
		top: -2px;
		z-index: 50;
		background: url(${PreviewFlag});
		background-position: top left;
		background-repeat: no-repeat;
	}
	width: 100%;
	height: 200px;
	overflow: hidden;
	margin: 0;
	position: relative;
	cursor: pointer;
	.img-skeleton {
		height: 100%;
	}

	&.hot-check,
	&.hot-eye {
		border: 2px solid #e30514;
	}

	&.hot-check:before {
		background: url(${HotFlagCheck});
	}
	&.hot-eye:before {
		background: url(${HotFlagEye});
	}
`;

// asset header
const ItemHeader = styled.header`
	box-sizing: border-box;
	padding: 23px 0 7px;
	h1 {
		font-family: 'Saira Extra Condensed', sans-serif;
		font-weight: 700;
		font-size: 20px;
		line-height: 31px;
		letter-spacing: 0.03em;
		margin: 0;

		sub {
			bottom: 0;
		}
	}
`;

// asset body
const ItemBody = styled.div`
	margin-bottom: 33px;
	p {
		font-family: 'Saira', sans-serif;
		font-weight: 400;
		line-height: 18px;
		margin: 0;
		font-size: 14px;
	}
`;

// asset footer
const ItemFooter = styled.footer`
	margin-top: auto;
	width: 100%;
	min-height: 50px;
	display: flex;
	justify-content: space-between;
	div {
		width: 48%;
		.button-skeleton {
			width: 100%;
			height: 100%;
		}
	}
`;

const ItemWrap = styled.section`
	width: 100%;
	max-width: 304px;
	display: flex;
	flex-direction: column;

	span.match {
		color: rgba(0, 103, 177, 1);
	}

	.react-loading-skeleton {
		border-radius: 0;
	}

	${({ withEdit, status }) =>
		withEdit
			? status === 'live'
				? 'border: green 1px solid;'
				: 'border: 1px orange solid;'
			: ''};
`;

const showMetadata = ['size', 'dimensions'];

const applyMatchHighlighting = (matchObj, target) => {
	Object.keys(matchObj).forEach((key) => {
		if (!!key && !!matchObj[key] && !!target[key]) {
			if (typeof target[key] === 'object')
				applyMatchHighlighting(matchObj[key], target[key]);
			else if (Array.isArray(target[key])) {
				target[key] = target[key].map((_el, idx) => {
					return ParseHTML(BrandMutator(matchObj[key][idx].value));
				});
			} else target[key] = ParseHTML(BrandMutator(matchObj[key].value));
		}
	});
};

function AccessoryAsset(props) {
	const dispatch = useDispatch();
	const { loading, data, titleOnly } = props;

	const clonedData = cloneDeep(data);

	let parsedName;
	const { _highlightResult } = data || {};
	if (_highlightResult && typeof _highlightResult === 'object') {
		applyMatchHighlighting(_highlightResult, clonedData);
		parsedName = clonedData.name;
	}

	let {
		previewGalleryPaths,
		thumbnailPath,
		name,
		metadata,
		customMetadata,
		id,
		hotAsset,
	} = clonedData || {};

	const allMetadata = {
		...metadata,
		...(customMetadata || {}),
	};

	if (!parsedName) parsedName = ParseHTML(BrandMutator(name));

	const isInCollection = useSelector((state) => state.collectionCart.byId[id]);

	const toggleInCollection = () => {
		if (isInCollection) dispatch(collectionCartRemove(id));
		else dispatch(collectionCartAdd(id));
	};

	const router = useLocation();
	const isHotPage = router.pathname === Routes.whatsHot.path;
	const hotClass = isHotPage ? 'hot-eye' : 'hot-check';

	return (
		<ItemWrap
			withEdit={props.withEdit}
			status={clonedData && clonedData.status}
		>
			<ItemImage
				className={hotAsset ? hotClass : ''}
				onClick={() => {
					if (loading) return;
					if (
						data &&
						data.metadata &&
						data.metadata.contentType === 'application/pdf'
					) {
						dispatch(
							downloadFile(allMetadata && `${allMetadata.path}-forPreview`, {
								open: true,
							})
						);
					} else {
						props.openPreviewHandler({
							id: id,
							title: name,
							longDescription: clonedData.longDescription,
							shortDescription: clonedData.shortDescription,
							path: allMetadata.path,
							preview: previewGalleryPaths,
							meta: {
								fileName: data.metadata.fileName,
								Format: clonedData.displayManualFormat,
								size: allMetadata.size,
								dimensions: clonedData.dimensions,
							},
						});
					}
				}}
			>
				<CloudStorageImg
					loading={loading}
					path={
						thumbnailPath ||
						(previewGalleryPaths ? previewGalleryPaths[0] : null)
					}
				/>
			</ItemImage>
			<ItemHeader>
				<h1>{parsedName || <Skeleton />}</h1>
			</ItemHeader>

			{!titleOnly && (
				<ItemBody>
					{(loading || (clonedData && clonedData.displayCategories)) && (
						<MetaData
							metakey={!loading && 'displayCategories'}
							metavalue={clonedData && clonedData.displayCategories}
							loading={loading}
							loadingValueCount={2}
							loadingValueWidth={'100%'}
						/>
					)}
					{(loading || (clonedData && clonedData.displayManualFormat)) && (
						<MetaData
							metakey={!loading && 'displayManualFormat'}
							metavalue={clonedData && clonedData.displayManualFormat}
							loading={loading}
							loadingValueCount={2}
							loadingValueWidth={'100%'}
						/>
					)}
					{!loading
						? allMetadata &&
						  Object.keys(allMetadata)
								.filter((k) => showMetadata.includes(k))
								.sort(
									(a, b) => showMetadata.indexOf(a) - showMetadata.indexOf(b)
								)
								.map((k, idx) => (
									<MetaData key={idx} metakey={k} metavalue={allMetadata[k]} />
								))
						: Array.apply(null, Array(3)).map((_e, idx) => (
								<MetaData
									key={idx}
									loadingValueCount={1}
									loadingValueWidth={'40%'}
									loading={loading}
								/>
						  ))}

					{(loading || (clonedData && clonedData.dimensions)) && (
						<MetaData
							metakey={!loading && 'dimensions'}
							metavalue={clonedData && clonedData.dimensions}
							loading={loading}
							loadingValueCount={2}
							loadingValueWidth={'100%'}
						/>
					)}
					{(loading || (clonedData && clonedData.shortDescription)) && (
						<MetaData
							metakey={!loading && 'description'}
							metavalue={clonedData && clonedData.shortDescription}
							loading={loading}
							loadingValueCount={2}
							loadingValueWidth={'100%'}
						/>
					)}
				</ItemBody>
			)}

			<ItemFooter openPreviewHandler={props.openPreviewHandler}>
				{loading ? null : (
					<>
						<CloudStorageDownloadButton path={allMetadata.path}>
							Download
						</CloudStorageDownloadButton>
						{!isInCollection ? (
							<Button
								onClick={toggleInCollection}
								variant='dark'
								type='collection-add'
								el='a'
							>
								Add
							</Button>
						) : (
							<Button
								onClick={toggleInCollection}
								variant='light'
								type='collection-remove'
								el='a'
							>
								Remove
							</Button>
						)}
					</>
				)}
			</ItemFooter>
			{props.withEdit && (
				<ItemBody>
					<MetaData
						loading={loading}
						metakey={'status'}
						metavalue={clonedData.status}
					/>
					<MetaData
						loading={loading}
						metakey={'id'}
						metavalue={clonedData.id}
					/>
					<Link to={`/edit-asset/${clonedData.id}`}>Edit</Link>
				</ItemBody>
			)}
		</ItemWrap>
	);
}

export default AccessoryAsset;
