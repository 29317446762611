import matchAll from 'string.prototype.matchall';
matchAll.shim();

function BrandMutator(s) {
	if (!s) return s;
	// search results regex
	const searchRegx = new RegExp(
		/<span class=['|"]match['|"]>(.*?)<\/span>/,
		'gm'
	);

	let matches;
	try {
		// search results matches
		matches = [...s.matchAll(searchRegx)];
		// remove all of the spans put in place by search results
		if (matches.length > 0) {
			matches.forEach((v) => {
				// console.log(v);
				s = s.replace(v[0], `#${v[1]}`);
			});
		}
	} catch (error) {
		// console.log(error);
	}

	// brand name and rball regex
	const regex = new RegExp(/(Jeep|Mopar|Fiat|®)/, 'g');
	// transforms based on the match
	s = s.replace(regex, (match) => {
		switch (match) {
			case 'Fiat':
				return `${match}<sup>&reg;</sup>`;
			case '®':
				return '';
			default:
				return `${match}<sub>&reg;</sub>`;
		}
	});

	// loop through the matches and re-add the search spans
	if (matches) {
		matches.forEach((v) => {
			let match = `#${v[1]}`;
			s = s.replace(match, `<span class="match">${v[1]}</span>`);
		});
	}

	return s;
}

export default BrandMutator;
