import { useEffect, useState } from 'react';

type Range = {
	min: number;
	max: number;
};

const bindValueToRange = (value: number, range: Range) => {
	if (value > range.max) return range.max;
	if (value < range.min) return range.min;
	return value;
};

const useRangeState = (initial: number, range: Range) => {
	const [state, setState] = useState(initial);

	useEffect(() => {
		const bound = bindValueToRange(state, range);
		if (bound !== state) setState(bound);
	}, [state, setState, range]);

	return [state, setState] as [number, typeof setState];
};

export default useRangeState;
