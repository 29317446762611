import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	html,body,main,#root{height:100%;}
	body{
		font-family: 'Saira', sans-serif;

		/* lock max-width to 1366px */
		max-width: 1366px;
		margin-left: auto;
		margin-right: auto;
	}

	#root{
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	a{
		color: inherit;
		text-decoration: none;
	}

	button{
		outline:none;
		background: none;
		border: none;
	}

	img{
		vertical-align:bottom;
	}
	
`;

export default GlobalStyle;
