export const ACTION_TYPES = {
	SET_SHOW: '_USER_TUTORIAL_SET_SHOW',
};

const initialState = {
	show: false,
};

export default function userTutorialReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_SHOW:
			return {
				...state,
				show: action.payload,
			};
		default:
			return state;
	}
}
