import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DownloadIcon from './icon-download.svg';
import PreviewIcon from './icon-preview.svg';
import RemoveIcon from './icon-remove.svg';

function getButtonColorsByVariant(variant) {
	switch (variant) {
		case 'dark':
			return { normal: '#000000', hover: '#000000' };
		case 'alternate':
			return { normal: '#0067B1', hover: '#005C9D' };
		case 'light':
			return { normal: '#aaaaaa', hover: '#aaaaaa' };
		default:
			return { normal: '#4D6771', hover: '#293E48' };
	}
}

const Wrapper = styled.button`
	color: #ffffff;
	font-family: 'Saira', sans-serif;
	font-size: 16px;
	font-weight: 500;

	display: ${(props) => (props.el === 'a' ? 'inline-flex' : 'block')};
	justify-content: center;
	align-items: center;
	border-radius: 0;
	height: 50px;
	min-width: ${(props) => (props.el === 'a' ? '0' : '304px')};
	width: ${(props) => (props.el === 'a' ? '150px' : '100%')};
	border: none;

	background: ${(props) => getButtonColorsByVariant(props.variant).normal};
	&:hover {
		background: ${(props) => getButtonColorsByVariant(props.variant).hover};
	}

	transition: background 250ms ease-out, opacity 250ms ease-out;
	padding: 0 12px;
	cursor: pointer;
	text-align: center;
	line-height: 50px;
	box-sizing: border-box;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

	${(props) =>
		props.disabled &&
		`
		opacity: 0.5;
		pointer-events: none;
	`}

	img {
		margin-right: 10px;
	}
`;

function Button(props) {
	const getIcon = (type) => {
		switch (type) {
			case 'download':
				return DownloadIcon;
			case 'preview':
			case 'collection-add':
				return PreviewIcon;
			case 'collection-remove':
				return RemoveIcon;
			default:
				return null;
		}
	};

	return (
		<Wrapper as={props.el} {...props}>
			{props.type !== 'default' ? (
				<img src={getIcon(props.type)} alt={`${props.type} icon`} />
			) : null}
			{props.children}
		</Wrapper>
	);
}

Button.propTypes = {
	type: PropTypes.oneOf([
		'default',
		'download',
		'preview',
		'collection-add',
		'collection-remove',
	]),
	el: PropTypes.oneOf(['a', 'button']),
	variant: PropTypes.oneOf(['default', 'alternate', 'dark', 'light']),
};

Button.defaultProps = {
	type: 'default',
	el: 'button',
	variant: 'default',
};

export default Button;
