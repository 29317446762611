import React from 'react';
import styled from 'styled-components';

const Wrap = styled.footer`
	background-color: #000000;
	color: #ffffff;
	box-sizing: border-box;
	padding: 40px 30px;

	font-family: 'Saira', sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	> p {
		margin: 0;
	}
`;

function Footer() {
	let currentYear = new Date().getFullYear();
	return (
		<Wrap>
			<p>©{currentYear} FCA US LLC. All Rights Reserved.</p>
			<p>
				Chrysler, Dodge, Jeep, Ram, Mopar and SRT are registered trademarks of
				FCA US LLC.
			</p>
			<p>
				FIAT and ALFA ROMEO are registered trademarks of FCA Group Marketing
				S.p.A., used with permission.
			</p>
		</Wrap>
	);
}

export default Footer;
