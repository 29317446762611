import React from 'react';
import styled from 'styled-components';

// tooltip button
const Button = styled.a`
	font-family: 'Saira', sans-serif;
	font-weight: 600;
	font-size: 9px;
	line-height: 14px;
	color: #0067b1;
	background: #ffffff;
	width: 12px;
	height: 12px;
	border: 0;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	/* common for arrow and tooltip bubble */
	&:before,
	&:after
	{
		box-sizing: border-box;
		bottom: 100%;
		position: absolute;
		transition: opacity 250ms ease-out;
		visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
		opacity: ${(props) => (props.open ? 1 : 0)};
		z-index: 1001;
	}

	/* directional arrow */
	&:before
	{
		content: "";
		display: block;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 12px solid #000000;
	}
	/* tooltip body */
	&:after
	{
		content: "${(props) => props.tooltip}";
		background-color: #000000;
		border-radius: 5px;
		width: 130px;
		padding: 8px;
		margin: 0;
		color: #ffffff;
		font-weight: 300;
		left: 50%;
		transform: translateX(-50%);
		/* margin-bottom should be height of arrow */
		margin-bottom: 12px;
	}
`;

class Tooltip extends React.Component {
	constructor() {
		super();

		this.state = {
			showingTip: false,
		};

		// auto close timeout
		this._autoCloseTimer = undefined;

		// bind toggleTooltip
		this._toggleTooltip = this._toggleTooltip.bind(this);

		// bind tooltipEventHandler
		this.tooltipEventHandler = this.tooltipEventHandler.bind(this);

		// add event listeners to close the tooltip if
		// the window is scrolled or resized
		window.addEventListener('scroll', () => {
			this._toggleTooltip(false);
		});
		window.addEventListener('resize', () => {
			this._toggleTooltip(false);
		});
	}

	/**
	 * sets a timeout or clears the existing timeout
	 * @param {bool} clearTimer whether to clear the timer or not
	 */
	_toggleTimer(clearTimer) {
		if (clearTimer) {
			clearTimeout(this._autoCloseTimer);
		} else {
			this._autoCloseTimer = setTimeout(() => {
				this.setState({ showingTip: false });
			}, 1000);
		}
	}

	/**
	 * handles opening/closing the flyout menu
	 * also will clear the autoCloseTimer if closing the menu
	 * @param {bool} openTooltip whether to open the menu or not
	 */
	_toggleTooltip(openTooltip) {
		// if we're closing the menu (showingTip==false)
		// then we'll clear the timer
		if (!openTooltip) this._toggleTimer(true);
		this.setState({ showingTip: openTooltip });
	}

	/**
	 * handles the events for the tooltip button
	 * @param {event} e the event
	 */
	tooltipEventHandler(e) {
		let _open = this.state.showingTip;
		switch (e.type) {
			case 'mouseenter':
				// if the menu is open and we're over
				// the element, we are clearing the timer
				if (_open) this._toggleTimer(true);
				break;
			case 'mouseleave':
				// if the menu is open we're not over
				// the element, we are setting a timer
				if (_open) this._toggleTimer(false);
				break;
			default:
				// click will toggle the menu open or close
				this._toggleTooltip(!_open);
				break;
		}
	}

	render() {
		return (
			<Button
				className='tip'
				open={this.state.showingTip}
				tooltip={this.props.tip}
				onClick={this.tooltipEventHandler}
				onMouseEnter={this.tooltipEventHandler}
				onMouseLeave={this.tooltipEventHandler}
			>
				?
			</Button>
		);
	}
}

export default Tooltip;
