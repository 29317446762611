import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Hero from '../components/_molecules/Hero';
import Footer from '../components/_atoms/Footer';
import firebase from 'firebase';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import Button from '@inovua/reactdatagrid-community/packages/Button';
import { read, utils } from 'xlsx';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import { Progress, Select } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';

const Wrap = styled.div`
	box-sizing: border-box;
	padding-top: 65px;
	width: 90%;
	margin: 0 auto 60px;
	> header {
		text-align: center;
		border-bottom: 1px solid #000000;
		padding-bottom: 54px;
		margin-bottom: 54px;

		a {
			margin-top: 20px;
		}
	}

	> section:not(:last-of-type) {
		margin-bottom: 50px;
	}
`;

const PartChanges = (props) => {
	const uploadId = props.match.params.id;
	const [gridRef, setGridRef] = useState(null);
	const [uploadProgress, setUploadProgress] = useState();
	const [dataDifference, setDataDifference] = useState();
	const [firebaseFiles, setFirebaseFiles] = useState();
	const [selectedFile, setSelectedFile] = useState();
	const [fileData, setFileData] = useState();
	const [file, setFile] = useState();
	const [fullData, setFullData] = useState();
	const [isCalculating, setIsCalculating] = useState();
	const [filterColumns, setFilterColumns] = useState();

	const filterTypes = Object.assign(
		{},
		ReactDataGrid.defaultProps.filterTypes,
		{
			partGroup: {
				name: 'partGroup',
				operators: [
					{
						name: 'partGroupContain',
						fn: ({ value, filterValue }) => {
							if (filterValue) {
								var hasValue = false;
								for (var i = 0; i < filterValue.length; i++) {
									if (value.includes(filterValue[i])) {
										hasValue = true;
										break;
									}
								}
								return hasValue;
							} else {
								return true;
							}
						},
					},
				],
			},
		}
	);

	const defaultFilterValue = [
		{
			name: 'partGroup',
			operator: 'partGroupContain',
			type: 'partGroup',
			value: null,
		},
	];

	useEffect(() => {
		if (uploadId !== undefined) {
			forTableFill(uploadId);
		}
		getDropdownData();
	}, [uploadId]);

	const getDropdownData = async () => {
		const files = [];
		const snapshot = await firebase
			.firestore()
			.collection('partUploads')
			.get();
		snapshot.docs.forEach((item, i) => {
			var data = item.data();
			const fileObj = { key: i, value: data.fileName, text: data.fileName };
			files.push(fileObj);
		});
		setFirebaseFiles(files);
	};

	const groups = [
		{
			name: 'Part Number',
			header: 'Part Number',
			headerAlign: 'center',
		},
		{
			name: 'Group Name',
			header: 'Group Name',
			headerAlign: 'center',
		},
		{
			name: 'List Price',
			header: 'List Price',
			headerAlign: 'center',
		},
		{
			name: 'Net Price',
			header: 'Net Price',
			headerAlign: 'center',
		},
	];

	const gridStyle = { minHeight: 550 };

	const columns = [
		{
			name: 'newPartNumber',
			header: 'Part Number',
			group: 'Part Number',
			style: { color: '#ff595e', fontWeight: 'bold' },
			flex: 1,
			textAlign: 'center',
		},
		{
			name: 'groupName',
			header: 'Group Name',
			group: 'Group Name',
			style: { color: '#ff595e', fontWeight: 'bold' },
			flex: 1,
			textAlign: 'center',
			render: ({ value }) =>
				value &&
				value.map((c, i, row) => {
					if (i + 1 === row.length) {
						return c;
					} else {
						return c + ', ';
					}
				}),
		},
		{
			name: 'originalDealerNetPrice',
			header: 'Old',
			group: 'Net Price',
			flex: 1,
			textAlign: 'center',
			style: { color: '#ff595e', fontWeight: 'bold' },
		},
		{
			name: 'newDealerNetPrice',
			header: 'New',
			group: 'Net Price',
			flex: 1,
			textAlign: 'center',
			style: { color: '#7986cb', fontWeight: 'bold' },
		},
		{
			name: 'originalDealerListPrice',
			header: 'Old',
			group: 'List Price',
			flex: 1,
			textAlign: 'center',
			style: { color: '#ff595e', fontWeight: 'bold' },
		},
		{
			name: 'newDealerListPrice',
			header: 'New',
			group: 'List Price',
			flex: 1,
			textAlign: 'center',
			style: { color: '#7986cb', fontWeight: 'bold' },
		},
	];

	const downloadBlob = (blob, fileName = 'grid-data.csv') => {
		const link = document.createElement('a');
		const url = URL.createObjectURL(blob);

		link.setAttribute('href', url);
		link.setAttribute('download', fileName);
		link.style.position = 'absolute';
		link.style.visibility = 'hidden';

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const exportCSV = () => {
		const SEPARATOR = ',';
		const columns = gridRef.current.visibleColumns;

		const header = columns.map((c) => c.name).join(SEPARATOR);
		const rows = gridRef.current.dataSource.map((data) =>
			columns.map((c) => data[c.id]).join(SEPARATOR)
		);

		const contents = [header].concat(rows).join('\n');
		const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

		downloadBlob(blob);
	};

	const exportMasterReport = async () => {
		const storage = firebase.storage();
		const fileRef = storage.ref('files').child(selectedFile);
		const fileURL = await fileRef.getDownloadURL();
		window.open(fileURL);
	};

	useFirestoreConnect([
		{
			collection: 'partUploads',
			orderBy: ['uploadDate', 'desc'],
			storeAs: 'partUploads',
			limit: 1,
		},
	]);

	const partUpload = useSelector(
		(state) => state.firestore.ordered[`partUploads`]
	);

	useFirestoreConnect([
		{
			collection: 'partGroups',
			storeAs: 'partGroups',
		},
	]);

	const partGroups = useSelector(
		(state) => state.firestore.ordered[`partGroups`]
	);

	const formHandler = async (e) => {
		e.preventDefault();
		setIsCalculating(true); // loading modal will show while calculating/uploading
		const storage = firebase.storage();
		const file = e.target[0].files[0];
		setFile(file);
		const data = await file.arrayBuffer();
		const workbook = read(data);

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = utils.sheet_to_json(worksheet);

		//set new data
		const newJsonObject = [];
		jsonData.forEach((item) => {
			var partNoun = item['Part Noun'];
			if (partNoun === undefined) {
				partNoun = '';
			}
			const tempObject = {
				dealerListPrice: item['QP0 - Dealer List Price CURRENT'],
				dealerNetPrice: item['QP1 - Dealer Net Price CURRENT'],
				majorCode: item['Major Code'],
				majorDescription: item['Major Code Description'],
				minorCode: item['Major Code'],
				minorCodeDescription: item['Minor Code Description'],
				partDescription: item['Part Description'],
				partId: item['Part Number'].trim(),
				partNoun: partNoun,
				partNumber: item['Part Number'].trim(),
				pptCode: item['PPT Code Description'],
			};
			newJsonObject.push(tempObject);
		});
		setFullData(newJsonObject);

		//get Old data
		if (partUpload.length > 0) {
			const fileRef = storage.ref('files').child(partUpload[0].fileName);

			const fileURL = await fileRef.getDownloadURL();
			const fetchFile = await (await fetch(fileURL)).arrayBuffer();
			const downloadedWorkbook = read(fetchFile);
			const downloadedWorksheet =
				downloadedWorkbook.Sheets[downloadedWorkbook.SheetNames[0]];
			const oldDataToJson = utils.sheet_to_json(downloadedWorksheet);
			const oldJsonObject = [];
			oldDataToJson.forEach((item) => {
				var partNoun = item['Part Noun'];
				if (partNoun === undefined) {
					partNoun = '';
				}
				const tempObject = {
					dealerListPrice: item['QP0 - Dealer List Price CURRENT'],
					dealerNetPrice: item['QP1 - Dealer Net Price CURRENT'],
					majorCode: item['Major Code'],
					majorDescription: item['Major Code Description'],
					minorCode: item['Major Code'],
					minorCodeDescription: item['Minor Code Description'],
					partDescription: item['Part Description'],
					partId: item['Part Number'].trim(),
					partNoun: partNoun,
					partNumber: item['Part Number'].trim(),
					pptCode: item['PPT Code Description'],
				};
				oldJsonObject.push(tempObject);
			});

			//map through both, compare and find diffs
			const priceChangeArray = [];
			newJsonObject.forEach((item) => {
				const i = oldJsonObject.findIndex(
					(e) => e.partNumber === item.partNumber
				);

				item.partGroup = [];
				partGroups.forEach((groupItem) => {
					const j = groupItem.partIds?.findIndex(
						(e) => e.partId === item.partNumber
					);

					if (j > -1) {
						item.partGroup.push(groupItem.groupName);
					}
				});

				if (i > -1) {
					/* vendors contains the element we're looking for, at index "i" */

					if (oldJsonObject[i].dealerListPrice !== item.dealerListPrice) {
						const tempPriceChangeArray = {
							newPartNumber: item.partNumber,
							newDealerListPrice: item.dealerListPrice,
							originalDealerListPrice: oldJsonObject[i].dealerListPrice,
							newDealerNetPrice: item.dealerNetPrice,
							originalDealerNetPrice: oldJsonObject[i].dealerNetPrice,
							groupName: item.partGroup,
						};
						priceChangeArray.push(tempPriceChangeArray);
					} else if (oldJsonObject[i].dealerNetPrice !== item.dealerNetPrice) {
						const tempPriceChangeArray = {
							newPartNumber: item.partNumber,
							newDealerListPrice: item.dealerListPrice,
							originalDealerListPrice: oldJsonObject[i].dealerListPrice,
							newDealerNetPrice: item.dealerNetPrice,
							originalDealerNetPrice: oldJsonObject[i].dealerNetPrice,
							groupName: item.partGroup,
						};
						priceChangeArray.push(tempPriceChangeArray);
					}
				} else {
					//did not find the price, so new part
					const tempPriceChangeArray = {
						newPartNumber: item.partNumber,
						newDealerListPrice: item.dealerListPrice,
						originalDealerListPrice: '',
						newDealerNetPrice: item.dealerNetPrice,
						originalDealerNetPrice: '',
						groupName: item.partGroup,
					};
					priceChangeArray.push(tempPriceChangeArray);
				}
			});

			setDataDifference(priceChangeArray);
		} else {
			const priceChangeArray = [];
			newJsonObject.forEach((item) => {
				//did not find the price, so new part
				const tempPriceChangeArray = {
					newPartNumber: item.partNumber,
					newDealerListPrice: item.dealerListPrice,
					originalDealerListPrice: '',
					newDealerNetPrice: item.dealerNetPrice,
					originalDealerNetPrice: '',
					groupName: [],
				};
				priceChangeArray.push(tempPriceChangeArray);
			});

			setDataDifference(priceChangeArray);
		}
		setIsCalculating(false); // hide the loading modal after completion of upload/diff
	};

	const confirmUpload = async () => {
		const db = firebase.firestore();
		dataDifference.forEach((item) => {
			const firestoreRef = firebase.firestore().collection('parts');
			const queryRef = firestoreRef.where(
				'partNumber',
				'==',
				item.newPartNumber
			);
			queryRef.get().then((querySnapshot) => {
				const matchedDocs = querySnapshot.size;
				const i = fullData.findIndex(
					(e) => e.partNumber === item.newPartNumber
				);
				const toUploadPart = fullData[i];
				if (i > -1) {
					if (matchedDocs > 0) {
						//exists
						db.collection('parts')
							.doc(querySnapshot.docs[0].id)
							.update({
								dealerListPrice: toUploadPart.dealerListPrice,
								dealerNetPrice: toUploadPart.dealerNetPrice,
							});
					} else {
						//does not exist create
						db.collection('parts')
							.add({
								dealerListPrice: toUploadPart.dealerListPrice,
								dealerNetPrice: toUploadPart.dealerNetPrice,
								majorCode: toUploadPart.majorCode,
								majorCodeDescription: toUploadPart.majorDescription,
								minorCode: toUploadPart.minorCode,
								minorCodeDescription: toUploadPart.minorCodeDescription,
								partDescription: toUploadPart.partDescription,
								partId: toUploadPart.partId,
								partNoun: toUploadPart.partNoun,
								partNumber: toUploadPart.partNumber,
								pptCode: toUploadPart.pptCode,
							})
							.then(function() {})
							.catch(() => {});
					}
				}
			});
		});

		db.collection('partUploads')
			.add({
				fileLocation: `storage/files/${file.name}`,
				fileName: file.name,
				partsUpdated: {
					parts: dataDifference,
				},
				uploadDate: firebase.firestore.FieldValue.serverTimestamp(),
				userUid: firebase.auth().currentUser.uid,
			})
			.then(function() {
				uploadFile();
			})
			.catch(() => {});
	};

	const uploadFile = () => {
		//Logic to upload file to firebase storage
		const uploadTask = firebase
			.storage()
			.ref(`files/${file.name}`)
			.put(file);
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// Track the progress of the file upload
				const percentUploaded = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setUploadProgress(percentUploaded);
				//
			},
			() => {
				firebase
					.storage()
					.ref('files')
					.child(file.name)
					.getDownloadURL()
					.then(() => {});
			}
		);
	};

	const handleSelectChange = (e, data) => {
		setSelectedFile(data.value);
		// 1. Grab all partsUpdated info from partUploads collection where the fileName matches the one the user selected..
		const firestoreRef = firebase.firestore().collection('partUploads');
		// Create a query against the collection where we can match the file name
		const queryRef = firestoreRef.where('fileName', '==', data.value).limit(1);

		queryRef.get().then((querySnapshot) => {
			//total matched documents
			const matchedDocs = querySnapshot.size;
			if (matchedDocs) {
				const fileArr = [];
				querySnapshot.docs.forEach((doc) => {
					var data = doc.data();
					data.partsUpdated.parts.forEach((item) => {
						item.partGroup = [];
						partGroups.forEach((groupItem) => {
							const j = groupItem.partIds?.findIndex(
								(e) => e.partId === item.newPartNumber
							);

							if (j > -1) {
								item.partGroup.push(groupItem.groupName);
							}
						});
						fileArr.push(item);
					});
				});
				const filteredArr = fileArr.filter((obj) => obj !== undefined);
				setFileData(filteredArr);

				let tempColumns = [
					{
						name: 'newPartNumber',
						header: 'Part Number',
						defaultFlex: 1,
					},
					{
						name: 'partGroup',
						header: 'Part Group',
						flex: 1,
						render: ({ value }) =>
							value &&
							value.map((c, i, row) => {
								if (i + 1 === row.length) {
									return c;
								} else {
									return c + ', ';
								}
							}),
						filterEditor: SelectFilter,
						filterEditorProps: {
							multiple: true,
							wrapMultiple: false,
							dataSource: partGroups.map((c) => {
								return { id: c.groupName, label: c.groupName };
							}),
						},
					},
					{
						name: 'newDealerListPrice',
						header: 'New Dealer List Price',
						flex: 1,
					},
					{
						name: 'originalDealerListPrice',
						header: 'Original Dealer List Price',
						flex: 1,
					},
					{
						name: 'newDealerNetPrice',
						header: 'New Dealer Net Price',
						flex: 1,
					},
					{
						name: 'originalDealerNetPrice',
						header: 'Original Dealer Net Price',
						flex: 1,
					},
				];
				setFilterColumns(tempColumns);
			}
		});
	};

	const forTableFill = async (id) => {
		const partGroupsData = [];
		const firestoreRefGroups = firebase.firestore().collection('partGroups');
		firestoreRefGroups.get().then((querySnapshot) => {
			const matchedDocs = querySnapshot.size;
			if (matchedDocs) {
				querySnapshot.docs.forEach((doc) => {
					var tempData = doc.data();
					partGroupsData.push(tempData);
				});
			}
		});

		const firestoreRef = firebase.firestore().collection('partUploads');
		// Create a query against the collection where we can match the file name
		const queryRef = firestoreRef
			.where(firebase.firestore.FieldPath.documentId(), '==', id)
			.limit(1);

		queryRef.get().then((querySnapshot) => {
			//total matched documents
			const matchedDocs = querySnapshot.size;
			if (matchedDocs) {
				const fileArr = [];
				querySnapshot.docs.forEach((doc) => {
					var data = doc.data();
					data.partsUpdated.parts.forEach((item) => {
						item.partGroup = [];
						partGroupsData.forEach((groupItem) => {
							const j = groupItem.partIds.findIndex(
								(e) => e.partId === item.newPartNumber
							);

							if (j > -1) {
								item.partGroup.push(groupItem.groupName);
							}
						});
						fileArr.push(item);
					});
				});

				const filteredArr = fileArr.filter((obj) => obj !== undefined);
				setFileData(filteredArr);

				let tempColumns = [
					{
						name: 'newPartNumber',
						header: 'Part Number',
						defaultFlex: 1,
					},
					{
						name: 'partGroup',
						header: 'Part Group',
						flex: 1,
						render: ({ value }) =>
							value &&
							value.map((c, i, row) => {
								if (i + 1 === row.length) {
									return c;
								} else {
									return c + ', ';
								}
							}),
						filterEditor: SelectFilter,
						filterEditorProps: {
							multiple: true,
							wrapMultiple: false,
							dataSource: partGroupsData.map((c) => {
								return { id: c.groupName, label: c.groupName };
							}),
						},
					},
					{
						name: 'newDealerListPrice',
						header: 'New Dealer List Price',
						flex: 1,
					},
					{
						name: 'originalDealerListPrice',
						header: 'Original Dealer List Price',
						flex: 1,
					},
					{
						name: 'newDealerNetPrice',
						header: 'New Dealer Net Price',
						flex: 1,
					},
					{
						name: 'originalDealerNetPrice',
						header: 'Original Dealer Net Price',
						flex: 1,
					},
				];
				setFilterColumns(tempColumns);
			}
		});
	};

	return (
		<React.Fragment>
			<Hero>
				<h1>Pricing Changes</h1>
			</Hero>
			<Wrap>
				<header>
					<h2>Files</h2>
					<h4>Select a file to view its data</h4>
					{firebaseFiles && (
						<Select
							placeholder='Select File'
							options={firebaseFiles}
							onChange={(e, data) => handleSelectChange(e, data)}
						/>
					)}
					<div>
						<h3>{selectedFile}</h3>
						{fileData && filterColumns && filterTypes && defaultFilterValue && (
							<div>
								<ReactDataGrid
									handle={setGridRef}
									idProperty='id'
									columns={filterColumns}
									dataSource={fileData}
									style={gridStyle}
									theme='default-light'
									showZebraRows={true}
									pagination
									defaultLimit={12}
									enableFiltering
									defaultFilterValue={defaultFilterValue}
									filterTypes={filterTypes}
								/>

								<Button
									style={{
										marginTop: 20,
										borderRadius: 0,
										paddingTop: 10,
										paddingBottom: 10,
										paddingLeft: 20,
										paddingRight: 20,
										fontSize: 16,
										width: 200,
										backgroundColor: '#005C9D',
										border: 0,
										color: 'white',
									}}
									onClick={exportCSV}
								>
									Export CSV
								</Button>
								<Button
									style={{
										marginTop: 20,
										marginLeft: 20,
										borderRadius: 0,
										paddingTop: 10,
										paddingBottom: 10,
										paddingLeft: 20,
										paddingRight: 20,
										fontSize: 16,
										width: 200,
										backgroundColor: '#005C9D',
										border: 0,
										color: 'white',
									}}
									onClick={exportMasterReport}
								>
									Export Master Report
								</Button>
							</div>
						)}
					</div>
				</header>
				<form onSubmit={formHandler}>
					<input
						type='file'
						accept='.csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
					/>
					<button
						type='submit'
						style={{
							marginTop: 20,
							borderRadius: 0,
							paddingTop: 10,
							paddingBottom: 10,
							paddingLeft: 20,
							paddingRight: 20,
							fontSize: 16,
							width: 200,
							backgroundColor: '#005C9D',
							border: 0,
							marginLeft: 20,
							color: 'white',
						}}
					>
						Calculate Data
					</button>
					<Modal
						show={isCalculating}
						size='lg'
						aria-labelledby='contained-modal-title-vcenter'
						centered
					>
						<Modal.Header>
							<Modal.Title>Calculating...</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							The parts spreadsheet is uploading and the differences from the
							previous state of the parts are being calculated and may take a
							few moments. Please do not leave the page during this process and
							make sure to confirm the changes after completion.
						</Modal.Body>
					</Modal>
				</form>
				{dataDifference && (
					<Button style={{ marginTop: 20 }} onClick={confirmUpload}>
						Update Data
					</Button>
				)}
				{uploadProgress && (
					<Progress
						className='progress__bar'
						percent={uploadProgress}
						progress
						indicating
						size='medium'
						inverted
					/>
				)}
				{uploadProgress === 100 && <h4>File successfully uploaded</h4>}

				{dataDifference && (
					<ReactDataGrid
						idProperty='id'
						columns={columns}
						dataSource={dataDifference}
						groups={groups}
						style={gridStyle}
						theme='default-light'
						showZebraRows={true}
					/>
				)}
			</Wrap>
			<Footer />
		</React.Fragment>
	);
};

export default withRouter(PartChanges);
